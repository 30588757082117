type TPropertyRentedIconProps = {
    fill: string
    width?: number
    height?: number
}

export function PropertyRentedIcon(props: TPropertyRentedIconProps) {
    return (
        <svg
            width={props.width || 60}
            height={props.height || 60}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M37.08 9.63939L25.56 1.57939C22.42 -0.620613 17.6 -0.500613 14.58 1.83939L4.55998 9.65939C2.55998 11.2194 0.97998 14.4194 0.97998 16.9394V30.7394C0.97998 35.8394 5.11998 39.9994 10.22 39.9994H31.78C36.88 39.9994 41.02 35.8594 41.02 30.7594V17.1994C41.02 14.4994 39.28 11.1794 37.08 9.63939ZM22.5 31.9994C22.5 32.8194 21.82 33.4994 21 33.4994C20.18 33.4994 19.5 32.8194 19.5 31.9994V25.9994C19.5 25.1794 20.18 24.4994 21 24.4994C21.82 24.4994 22.5 25.1794 22.5 25.9994V31.9994Z"
                fill="#5E8874"
            />
        </svg>
    )
}
