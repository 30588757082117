import { Box, Sheet, Typography, Stack } from '@mui/joy'
import { MessageProps } from '@/services/use-user-messaging/provider/types'
import { DateTime } from 'luxon'

type ChatBubbleProps = MessageProps & {
    variant: 'sent' | 'received'
}

export function ChatBubble(props: ChatBubbleProps) {
    const { content, createdOn, variant } = props
    const isSent = variant === 'sent'

    return (
        <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
            <Box sx={{ position: 'relative' }}>
                <Sheet
                    color={isSent ? 'primary' : 'neutral'}
                    variant={isSent ? 'solid' : 'soft'}
                    sx={{
                        p: 1.25,
                        borderRadius: 'lg',
                        borderTopRightRadius: isSent ? 0 : 'lg',
                        borderTopLeftRadius: isSent ? 'lg' : 0,
                        backgroundColor: isSent
                            ? 'var(--joy-palette-primary-solidBg)'
                            : 'background.body',
                    }}
                >
                    <Typography
                        level="body-sm"
                        sx={{
                            color: isSent
                                ? 'var(--joy-palette-common-white)'
                                : 'var(--joy-palette-text-primary)',
                        }}
                    >
                        {content.message}
                    </Typography>
                </Sheet>
            </Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{ mb: 0.25 }}
            >
                <Typography level="body-xs">
                    {DateTime.fromJSDate(new Date(createdOn)).toFormat(
                        'ccc - hh:mm a'
                    )}
                </Typography>
            </Stack>
        </Box>
    )
}
