import React from 'react'
import {
    ChatClient,
    ChatMessage,
    ChatThreadClient,
} from '@azure/communication-chat'
import { CommunicationIdentityClient } from '@azure/communication-identity'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    increment,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore'
import { db } from '@/utilities/firebase-config'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import {
    MessageProps,
    UserChatList,
} from '@/services/use-user-messaging/provider/types.ts'

function useChat() {
    const [isLoadingUsers, setIsLoadingUsers] = React.useState<boolean>(false)
    const [isLoadingMessages, setIsLoadingMessages] =
        React.useState<boolean>(false)
    const [loadMessage, setLoadMessage] = React.useState<boolean>(false)

    const [token, setToken] = React.useState<string>('')
    const [threadId, setThreadId] = React.useState<string>('')
    const [selectedConversation, setSelectedConversation] =
        React.useState<boolean>(false)
    const [conversations, setConversations] = React.useState<MessageProps[]>([])

    const [initiateNewConversation, setInitiateNewConversation] =
        React.useState<boolean>(false)
    const [initialConversation, setInitialConversation] =
        React.useState<UserChatList | null>(null)
    const authController = useGetAuthenticatedUser()
    const connectionString = import.meta.env.VITE_AZURE_CONNECTION_STRING
    const endpointUrl = import.meta.env.VITE_AZURE_CHAT_ENDPOINT as string
    const identityClient = new CommunicationIdentityClient(connectionString)
    const senderInfo = {
        first_name: authController.user?.first_name ?? '',
        last_name: authController.user?.last_name ?? '',
        id: authController.user?.id ?? '',
    }

    const getToken = async (): Promise<string> => {
        try {
            const user = await identityClient.createUser()
            const tokenResponse = await identityClient.getToken(user, ['chat'])
            setToken(tokenResponse.token)
            return tokenResponse.token
        } catch (error) {
            console.error('Error generating ACS token:', error)
            throw new Error('Failed to generate ACS token')
        }
    }

    const createConversation = async (
        user_id: string,
        landlord_id: string,
        property_id: string,
        property_image_url: string,
        landlord_name: string,
        user_name: string,
        expiredToken?: boolean,
        thread_id?: string
    ): Promise<string> => {
        const conversationsRef = collection(db, 'chat_conversations')
        const q = query(
            conversationsRef,
            where('property_id', '==', property_id),
            where('user_id', '==', user_id)
        )
        const querySnapshot = await getDocs(q)
        if (!querySnapshot.empty) {
            if (expiredToken) {
                if (thread_id) {
                    const docRef = doc(db, 'chat_conversations', thread_id)
                    const docSnap = await getDoc(docRef)

                    if (docSnap.exists()) {
                        await deleteDoc(docRef)
                    } else {
                        console.log('Failed to delete')
                    }
                }
            } else {
                return 'Already have chat with this user'
            }
        }

        const token = await getToken()

        const chatClient = new ChatClient(
            endpointUrl,
            new AzureCommunicationTokenCredential(token)
        )
        const createChatThreadRequest = {
            topic: `Chat about Property ${property_id}`,
        }

        const createChatThreadResult = await chatClient.createChatThread(
            createChatThreadRequest
        )

        if (!createChatThreadResult || !createChatThreadResult.chatThread) {
            throw new Error('Failed to create chat thread')
        }

        const threadId = createChatThreadResult.chatThread.id

        await setDoc(doc(db, 'chat_conversations', threadId), {
            user_id,
            landlord_id,
            property_id,
            property_image_url,
            landlord_name,
            user_name,
            threadId,
            token,
        })

        return threadId
    }

    const getUserList = (
        callback: (userList: UserChatList[]) => void
    ): (() => void) => {
        setIsLoadingUsers(true)

        const q1 = query(
            collection(db, 'chat_conversations'),
            where('user_id', '==', authController.user?.id)
        )
        const q2 = query(
            collection(db, 'chat_conversations'),
            where('landlord_id', '==', authController.user?.id)
        )

        const unsubscribe1 = onSnapshot(q1, (snapshot1) => {
            const userList1: UserChatList[] = []
            snapshot1.forEach((doc) => {
                userList1.push(doc.data() as UserChatList)
            })

            const unsubscribe2 = onSnapshot(q2, (snapshot2) => {
                const userList2: UserChatList[] = []
                snapshot2.forEach((doc) => {
                    userList2.push(doc.data() as UserChatList)
                })

                const combinedUserList = [...userList1, ...userList2]
                callback(combinedUserList)
                setIsLoadingUsers(false)
            })

            return () => {
                unsubscribe2()
            }
        })

        return () => {
            unsubscribe1()
        }
    }

    const getMessages = async (
        threadId: string,
        token: string,
        init: boolean = false
    ): Promise<MessageProps[]> => {
        setLoadMessage(true)
        setSelectedConversation(true)
        if (init) {
            //setSelectedConversation(false)
            setIsLoadingMessages(true)
            const conversationRef = doc(db, 'chat_conversations', threadId)
            const con = await getDoc(conversationRef)
            if (
                con.exists() &&
                con.data().last_message_sender_id !== authController.user?.id
            ) {
                await updateDoc(conversationRef, {
                    last_message_sender_id: authController.user?.id,
                    count_unread_messages: 0,
                })
            }
        }

        try {
            setThreadId(threadId)
            setToken(token)

            const chatClient = new ChatClient(
                endpointUrl,
                new AzureCommunicationTokenCredential(token)
            )
            const threadClient = chatClient.getChatThreadClient(threadId)

            const pagedMessages = threadClient.listMessages().byPage()
            const messages: ChatMessage[] = []

            for await (const page of pagedMessages) {
                messages.push(...page)
            }

            const transformedMessages: MessageProps[] = messages.map((msg) => ({
                content: {
                    attachments: msg.content?.attachments ?? [],
                    message: msg.content?.message ?? '',
                },
                createdOn: msg.createdOn ?? new Date(),
                id: msg.id ?? '',
                metadata: {
                    first_name: msg.metadata?.first_name ?? '',
                    id: msg.metadata?.id ?? '',
                    last_name: msg.metadata?.last_name ?? '',
                    isTyping: false,
                },
                senderDisplayName: msg.senderDisplayName ?? '',
                sequenceId: msg.sequenceId ?? '',
                type: msg.type ?? '',
                version: msg.version ?? '',
            }))

            const filteredMessages = transformedMessages.filter(
                (msg) => msg.type === 'text'
            )

            const sortedMessages = filteredMessages.sort((a, b) => {
                const dateA = new Date(a.createdOn).getTime()
                const dateB = new Date(b.createdOn).getTime()
                return dateA - dateB
            })

            setConversations(sortedMessages)
            setIsLoadingMessages(false)
            setLoadMessage(false)
            return sortedMessages
        } catch (error) {
            setLoadMessage(false)
            setIsLoadingMessages(false)
            //create a new conversation
            setInitiateNewConversation(true)
            await getDocumentByThreadId(threadId)

            console.error('Error fetching messages:', error)
            throw error
        }
    }

    async function getDocumentByThreadId(threadId: string) {
        const docRef = doc(db, 'chat_conversations', threadId)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
            setInitialConversation(docSnap.data() as UserChatList)
        } else {
            console.log('No such document!')
        }
    }

    const sendMessage = async (messageContent: string): Promise<void> => {
        try {
            const chatClient = new ChatClient(
                endpointUrl,
                new AzureCommunicationTokenCredential(token)
            )
            const threadClient: ChatThreadClient =
                chatClient.getChatThreadClient(threadId)

            const sendMessageRequest = {
                content: messageContent,
            }
            await threadClient.sendMessage(sendMessageRequest, {
                metadata: senderInfo,
            })

            const conversationRef = doc(db, 'chat_conversations', threadId)

            // Update last message and increment unread count
            const con = await getDoc(conversationRef)
            if (con.exists()) {
                if (
                    con.data().last_message_sender_id !==
                    authController.user?.id
                ) {
                    await updateDoc(conversationRef, {
                        lastMessage: messageContent,
                        last_message_sender_id: authController.user?.id,
                        count_unread_messages: 0,
                    })
                } else {
                    await updateDoc(conversationRef, {
                        lastMessage: messageContent,
                        last_message_sender_id: authController.user?.id,
                        count_unread_messages: increment(1),
                    })
                }
            }

            await getMessages(threadId, token, false)
            await chatClient.startRealtimeNotifications()

            const messageListener = () => {
                console.log('message received')
                getMessages(threadId, token, false)
            }

            chatClient.on('chatMessageReceived', messageListener)
        } catch (error) {
            console.error('Error sending message:', error)
            throw error
        }
    }

    async function createNewConversationAfterTokenExpired() {
        setIsLoadingUsers(true)
        if (initialConversation) {
            await createConversation(
                initialConversation.user_id,
                initialConversation.landlord_id,
                initialConversation.property_id,
                initialConversation.property_image_url,
                initialConversation.landlord_name,
                initialConversation.user_name,
                true,
                threadId
            )
        }
        setIsLoadingUsers(false)
        //createConversation()
    }

    return {
        isLoadingUsers,
        getUserList,
        getMessages,
        createConversation,
        setSelectedConversation,
        selectedConversation,
        sendMessage,
        conversations,
        setConversations,
        threadId,
        token,
        setInitiateNewConversation,
        initiateNewConversation,
        createNewConversationAfterTokenExpired,
        isLoadingMessages,
        loadMessage,
    }
}

export const ChatContext = React.createContext<
    ReturnType<typeof useChat> | undefined
>(undefined)

export function useChatController() {
    const context = React.useContext(ChatContext)
    if (!context) {
        throw new Error('useChat must be used within a ChatProvider')
    }
    return context
}

export function ChatProvider({ children }: React.PropsWithChildren) {
    const value = useChat()
    return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
}
