export function openSidebar() {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden'
        document.documentElement.style.setProperty(
            '--SideNavigation-slideIn',
            '1'
        )
    }
}

export function closeSidebar() {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty(
            '--SideNavigation-slideIn'
        )
        document.body.style.removeProperty('overflow')
    }
}

export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn')
        if (slideIn) {
            closeSidebar()
        } else {
            openSidebar()
        }
    }
}

export function openMessagesPane() {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden'
        document.documentElement.style.setProperty(
            '--MessagesPane-slideIn',
            '1'
        )
    }
}

export function closeMessagesPane() {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--MessagesPane-slideIn')
        document.body.style.removeProperty('overflow')
    }
}

export function toggleMessagesPane() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--MessagesPane-slideIn')
        if (slideIn) {
            closeMessagesPane()
        } else {
            openMessagesPane()
        }
    }
}
