import { PropsWithChildren } from 'react'
import { Typography, TypographyProps } from '@mui/joy'
import { colors } from '@/utilities/colors.ts'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { FontSize } from '@/components/text/types.ts'

interface ITextProps extends TypographyProps {
    textFontSize?: FontSize
    bigHeader?: boolean
    header?: boolean
}

export function Text(mprops: PropsWithChildren<ITextProps>) {
    function getTextStyle(value?: FontSize): SxProps<Theme> {
        const result: SxProps<Theme> = {
            fontSize: FontSize.Base,
            color: colors.brown,
        }
        if (!value) {
            return result
        }

        if (value === FontSize.SubTitle) {
            return {
                fontWeight: 600,
                fontSize: FontSize.SubTitle,
                color: colors.brown,
            }
        }

        if (value === FontSize.Title) {
            return {
                fontWeight: 600,
                fontSize: FontSize.Title,
                color: colors.brown,
            }
        }

        return result
    }
    const textStyle = {
        ...getTextStyle(mprops.textFontSize),
        ...mprops.sx,
    } as SxProps
    const props = { ...mprops }
    delete props.textFontSize

    return (
        <Typography {...props} sx={textStyle}>
            {mprops.children}
        </Typography>
    )
}
