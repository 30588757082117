import { Dropdown } from '@/components/dropdown'
import { PropertyFilterRange } from '@/src/pages/home/components/search-filter/components/property-filter-range'

type TSelectPriceDropdownProps = {
    onToggle: (value: boolean) => void
    onSetMinPrice: (value: string) => void
    onSetMaxPrice: (value: string) => void
    maxPrice: string
    minPrice: string
    isSelected: boolean
    isOpen: boolean
}
export function SelectPriceDropdown(props: TSelectPriceDropdownProps) {
    return (
        <Dropdown
            isSelected={props.isSelected}
            title={'Price'}
            onToggle={props.onToggle}
            isOpen={props.isOpen}
        >
            <PropertyFilterRange
                leftInputValue={props.minPrice}
                rightInputValue={props.maxPrice}
                onChangeLeftValue={(value) => {
                    props.onSetMinPrice(value)
                }}
                onChangeRightValue={(value) => {
                    props.onSetMaxPrice(value)
                }}
                leftInputTitle={'Min Price'}
                rightInputTitle={'Max Price'}
            />
        </Dropdown>
    )
}
