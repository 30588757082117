import { EditPropertyForm } from '@/src/pages/secure/properties/edit-property/components/edit-property-form'
import { EditPropertyProvider } from '@/src/pages/secure/properties/edit-property/hooks/use-edit-property/provider'

export function EditProperty() {
    return (
        <EditPropertyProvider>
            <EditPropertyForm />
        </EditPropertyProvider>
    )
}
