import { z } from 'zod'

export enum UserType {
    LANDLORD = 'LANDLORD',
    RENTER = 'RENTER',
    AGENT = 'AGENT',
}

export enum SubscriptionType {
    FREE_TRIAL = 'FREE_TRIAL',
    PRO = 'PRO',
    BASIC = 'BASIC',
}

export const userSchema = z.object({
    is_identity_card_valid: z.boolean(),
    has_uploaded_id_card: z.boolean().default(false),
    subscription_expiry: z.string().default(''),
    subscription_type: z
        .nativeEnum(SubscriptionType)
        .default(SubscriptionType.FREE_TRIAL),
    virtual_tour_view_count: z.number().default(2),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    phone: z.string().optional(),
    favourites: z.string().array().optional(),
    createdAt: z.string(),
    id: z.string(),
    user_type: z.nativeEnum(UserType).default(UserType.RENTER),
})

export type User = z.infer<typeof userSchema>
