import { Box, Grid, Skeleton } from '@mui/joy'

export function PropertyLoading() {
    return (
        <Box mb={5} maxHeight={500} height={500}>
            <Grid container spacing={1} maxHeight={500} height={500}>
                <Grid md={12} sm={12} xs={12} sx={{ position: 'relative' }}>
                    <Skeleton height={500} sx={{ borderRadius: 15 }} />
                </Grid>
            </Grid>
        </Box>
    )
}
