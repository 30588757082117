import { PropsWithChildren } from 'react'
import { ClickAwayListener } from '@mui/material'
import { Box, Typography, Button } from '@mui/joy'
import { colors } from '@/utilities/colors.ts'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

type TDropdownProps = {
    title: string
    sx?: SxProps<Theme>
    buttonHeight?: number
    onToggle: (value: boolean) => void
    onClear?: () => void
    isOpen: boolean
    isSelected?: boolean
}

export function Dropdown(props: PropsWithChildren<TDropdownProps>) {
    function onClear() {
        if (props.onClear) {
            props.onClear()
        }
        props.onToggle(false)
    }

    return (
        <ClickAwayListener onClickAway={() => props.onToggle(false)}>
            <Box sx={{ position: 'relative' }}>
                <Button
                    variant={'outlined'}
                    onClick={() => props.onToggle(!props.isOpen)}
                    endDecorator={<KeyboardArrowDownIcon />}
                    sx={{
                        textTransform: 'none',
                        fontSize: 13,
                        border: `1px solid ${
                            props.isSelected ? colors.primary : colors.gray10
                        } !important`,
                        color: colors.brown,
                        borderRadius: 6,
                        height: props.buttonHeight || 46,
                        py: 0,
                        ml: 1,
                        mr: 1,
                    }}
                >
                    <Typography
                        my={2}
                        level={'body-sm'}
                        sx={{ color: '#666666', fontWeight: 500 }}
                    >
                        {props.title}
                    </Typography>
                </Button>
                {props.isOpen && (
                    <Box
                        sx={{
                            position: 'absolute',
                            zIndex: 9999,
                            p: 3,
                            borderRadius: 2,
                            top: 60,
                            minWidth: 400,
                            backgroundColor: colors.white,
                            boxShadow: 6,
                        }}
                    >
                        {props.children}
                        <Box
                            display={'flex'}
                            mt={2}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Box>
                                <Typography
                                    fontWeight={'bold'}
                                    sx={{
                                        color: colors.primary,
                                        cursor: 'pointer',
                                    }}
                                    onClick={onClear}
                                >
                                    Clear
                                </Typography>
                            </Box>
                            <Button
                                onClick={() => props.onToggle(false)}
                                sx={{
                                    px: 5,
                                    backgroundColor: colors.primary,
                                    textTransform: 'none',
                                    color: colors.white,
                                    '&:hover': {
                                        backgroundColor: colors.primary,
                                        opacity: '0.8',
                                    },
                                }}
                            >
                                Done
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </ClickAwayListener>
    )
}
