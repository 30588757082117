import { Typography, Box } from '@mui/joy'
import { useGetUserFavouriteProperties } from '@/services/use-get-properties'
import { colors } from '@/utilities/colors'
import { SecureHeader } from '@/src/pages/secure/components/secure-header'
import { FavouriteProperties } from '@/src/pages/secure/favourites/components/favourtie-properties'

export function Favourites() {
    const properties = useGetUserFavouriteProperties()
    return (
        <>
            <SecureHeader />
            <Box px={3}>
                <Typography level={'h3'} mb={4} sx={{ color: colors.brown }}>
                    Favourites
                </Typography>
                <FavouriteProperties
                    isFetching={properties.isRefetching}
                    isLoading={properties.isLoading}
                    isError={properties.isError}
                    propertyList={properties.data || []}
                />
            </Box>
        </>
    )
}
