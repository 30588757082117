import React from 'react'

type THeartIconProps = {
    isSelected: boolean
    onClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

export function HeartIcon(props: THeartIconProps) {
    const fillColor = props.isSelected ? 'red' : 'gray'
    return (
        <svg
            onClick={props.onClick}
            width="28"
            height="26"
            viewBox="0 0 28 26"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.7305 3.61914C16.7081 3.61914 14.898 4.55547 13.7695 5.99188C12.641 4.55547 10.8309 3.61914 8.80848 3.61914C5.37825 3.61914 2.59607 6.27916 2.59607 9.56695C2.59607 10.8331 2.80836 12.0035 3.17709 13.0888C4.94248 18.4089 10.3839 21.5902 13.0767 22.4627C13.4566 22.5904 14.0823 22.5904 14.4622 22.4627C17.155 21.5902 22.5965 18.4089 24.3618 13.0888C24.7306 12.0035 24.9429 10.8331 24.9429 9.56695C24.9429 6.27916 22.1607 3.61914 18.7305 3.61914Z"
                fill={fillColor}
                fillOpacity="1"
                stroke="#F8F8F8"
                strokeWidth="3"
            />
        </svg>
    )
}
