import {
    Box,
    Button,
    formLabelClasses,
    List,
    ListItem,
    ListItemDecorator,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/joy'
import { useFormik } from 'formik'
import { colors } from '@/utilities/colors'
import Landlord from '@/assets/svgs/landlord.svg?react'
import Renter from '@/assets/svgs/renter.svg?react'
import { FontSize } from '@/components/text/types'
import * as Yup from 'yup'
import { useUserTypeUpdateMutation } from '@/services/use-user-profile-update'
import { Navigate, useNavigate } from 'react-router-dom'
import { Loading } from '@/src/pages/secure/secure-layout/components/loading'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { USERTYPE } from '@/services/use-user-profile-update/types.ts'
import { queryClient } from '@/utilities/react-query-client.ts'
import { keys } from '@/services/query-keys.ts'

export function GettingStarted() {
    const profileMutation = useUserTypeUpdateMutation()
    const authUserController = useGetAuthenticatedUser()
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            account_type: USERTYPE.UNKNOWN,
        },
        validationSchema: Yup.object({
            account_type: Yup.string().required('Please select your Role '),
        }),
        onSubmit: (values) => {
            const is_landlord = values.account_type === USERTYPE.LANDLORD
            profileMutation.mutate(
                {
                    user_type: is_landlord
                        ? USERTYPE.LANDLORD
                        : USERTYPE.RENTER,
                },
                {
                    onSuccess: () => {
                        navigate('/secure')
                        queryClient.invalidateQueries({
                            queryKey: [keys.GET_USER.key],
                        })
                    },
                }
            )
        },
    })

    if (authUserController.isPending) {
        return <Loading />
    }

    if (!authUserController.hasActiveSession) {
        return <Navigate to={'/auth/login'} replace />
    }
    return (
        <Box sx={{ mt: { md: '2rem' } }}>
            <Box
                component="main"
                my={'auto'}
                py={2}
                pb={5}
                display={'flex'}
                flexDirection={'column'}
                gap={2}
                mx={'auto'}
                borderRadius={'sm'}
                sx={{
                    width: { sm: '100%', md: '600px' },
                    maxWidth: '100%',
                    '& form': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    },
                    [`& .${formLabelClasses.asterisk}`]: {
                        visibility: 'hidden',
                    },
                }}
            >
                <Typography level="h4" sx={{ color: colors.brown }}>
                    Select Your Role to Get Started
                </Typography>
                <Typography level="body-md" sx={{ color: colors.brown }} mb={2}>
                    Help us tailor your experience on Openprop. Choose whether
                    you’re a landlord looking to list properties or a renter
                    searching for a new home.
                </Typography>

                <RadioGroup
                    aria-label="Choose account type"
                    name="account_type"
                    value={formik.values.account_type}
                    onChange={formik.handleChange}
                >
                    <List
                        sx={{
                            minWidth: '100%',
                            '--List-gap': '1.5rem',
                            '--ListItem-paddingY': '1rem',
                            '--ListItem-radius': '8px',
                            '--ListItemDecorator-size': '32px',
                        }}
                    >
                        {[
                            {
                                title: 'Landlord/Agent',
                                value: USERTYPE.LANDLORD,
                                description:
                                    "Manage and list your properties with ease. Get access to powerful tools that maximize your property's visibility and reach.",
                            },
                            {
                                title: 'Renter',
                                value: USERTYPE.RENTER,
                                description:
                                    'Find your perfect home with our extensive listings. Enjoy immersive virtual tours and detailed property information',
                            },
                        ].map((item, index) => (
                            <ListItem
                                variant="outlined"
                                key={index}
                                sx={{ boxShadow: 'sm', alignItems: 'start' }}
                            >
                                <ListItemDecorator
                                    sx={{ minWidth: '3rem', px: '0.8rem' }}
                                >
                                    {[<Renter />, <Landlord />][index]}
                                </ListItemDecorator>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.54rem',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: FontSize.Title,
                                        }}
                                    >
                                        {item.title.charAt(0).toUpperCase() +
                                            item.title.slice(1)}
                                    </Typography>
                                    <Typography>{item.description}</Typography>
                                </Box>
                                <Radio
                                    overlay
                                    value={item.value}
                                    sx={{
                                        flexGrow: 1,
                                        flexDirection: 'row-reverse',
                                    }}
                                    slotProps={{
                                        action: ({ checked }) => ({
                                            sx: (theme) => ({
                                                ...(checked && {
                                                    inset: -1,
                                                    border: '2px solid',
                                                    borderColor:
                                                        theme.vars.palette
                                                            .primary[500],
                                                }),
                                            }),
                                        }),
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </RadioGroup>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {formik.errors.account_type && (
                        <Typography level="body-sm" sx={{ color: colors.red }}>
                            {formik.errors.account_type}
                        </Typography>
                    )}
                    <Button
                        onClick={() => formik.handleSubmit()}
                        disabled={!formik.isValid || !formik.dirty}
                    >
                        Get Started
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: colors.white,
                            color: colors.brown,
                            border: `1px solid ${colors.gray}`,
                            '&:hover': {
                                backgroundColor: colors.white,
                            },
                        }}
                        onClick={() => navigate('/auth/sign-up')}
                    >
                        Back
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
