import React from 'react'
import { useFormik } from 'formik'
import { z } from 'zod'
import { useNavigate } from 'react-router-dom'
import { useStytch } from '@stytch/react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import {
    Box,
    Button,
    FormLabel,
    LinearProgress,
    Stack,
    Typography,
} from '@mui/joy'

import { Header } from '@/src/pages/auth/components/header'
import { TextInput } from '@/components/text-input'
import { colors } from '@/utilities/colors.ts'

import { useURLQuery } from '@/utilities/use-url-query.tsx'
import { useToastController } from '@/services/use-toast'
import { StytchAPIError } from '@stytch/vanilla-js'
import { FormControl } from '@mui/material'
import { useUserOnboardingController } from '@/services/use-user-onboarding'
import { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem'

export function UpdatePassword() {
    const onboardingController = useUserOnboardingController()
    const queryParams = useURLQuery()
    const navigate = useNavigate()
    const stytchClient = useStytch()
    const toast = useToastController()
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: toFormikValidationSchema(
            z.object({
                password: z.string().min(10),
                confirmPassword: z.string().min(10),
            })
        ),
        onSubmit: async ({ password }) => {
            await resetPassword(password)
        },
    })

    const [passwordStrength, setPasswordStrength] = React.useState({
        valid_password: false,
        feedback: {
            has_digit: false,
            has_lower_case: false,
            has_symbol: false,
            has_upper_case: false,
        },
        missing_characters: 0,
        missing_complexity: 0,
    })

    const token = queryParams.get('token')
    const isPasswordReset =
        queryParams.get('stytch_token_type') === 'reset_password'

    const passwordErrorMessage = React.useMemo(() => {
        let errorMessage = ''

        if (formik.errors.password) {
            errorMessage = formik.errors.password
            return errorMessage
        }

        if (!passwordStrength.valid_password) {
            errorMessage = 'Password is invalid'
            return errorMessage
        }

        return errorMessage
    }, [passwordStrength, formik.errors.password])

    function getPasswordStrengthState(password: string): {
        value: number
        color: DefaultColorPalette
    } {
        if (password.length < 10) {
            return {
                value: 25,
                color: 'danger',
            }
        }
        const isPasswordUpToStandard = Object.values(
            passwordStrength.feedback
        ).every((val) => val)
        if (
            password.length >= 10 &&
            !passwordStrength.valid_password &&
            !isPasswordUpToStandard
        ) {
            return {
                value: 60,
                color: 'warning',
            }
        }

        if (passwordStrength.valid_password) {
            return {
                value: 100,
                color: 'success',
            }
        }
        return {
            value: 0,
            color: 'danger',
        }
    }

    const p = getPasswordStrengthState(formik.values.password)

    const resetPassword = React.useCallback(
        async (password: string) => {
            if (!token) {
                toast.onOpen('No token available', 'error')
                return
            }
            try {
                await stytchClient.passwords.resetByEmail({
                    token: token,
                    password: password,
                    session_duration_minutes: 10800,
                })
                toast.onOpen('Password has been updated!', 'success')
                navigate('/secure')
            } catch (err) {
                const error = err as StytchAPIError
                let message = 'Oops! Something went wrong!'
                if (error.error_type === 'unable_to_auth_magic_link') {
                    message =
                        'Unable to authenticate magic link. Please try again.'
                    navigate('/')
                } else if (error.error_type === 'breached_password') {
                    message =
                        'Password is in our list of compromised passwords. Please use a more complex password.'
                } else if (error.error_type === 'weak_password') {
                    message = "Password doesn't meet our strength requirements."
                } else if (error.error_type === 'unauthorized_credentials') {
                    message =
                        'Unable to authenticate credentials. Please restart the process'
                }
                toast.onOpen(message, 'error')
            }
        },
        [stytchClient, token]
    )

    React.useEffect(() => {
        if (formik.values.password.length === 0) {
            return
        }

        async function checkPassword() {
            try {
                const passwordValidity =
                    await onboardingController.checkPasswordStrength(
                        formik.values.password
                    )

                if (!passwordValidity) {
                    return
                }
                setPasswordStrength({
                    valid_password: passwordValidity.valid_password,
                    feedback: {
                        has_digit:
                            passwordValidity.feedback.luds_requirements
                                .has_digit,
                        has_lower_case:
                            passwordValidity.feedback.luds_requirements
                                .has_lower_case,
                        has_symbol:
                            passwordValidity.feedback.luds_requirements
                                .has_symbol,
                        has_upper_case:
                            passwordValidity.feedback.luds_requirements
                                .has_upper_case,
                    },
                    missing_characters:
                        passwordValidity.feedback.luds_requirements
                            .missing_characters,
                    missing_complexity:
                        passwordValidity.feedback.luds_requirements
                            .missing_complexity,
                })
            } catch (e) {
                console.log(e)
            }
        }
        void checkPassword()
    }, [formik.values.password, onboardingController])

    if (!token || !isPasswordReset) {
        //TODO: design page for no token or token type
        return (
            <Box>
                <Typography level={'body-xs'} sx={{ color: colors.red, mb: 1 }}>
                    Error display page please check if email link is valid or
                    click here to restart process
                </Typography>
            </Box>
        )
    }

    return (
        <Box>
            <Header />
            <Box
                sx={{
                    mx: 'auto',
                    width: {
                        xs: '90%',
                        sm: '80%',
                        md: '40%',
                    },
                }}
            >
                <Stack mt={4} gap={4} direction={'column'}>
                    <Box
                        textAlign={'center'}
                        gap={4}
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        <Typography level={'h2'} sx={{ color: colors.brown }}>
                            Enter new Password
                        </Typography>
                        <Typography>
                            Please enter the email you used to sign-up. A reset
                            key will be sent to your email to help reset your
                            password.
                        </Typography>
                    </Box>
                    <FormControl required>
                        <FormLabel>New Password</FormLabel>
                        <TextInput
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorText={
                                formik.touched.password && passwordErrorMessage
                                    ? passwordErrorMessage
                                    : ''
                            }
                            name={'password'}
                            type={'password'}
                            size={'lg'}
                        />
                        {formik.values.password.length > 0 && (
                            <Box>
                                <LinearProgress
                                    determinate
                                    color={p.color}
                                    value={p.value}
                                />
                            </Box>
                        )}
                    </FormControl>
                    <FormControl required>
                        <FormLabel>Confirm Password</FormLabel>
                        <TextInput
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorText={
                                formik.touched.confirmPassword &&
                                formik.values.password !==
                                    formik.values.confirmPassword
                                    ? 'Password Mismatch'
                                    : ''
                            }
                            name={'confirmPassword'}
                            type={'password'}
                            size={'lg'}
                        />
                    </FormControl>
                    <Button
                        onClick={() => formik.handleSubmit()}
                        sx={{
                            py: 1.5,
                            px: 10,
                            width: 120,
                            borderRadius: 10,
                            alignSelf: 'center',
                        }}
                    >
                        Reset
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}
