import { Box } from '@mui/joy'
import { Step1 } from '@/src/pages/secure/properties/edit-property/components/steps/step-1'
import { Step2 } from '@/src/pages/secure/properties/edit-property/components/steps/step-2'
import { Step3 } from '@/src/pages/secure/properties/edit-property/components/steps/step-3'
import { SecureHeader } from '@/src/pages/secure/components/secure-header'
import { useEditPropertyController } from '@/src/pages/secure/properties/edit-property/hooks/use-edit-property'

export function EditPropertyForm() {
    const createPropertyController = useEditPropertyController()
    const steps = [<Step1 />, <Step2 />, <Step3 />]

    return (
        <Box>
            <SecureHeader />
            <Box p={3}>{steps[createPropertyController.activeStep]}</Box>
        </Box>
    )
}
