import React, { PropsWithChildren } from 'react'
import { DateTime } from 'luxon'
import { PropertyType } from '@/src/pages/home/components/search-filter/types.ts'
import {
    PropertyInformation,
    RentalTerm,
} from '@/services/use-get-properties/types.ts'
import { ImageData } from '@/src/pages/secure/properties/create-property/hooks/use-create-property/types.ts'
import { useMutation } from '@tanstack/react-query'
import { Axios } from '@/utilities/axios.ts'
import { queryClient } from '@/utilities/react-query-client.ts'
import { useToastController } from '@/services/use-toast'
import { fileUpload } from '@/utilities/azure-file-upload.ts'
import { useNavigate } from 'react-router-dom'
import { keys } from '@/services/query-keys.ts'

export const MAX_STEP = 3

function useCreateProperty() {
    const navigation = useNavigate()
    const currentYear = DateTime.now().year
    const [activeStep, setActiveStep] = React.useState<number>(0)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [property, setProperty] = React.useState({
        has_virtual_tour: false,
        overview: '',
        price: 0,
        security_deposit: 0,
        service_charge: 0,
        rental_term: RentalTerm.MONTHLY,
        property_types: PropertyType.UNKNOWN,
        residential_type: '',
        year_built: currentYear,
        region: {
            id: '',
            region_name: '',
        },
        neighbourhood: {
            id: '',
            neighbourhood_name: '',
            coords: {
                type: 'Point',
                coordinates: [0, 0],
            },
        },
        address1: '',
        address2: '',
        property_floor_size: 0,
        property_space: '',
        property_land_size: 0,
        number_of_bedrooms: 0,
        number_of_bathrooms: 0,
        number_of_packing_space: 0,
        selected_general_amenities: [],
        selected_kitchen_amenities: [],
        selected_bedroom_amenities: [],
        selected_facility_amenities: [],
        selected_outdoor_amenities: [],
        selected_bathroom_amenities: [],
        selected_livingroom_amenities: [],
        property_information: PropertyInformation.UNKNOWN,
        images: [],
        coords: { type: 'Point', coordinates: [0, 0] },
    })
    const [images, setImages] = React.useState<ImageData[]>([])
    const toast = useToastController()
    const createPropertyMutation = useMutation({
        //TODO: remove any and type properly
        mutationFn: (variables: never) => Axios.post('/properties', variables),
        onError: () => {
            toast.onOpen('Failed to create property', 'error')
        },
        onSuccess: () => {
            toast.onOpen('Property created!', 'success')
            void queryClient.invalidateQueries({ queryKey: [] })
            navigation('/secure/properties')
        },
    })

    function stepCurrentActiveStep(stepNumber: number) {
        if (stepNumber <= MAX_STEP || stepNumber >= 1) {
            setActiveStep(stepNumber)
        }
    }

    function onSaveImages(image: ImageData | ImageData[]) {
        if (Array.isArray(image)) {
            setImages(image)
            return
        }
        setImages((prev) => [...prev, image])
    }

    function onRemoveImages(imageId: string) {
        const imageList = images.filter((img) => img.id !== imageId)
        setImages(imageList)
    }

    async function onSubmit() {
        try {
            setIsLoading(true)
            const uploadStatus = images.map((img) => ({
                is_cover: img.is_cover,
                upload: fileUpload(img.file, 'imagess'),
            }))
            const uploadPromises = uploadStatus.map((status) => status.upload)

            const promiseResults = await Promise.allSettled(uploadPromises)

            const successfulUploads = promiseResults
                .map((result, index) => {
                    if (result.status === 'fulfilled') {
                        return {
                            is_cover: uploadStatus[index].is_cover || false,
                            image_url: result.value,
                        }
                    }
                })
                .filter(Boolean)

            const variable = {
                name: property.overview,
                overview: property.overview,
                price: property.price,
                service_charge: property.service_charge,
                number_of_bedrooms: property.number_of_bedrooms,
                number_of_bathrooms: property.number_of_bathrooms,
                number_of_parking_spaces: property.number_of_packing_space || 0,
                property_floor_size: property.property_floor_size || 100,
                has_virtual_tour: property.has_virtual_tour,
                year_built: property.year_built,
                property_land_size: property.property_land_size || 100,
                security_deposit: property.security_deposit,
                property_types: property.property_types,
                residential_type: property.residential_type,
                address1: property.address1,
                address2: property.address2,
                region: property.region.id,
                neighbourhood: property.neighbourhood.id,
                space_type: property.property_space,
                property_information: [property.property_information],
                property_amenities: [
                    ...property.selected_kitchen_amenities,
                    ...property.selected_outdoor_amenities,
                    ...property.selected_livingroom_amenities,
                    ...property.selected_bathroom_amenities,
                    ...property.selected_general_amenities,
                    ...property.selected_facility_amenities,
                    ...property.selected_bedroom_amenities,
                ].map((am) => {
                    // @ts-expect-error -- temporarily
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    return am.id
                }),
                property_images: successfulUploads,
                coords: {
                    lat: property.coords.coordinates[0],
                    long: property.coords.coordinates[1],
                },
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            createPropertyMutation.mutate(variable)
            queryClient.removeQueries({
                queryKey: [keys.GET_LANDLORD_PROPERTY.key],
            })
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    return {
        activeStep: activeStep,
        setActiveStep: stepCurrentActiveStep,
        property: property,
        setProperty: setProperty,
        images: images,
        onRemoveImages: onRemoveImages,
        onSaveImages: onSaveImages,
        onSubmit: onSubmit,
        isSubmitting: isLoading || createPropertyMutation.isPending,
    }
}

type CreatePropertyContext = ReturnType<typeof useCreateProperty>
export const Context = React.createContext<CreatePropertyContext | undefined>(
    undefined
)

type TCreatePropertyProvider = PropsWithChildren<{
    value?: CreatePropertyContext
}>

export function CreatePropertyProvider(props: TCreatePropertyProvider) {
    const value = useCreateProperty()
    return (
        <Context.Provider value={props.value || value}>
            {props.children}
        </Context.Provider>
    )
}
