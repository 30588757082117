import { useNavigate } from 'react-router-dom'
import { Box, Typography, Card, CardCover, Grid, Container } from '@mui/joy'

import { colors } from '@/utilities/colors.ts'
import style from '@/src/pages/home/components/city-list/style.module.css'

import accra from '@/src/pages/home/components/city-list/assets/images/accra.png'
import kumasi from '@/src/pages/home/components/city-list/assets/images/kumasi.png'
import tamale from '@/src/pages/home/components/city-list/assets/images/tamale.png'
import capecoast from '@/src/pages/home/components/city-list/assets/images/cape-coast.png'
import takoradi from '@/src/pages/home/components/city-list/assets/images/takoradi.png'

export function CityList() {
    const navigate = useNavigate()
    const cities = [
        {
            name: 'Accra',
            image: accra,
            id: '652390a94e8b1f63133f565e',
            isAvailable: true,
        },
        { name: 'Cape Coast', image: capecoast, id: '', isAvailable: false },
        { name: 'Kumasi', image: kumasi, id: '', isAvailable: false },
        { name: 'Takoradi', image: takoradi, id: '', isAvailable: false },
        { name: 'Tamale', image: tamale, id: '', isAvailable: false },
    ]

    function onNavigate(value: string) {
        if (value.trim() === '') {
            return
        }
        navigate(`/properties?region=${value}`)
    }
    return (
        <Container
            maxWidth={'md'}
            sx={{
                marginBottom: 5,
            }}
        >
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: { xs: 21, sm: 28, md: 36 },
                    color: colors.footerTextColor,
                    textAlign: { xs: 'center', sm: 'center' },
                    pt: 4,
                    pb: 4,
                    px: 2,
                }}
            >
                Find apartments for rent using our convenient search
            </Typography>
            {cities.length > 0 ? (
                <Grid
                    container
                    spacing={2}
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    {cities.map((city) => {
                        return (
                            <Grid
                                xs={6}
                                sm={6}
                                md={4}
                                key={city.name}
                                sx={{
                                    cursor: 'pointer',
                                    overflowY: 'hidden',
                                }}
                                onClick={() => onNavigate(city.id)}
                            >
                                <Card
                                    className={style.cityCard}
                                    sx={{
                                        height: { xs: 60, sm: 100 },
                                    }}
                                >
                                    <CardCover>
                                        <img
                                            src={city.image}
                                            srcSet={city.image}
                                            loading="lazy"
                                            alt=""
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{
                                            bgcolor: colors.brown,
                                            opacity: 0.55,
                                        }}
                                    />
                                    <CardCover>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <Typography
                                                textColor="#fff"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: {
                                                        xs: 15,
                                                        sm: 18,
                                                        md: 30,
                                                    },
                                                }}
                                            >
                                                {city.name}
                                            </Typography>
                                        </Box>
                                    </CardCover>
                                    <CardCover>
                                        <Box className={style.cityName}>
                                            <Typography
                                                fontWeight="lg"
                                                sx={{
                                                    fontSize: {
                                                        xs: 12,
                                                        sm: 15,
                                                        md: 18,
                                                    },
                                                    px: {
                                                        xs: 1,
                                                        sm: 2,
                                                    },
                                                    zIndex: 1,
                                                    textAlign: { xs: 'center' },
                                                }}
                                                textColor="#fff"
                                            >
                                                {city.isAvailable ? (
                                                    'View all properties in this area'
                                                ) : (
                                                    <>
                                                        Properties in this area
                                                        will be available soon
                                                    </>
                                                )}
                                            </Typography>
                                        </Box>
                                    </CardCover>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            ) : (
                <Box
                    sx={{
                        alignItems: 'center',
                        minHeight: '10rem',
                        display: 'flex',
                        justifyContent: 'center',
                        border: `1px solid ${colors.footerTextColor}`,
                        borderRadius: 5,
                    }}
                >
                    <Typography
                        sx={{
                            color: colors.footerTextColor,
                            fontWeight: 600,
                            fontSize: { xs: 20, sm: 26, md: 28 },
                        }}
                    >
                        No cities Yet
                    </Typography>
                </Box>
            )}
        </Container>
    )
}
