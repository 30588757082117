import { z } from 'zod'
import {
    PropertyType,
    ResidentialType,
} from '@/src/pages/home/components/search-filter/types.ts'

export enum SortType {
    DEFAULT = 'newest',
    ASC_PRICE = 'low-price',
    DESC_PRICE = 'high-price',
}

export enum RentalTerm {
    MONTHLY = 'MONTHLY',
    //YEARLY = 'YEARLY',
    DAILY = 'DAILY',
}

export enum PropertyInformation {
    UNKNOWN = 'UNKNOWN',
    NEEDS_WORK = 'NEEDS_WORK',
    UNFINISHED = 'UNFINISHED',
    MOVE_IN_READY = 'MOVE_IN_READY',
}

export type PageQueryParams = {
    min_price?: string | null
    max_price?: string | null
    max_number_of_beds?: string | null
    max_number_of_bathrooms?: string | null
    property_types?: string | null
    isRegion?: boolean
    region?: string | null
    limit?: string | null
    page?: string | null
}

export const PropertyResponseSchema = z.object({
    id: z.string(),
    name: z.string(),
    overview: z.string(),
    price: z.number(),
    property_history: z.object({}).array().nullish(),
    property_floor_size: z.number(),
    number_of_bedrooms: z.number(),
    number_of_bathrooms: z.number(),
    security_deposit: z.number(),
    service_charge: z.number().default(0),
    rental_term: z.nativeEnum(RentalTerm),
    views_count: z.number().default(0),
    year_built: z.number(),
    space_type: z.enum(['entire_space', 'shared_space']),
    neighbourhood: z.object({
        id: z.string(),
        neighbourhood_name: z.string(),
        region: z.object({
            id: z.string(),
            region_name: z.string(),
        }),
    }),
    has_virtual_tour: z.boolean(),
    address1: z.string(),
    address2: z.string().nullish(),
    coords: z.object({
        id: z.string(),
        type: z.enum(['Point']),
        coordinates: z.number().array(),
    }),
    property_amenities: z
        .object({
            id: z.string(),
            name: z.string(),
            amenity_type: z.string(),
        })
        .array(),
    property_images: z
        .object({
            id: z.string(),
            image_url: z.string(),
            is_cover: z.boolean().default(false),
            is_active: z.boolean().default(false),
        })
        .array(),
    agent: z.object({
        id: z.string(),
        first_name: z.string(),
        last_name: z.string(),
    }),
    property_information: z
        .array(z.nativeEnum(PropertyInformation))
        .default([]),
    property_types: z.nativeEnum(PropertyType),
    residential_type: z.nativeEnum(ResidentialType),
    virtual_tour_url: z.string().optional(),
    property_status: z.enum([
        'ACTIVE',
        'IN_ACTIVE',
        'DRAFT',
        'IS_DELETED',
        'PENDING_APPROVAL',
    ]),
})

export const propertySchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    properties: PropertyResponseSchema.array(),
})

export const PropertySchema = propertySchema
export const PropertiesSchema = PropertyResponseSchema.array()
export const SinglePropertiesSchema = PropertyResponseSchema
export type Property = z.infer<typeof PropertyResponseSchema>
export type PropertyResponse = z.infer<typeof propertySchema>
