import { Box } from '@mui/material'
import { colors } from '@/utilities/colors.ts'

type TNumberOfBedroomsOrBathroomSelectorProps = {
    max: number
    onSelect: (value: number | string) => void
    value: number | string
}

export function NumberOfBedroomsOrBathroomSelector(
    props: TNumberOfBedroomsOrBathroomSelectorProps
) {
    const countList = Array.from({ length: props.max }, (_, i) => i + 1)

    function isSelected(selected: boolean) {
        if (selected) {
            return {
                backgroundColor: `${colors.primary} !important`,
            }
        }
        return {}
    }
    return (
        <Box display={'flex'} width={'100%'} mb={2}>
            {countList.map((count) => {
                const isFirst =
                    count === 1
                        ? {
                              borderTopLeftRadius: 3,
                              borderBottomLeftRadius: 3,
                          }
                        : {}
                const isLast =
                    count === props.max
                        ? {
                              borderTopRightRadius: 3,
                              borderBottomRightRadius: 3,
                              borderRight: `1px solid ${colors.gray10}`,
                          }
                        : {}

                return (
                    <Box
                        key={count}
                        sx={{
                            flex: 1,
                            borderLeft: `1px solid ${colors.gray10}`,
                            borderTop: `1px solid ${colors.gray10}`,
                            borderBottom: `1px solid ${colors.gray10}`,
                            textAlign: 'center',
                            py: 1,
                            cursor: 'pointer',
                            ...isFirst,
                            ...isLast,
                            ...isSelected(count === Number(props.value)),
                        }}
                        onClick={() => props.onSelect(count)}
                    >
                        {count}
                    </Box>
                )
            })}
        </Box>
    )
}
