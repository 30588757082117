import { colors } from '@/utilities/colors'
import { Box, List, ListItem, Typography } from '@mui/joy'
import { useGetUserNotifications } from '@/services/use-get-user-notifications'

export function Notifications() {
    const notifications = useGetUserNotifications()

    return (
        <Box sx={{ mx: 'auto', maxWidth: '50rem' }}>
            <Typography
                sx={{
                    my: '1rem',
                    color: colors.brown,
                    textAlign: 'center',
                    fontSize: '1.4rem',
                    fontWeight: 600,
                }}
            >
                Notifications
            </Typography>
            {(notifications.data || []).length > 0 ? (
                <List sx={{ display: 'grid', gap: '1.2rem' }}>
                    {(notifications.data || []).map((notification, i) => {
                        return (
                            <ListItem
                                key={i}
                                sx={{
                                    display: 'block',
                                    borderRadius: '0.8rem',
                                    textAlign: 'left',
                                    px: 2,
                                    py: 1,
                                    /* backgroundColor: notification.read
                                        ? colors.white
                                        : colors.gray15, */
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: colors.secondary,
                                        fontWeight: 600,
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    {notification.title}
                                </Typography>
                                <Typography sx={{ color: colors.brown }}>
                                    {notification.description}
                                </Typography>
                            </ListItem>
                        )
                    })}
                </List>
            ) : (
                <></>
            )}
        </Box>
    )
}
