import Sheet from '@mui/joy/Sheet'

import { MessagesPane } from '@/src/pages/secure/messaging/components/messages-pane'
import { ChatsPane } from '@/src/pages/secure/messaging/components/chats-pane'
import { useUserChatController } from '@/services/use-user-messaging'

export default function MyMessages() {
    const { selectedConversation, initiateNewConversation } =
        useUserChatController()

    return (
        <Sheet
            sx={{
                flex: 1,
                width: '100%',
                mx: 'auto',
                pt: { xs: 'var(--Header-height)', sm: 0 },
                display: 'grid',
                gridTemplateColumns: {
                    xs: '1fr',
                    sm: 'minmax(min-content, min(30%, 400px)) 1fr',
                },
            }}
        >
            <Sheet
                sx={{
                    position: { xs: 'fixed', sm: 'sticky' },
                    transform: {
                        xs: 'translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))',
                        sm: 'none',
                    },
                    transition: 'transform 0.4s, width 0.4s',
                    zIndex: 100,
                    width: '100%',
                    height: '100vh',
                    top: 60,
                }}
            >
                <ChatsPane />
            </Sheet>
            {(selectedConversation || initiateNewConversation) && (
                <MessagesPane />
            )}
        </Sheet>
    )
}
