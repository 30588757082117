import { Button } from '@mui/joy'
import SvgIcons from '@/components/svg-icon'
import { useUserOnboardingController } from '@/services/use-user-onboarding'

export function GoogleLoginButton() {
    const onboardingController = useUserOnboardingController()

    const showBtn = false
    if (showBtn) {
        return (
            <Button
                variant="soft"
                color="neutral"
                fullWidth
                startDecorator={<SvgIcons iconName={'google_icon'} />}
                onClick={onboardingController.onGoogleLogin}
            >
                Continue with Google
            </Button>
        )
    }
    return null
}
