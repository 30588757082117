import { Typography, Container, Box } from '@mui/joy'
import { RentersSubscription } from '@/src/pages/home/components/subscription/components/renters'
import { LandlordsSubscription } from '@/src/pages/home/components/subscription/components/landlords'

import { colors } from '@/utilities/colors.ts'
import React from 'react'

export function Subscription() {
    const [user, setUser] = React.useState('renters')

    return (
        <Container
            maxWidth={false}
            sx={{
                marginBottom: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: {
                    xs: 'start',
                    sm: 'center',
                },
                bgcolor: '#F2F2F2',
                width: '100%',
                padding: {
                    xs: 2,
                    sm: 10,
                },
            }}
        >
            {' '}
            <Box>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: { xs: 21, sm: 28, md: 36 },
                        color: colors.footerTextColor,
                        textAlign: { xs: 'center', sm: 'center' },
                        pt: 4,
                        pb: 4,
                        px: 2,
                    }}
                >
                    Choose preferred plan
                </Typography>
                <Typography
                    sx={{ textAlign: 'center', mt: -2, fontWeight: 400 }}
                >
                    Whether you're a landlord looking to maximize your
                    property's visibility or a renter searching for your next
                    home, Openprop has the perfect plan for you.
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        gap: 3,
                        border: '1px solid white',
                        bgcolor: 'white',
                        borderRadius: 35,
                        marginTop: '15px',
                        padding: '10px 15px',
                        // width: '250px',
                    }}
                >
                    <Box
                        onClick={() => setUser('renters')}
                        paddingX={'28px'}
                        paddingY={'10px'}
                        sx={
                            user === 'renters'
                                ? {
                                      color: colors.footerTextColor,
                                      fontWeight: 'lg',
                                      border: `1px solid ${colors.primary}`,
                                      borderRadius: 25,
                                      cursor: 'pointer',
                                  }
                                : { cursor: 'pointer', color: '#33323280' }
                        }
                    >
                        Renters
                    </Box>
                    <Box
                        onClick={() => setUser('landlords')}
                        paddingX={'28px'}
                        paddingY={'10px'}
                        sx={
                            user === 'landlords'
                                ? {
                                      color: colors.footerTextColor,
                                      fontWeight: 'lg',
                                      border: `1px solid ${colors.primary}`,
                                      borderRadius: 25,
                                      cursor: 'pointer',
                                  }
                                : { cursor: 'pointer', color: '#33323280' }
                        }
                    >
                        Landlords
                    </Box>
                </Box>
            </Box>
            {user === 'renters' ? (
                <RentersSubscription />
            ) : (
                <LandlordsSubscription />
            )}
        </Container>
    )
}
