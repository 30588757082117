import { useNavigate } from 'react-router-dom'
import { Button, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy'

type VerifyIdentityModalProps = {
    isOpen: boolean
    isPendingVerification: boolean
    onToggle?: () => void
}
export function VerifyIdentityModal(props: VerifyIdentityModalProps) {
    const navigate = useNavigate()
    function onClickContinue() {
        if (props.isPendingVerification) {
            props.onToggle && props.onToggle()
            return
        }
        navigate('/secure/verify-identity')
    }
    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onToggle && props.onToggle()}
        >
            <ModalDialog>
                {props.onToggle && <ModalClose />}
                <Typography fontWeight={500} fontSize={20}>
                    Verify Identity
                </Typography>
                <Typography>
                    {props.isPendingVerification
                        ? 'Verification in progress. We will notify you once it’s complete.'
                        : 'In order to create a listing, you must verify your identity by uploading your Ghana identity card to enable us prevent fraud or fake listings.'}
                </Typography>
                {props.onToggle && (
                    <Button onClick={onClickContinue}>Continue</Button>
                )}
            </ModalDialog>
        </Modal>
    )
}
