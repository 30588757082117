import React from 'react'
import { Navbar } from '@/src/components/navbar/index.tsx'
import { Footer } from '@/src/components/footer/index.tsx'
import { Subscription } from '@/src/pages/home/components/subscription/index.tsx'
import { Box } from '@mui/joy'

export function SubscriptionPage() {
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    bgcolor: '#F2F2F2',
                }}
            >
                <Navbar />
                <Subscription />
                <Footer />
            </Box>
        </React.Fragment>
    )
}
