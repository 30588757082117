import React from 'react'
import ReactDOM from 'react-dom/client'
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'

import App from './App.tsx'
import './index.css'
import './styles.css'

import { CssVarsProvider } from '@mui/joy/styles'
import { theme } from '@/utilities/theme'
import { StytchProvider } from '@stytch/react'
import { StytchUIClient } from '@stytch/vanilla-js'
import { queryClient } from '@/utilities/react-query-client'

import * as Sentry from '@sentry/react'
import { HelmetProvider } from 'react-helmet-async'

Sentry.init({
    dsn: 'https://1f01cb9fecc72ef5846c71aa4f3f91a4@o4507034186416128.ingest.us.sentry.io/4507034192838656',
    integrations: [
        // Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    enabled: !import.meta.env.DEV,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [
    //     'localhost',
    //     /^https:\/\/open-prop-staging.azurewebsites\.net\/api\/v1/,
    // ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
const stytch = new StytchUIClient(import.meta.env.VITE_STYTCH_PUBLIC_TOKEN)

function MainApp() {
    return (
        <React.StrictMode>
            <HelmetProvider>
                <StytchProvider stytch={stytch}>
                    <QueryClientProvider client={queryClient}>
                        <CssVarsProvider theme={theme}>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </CssVarsProvider>
                    </QueryClientProvider>
                </StytchProvider>
            </HelmetProvider>
        </React.StrictMode>
    )
}

const root = document.getElementById('root')!
if (root.hasChildNodes()) {
    ReactDOM.hydrateRoot(root, <MainApp />)
} else {
    ReactDOM.createRoot(root).render(<MainApp />)
}
