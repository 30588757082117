import { BlobServiceClient } from '@azure/storage-blob'
import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'

const obj: Record<string, string> = {
    'image/png': '.png',
    'image/jpg': '.jpg',
    'image/jpeg': '.jpeg',
}

export async function fileUpload(
    file: File,
    containerName: 'imagess' | 'identities'
) {
    try {
        const storageAccount = 'openproppropertyimages'
        const sasToken = import.meta.env.VITE_AZURE_SAS_TOKEN
        const blobService = new BlobServiceClient(
            `https://${storageAccount}.blob.core.windows.net/?${sasToken}`
        )
        const fileRename = new File(
            [file],
            `${nanoid()}-${DateTime.now().toMillis()}${obj[file.type]}`,
            { type: file.type, lastModified: file.lastModified }
        )
        const containerClient = blobService.getContainerClient(containerName)
        const blobClient = containerClient.getBlockBlobClient(fileRename.name)

        const options = {
            blobHTTPHeaders: {
                blobContentType: file.type,
            },
        }

        const res = await blobClient.uploadData(fileRename, options)

        if (res) {
            return `https://${storageAccount}.blob.core.windows.net/${containerName}/${fileRename.name}`
        }
    } catch (error) {
        throw Error('Error uploading file to Azure')
    }
}
