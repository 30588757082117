/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHelperText, Grid, Typography } from '@mui/joy'
import { TextInput } from '@/components/text-input'
import { colors } from '@/utilities/colors'

interface IAddress {
    address1: string
    address2: string
    is_address1_touched: boolean
    is_address2_touched: boolean
    address1_error: string | undefined
    address2_error: string | undefined
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleBlur: (e: React.FocusEvent<any, Element>) => void
}

export function AddressInput(props: IAddress) {
    return (
        <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={6}>
                <Typography>Address 1</Typography>
                <TextInput
                    size={'lg'}
                    name={'address1'}
                    placeholder={'Property Address'}
                    value={props.address1}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.is_address1_touched && !!props.address1_error}
                />
                <FormHelperText sx={{ color: colors.red }}>
                    {props.is_address1_touched && props.address1_error
                        ? props.address1_error
                        : undefined}
                </FormHelperText>
            </Grid>
            <Grid xs={12} sm={12} md={6}>
                <Typography>Address 2</Typography>
                <TextInput
                    size={'lg'}
                    name={'address2'}
                    placeholder={'Address optional'}
                    value={props.address2}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.is_address2_touched && !!props.address2_error}
                />
            </Grid>
        </Grid>
    )
}
