import { Box, Typography } from '@mui/joy'
import NoFavouriteIcon from '@/src/pages/secure/favourites/components/no-favourites/assets/no-favourites-icon.svg?react'

export function NoFavourite() {
    return (
        <Box
            width={'100%'}
            mt={6}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
        >
            <NoFavouriteIcon />
            <Box textAlign={'center'}>
                <Typography mb={2} level={'h2'}>
                    No Favourites Saved
                </Typography>
                <Typography>
                    Click the like button on the property to save property
                </Typography>
            </Box>
        </Box>
    )
}
