import axios from 'axios'
import Cookies from 'js-cookie'
import isNil from 'lodash/isNil'

const Axios = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
})

Axios.interceptors.request.use(
    (config) => {
        const jwt = Cookies.get('stytch_session_jwt')
        config.headers['x-origin'] = window.location.origin
        if (!isNil(jwt)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            config.headers['Authorization'] = `Bearer ${Cookies.get(
                'stytch_session_jwt'
            )}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

Axios.interceptors.response.use(
    (response) => {
        return response.data
    },
    (error) => {
        return Promise.reject(error)
    }
)

export { Axios }
