import { Select, Typography, Option, Box } from '@mui/joy'
import { useGetPropertiesLocationsFormatPlain } from '@/services/use-get-properties-locations'
import isNil from 'lodash/isNil'

interface IRegionSelector {
    handleRegionChange: (id: string) => void
    region_id: string
}
export function RegionSelector(props: IRegionSelector) {
    const locationsQuery = useGetPropertiesLocationsFormatPlain()
    const locations = locationsQuery.data || []
    return (
        <Box width={'20%'}>
            <Typography>Region</Typography>
            <Select
                placeholder="Choose a region"
                name={'region'}
                onChange={(_, value) => {
                    if (isNil(value)) {
                        return
                    }
                    props.handleRegionChange(value)
                }}
                value={props.region_id}
                sx={{ minWidth: '10rem' }}
            >
                {locations.map((region) => (
                    <Option key={region.id} value={region.id}>
                        {region.region_name}
                    </Option>
                ))}
            </Select>
        </Box>
    )
}
