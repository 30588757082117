import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import {
    Box,
    Grid,
    useTheme,
    Container,
    Typography,
    AspectRatio,
} from '@mui/joy'
import { DateTime } from 'luxon'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css'

import { Property } from '@/services/use-get-properties/types.ts'
import { PropertyDetails } from '@/src/pages/properties/[id]/components/property-details'
import { MorePropertySlider } from '@/src/pages/properties/[id]/components/more-property-slider'
import { MorePropertyPhotosModal } from '@/src/pages/properties/[id]/components/modals/images'
import { GoogleMapsView } from '@/components/google-maps-view'
import { colors } from '@/utilities/colors.ts'
import WhiteCube from '@/assets/svgs/white-cube.svg?react'
import { VirtualTourLimit } from '@/src/pages/properties/components/virtual-tour-limit'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { isAfter } from '@/utilities/dates.ts'
import { PropertyLoading } from '@/src/pages/properties/[id]/components/property-loading'
import { Property404 } from '@/src/pages/properties/[id]/components/property-404'

interface PropertyProp {
    property?: Property
    isLoading: boolean
}

export function SingleProperty(props: PropertyProp) {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
    const navigation = useNavigate()
    const authUserController = useGetAuthenticatedUser()
    const coverImage = props.property?.property_images.find(
        (img) => img.is_cover
    ) ||
        (props.property?.property_images || [])[0] || {
            image_url: '',
            _id: '1',
        }
    const otherImages =
        props.property?.property_images
            .filter((img) => !img.is_cover)
            .slice(0, 2) || []

    const hasVirtualTour =
        Boolean(props.property?.has_virtual_tour) &&
        Boolean(props.property?.virtual_tour_url?.trim().length)
    const virtual_tour_view_count =
        authUserController.user?.virtual_tour_view_count || 0
    const center = {
        lat: props.property?.coords.coordinates[0] || 5.614818,
        lng: props.property?.coords.coordinates[1] || -0.205874,
    }

    const hasSubscriptionExpired = isAfter(
        DateTime.fromJSDate(
            new Date(authUserController.user?.subscription_expiry || '')
        ),
        DateTime.local()
    )
    const showVirtualTour =
        (!hasSubscriptionExpired && hasVirtualTour) ||
        (virtual_tour_view_count > 0 && hasVirtualTour)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.href])

    return (
        <Container maxWidth={'xl'}>
            <Box sx={{ px: { xs: 2, md: 4 }, mt: 2 }}>
                {props.isLoading && <PropertyLoading />}
                {!props.property && !props.isLoading && <Property404 />}
                {!isTablet ? (
                    props.property && (
                        <Box mb={5} maxHeight={500} height={500}>
                            <Grid
                                container
                                spacing={1}
                                maxHeight={500}
                                height={500}
                            >
                                <Grid
                                    md={8}
                                    sm={8}
                                    sx={{ position: 'relative' }}
                                >
                                    {props.property?.has_virtual_tour && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                zIndex: 2,
                                                left: 30,
                                                top: 15,
                                                display: 'flex',
                                                gap: 1,
                                                alignItems: 'center',
                                                px: 2,
                                                borderRadius: 5,
                                                backgroundColor:
                                                    'rgba(6, 6, 6, 0.477)',
                                            }}
                                        >
                                            <WhiteCube width={30} height={30} />
                                            <Typography sx={{ color: 'white' }}>
                                                Virtual tour
                                            </Typography>
                                        </Box>
                                    )}
                                    <AspectRatio
                                        objectFit="cover"
                                        minHeight={500}
                                        maxHeight={500}
                                        sx={{ borderRadius: 15 }}
                                    >
                                        <img
                                            src={coverImage.image_url}
                                            alt={'property cover image'}
                                        />
                                    </AspectRatio>
                                </Grid>
                                <Grid md={4} sm={4}>
                                    <Box height={500}>
                                        <Grid container spacing={1}>
                                            {otherImages.map((img, idx) => (
                                                <Grid
                                                    md={12}
                                                    sm={12}
                                                    key={idx}
                                                    position={'relative'}
                                                >
                                                    <AspectRatio
                                                        objectFit="cover"
                                                        maxHeight={246}
                                                        minHeight={246}
                                                        sx={{
                                                            borderRadius: 15,
                                                        }}
                                                    >
                                                        <img
                                                            src={img.image_url}
                                                            alt={
                                                                'property images'
                                                            }
                                                        />
                                                        {otherImages.length -
                                                            1 ===
                                                            idx && (
                                                            <Box
                                                                position={
                                                                    'absolute'
                                                                }
                                                                bottom={10}
                                                                left={0}
                                                                right={0}
                                                                display={'flex'}
                                                                justifyContent={
                                                                    'center'
                                                                }
                                                            >
                                                                <MorePropertyPhotosModal
                                                                    property={
                                                                        props.property
                                                                    }
                                                                />
                                                            </Box>
                                                        )}
                                                    </AspectRatio>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                ) : (
                    <Swiper
                        modules={[Navigation, Pagination]}
                        slidesPerView={1}
                        onClick={() => navigation(`propertyPictures`)}
                        navigation
                        pagination={{ clickable: true }}
                    >
                        {(props.property?.property_images || []).map(
                            (image, idx) => (
                                <SwiperSlide key={idx}>
                                    <img
                                        src={image.image_url}
                                        alt="The image of the property"
                                        loading="lazy"
                                        style={{
                                            height: '300px',
                                            width: '100%',
                                            borderRadius: 8,
                                        }}
                                    />
                                </SwiperSlide>
                            )
                        )}
                    </Swiper>
                )}
                {props.property && (
                    <>
                        <PropertyDetails property={props.property} />
                        <Typography
                            level={'body-md'}
                            sx={{
                                fontWeight: 600,
                                mb: 1,
                                color: colors.brown,
                            }}
                        >
                            Virtual tour
                        </Typography>
                        <VirtualTourLimit
                            isVisible={showVirtualTour}
                            src={props.property?.virtual_tour_url}
                        />
                        <Typography
                            mt={3}
                            mb={1}
                            fontSize={'1rem'}
                            fontWeight={600}
                            sx={{
                                color: colors.brown,
                            }}
                        >
                            Map
                        </Typography>
                        <GoogleMapsView
                            center={center}
                            properties={props.property ? [props.property] : []}
                            containerStyle={{ width: '100%', height: 500 }}
                        />
                    </>
                )}
                <MorePropertySlider />
            </Box>
        </Container>
    )
}
