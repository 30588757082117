import { Dropdown } from '@/components/dropdown'
import { PropertyType } from '@/src/pages/home/components/search-filter/types.ts'
import { SelectPropertyTypes } from '@/components/select-property-type-dropdown/select-property-types'

type TSelectPropertyTypeDropdownProps = {
    value: PropertyType
    onChange: (value: PropertyType) => void
    isOpen: boolean
    onToggle: (value: boolean) => void
}

export function SelectPropertyTypeDropdown(
    props: TSelectPropertyTypeDropdownProps
) {
    return (
        <Dropdown
            isSelected={props.value !== PropertyType.UNKNOWN}
            title={'Type'}
            onToggle={props.onToggle}
            isOpen={props.isOpen}
        >
            <SelectPropertyTypes
                value={props.value}
                onChange={props.onChange}
            />
        </Dropdown>
    )
}
