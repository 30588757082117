import React from 'react'
import {
    ListDivider,
    Box,
    ListItemButton,
    ListItemButtonProps,
    Stack,
    Typography,
    ListItem,
    AspectRatio,
} from '@mui/joy'
import { toggleMessagesPane } from '@/utilities/message'
import { UserChatList } from '@/services/use-user-messaging/provider/types'
import { colors } from '@/utilities/colors'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'

type ChatListItemProps = ListItemButtonProps & {
    user: UserChatList
    onConversationSelect?: () => void
}

export function ChatListItem(props: ChatListItemProps) {
    const authController = useGetAuthenticatedUser()

    const propertyImage = props.user.property_image_url

    function getUserName() {
        if (props.user.user_id === authController.user?.id) {
            return props.user.landlord_name
        } else {
            return props.user.user_name
        }
    }

    function getUnreadMessagesCount(): number {
        if (
            props.user.last_message_sender_id !== authController.user?.id &&
            props.user.count_unread_messages &&
            props.user.count_unread_messages > 0
        ) {
            return props.user.count_unread_messages
        }
        return 0
    }
    return (
        <React.Fragment>
            <ListItem onClick={props.onConversationSelect}>
                <ListItemButton
                    onClick={() => {
                        toggleMessagesPane()
                    }}
                    selected={props.selected}
                    color="neutral"
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'initial',
                        gap: 1,
                    }}
                >
                    <Box
                        sx={{
                            width: '60px',
                            height: '50px',
                            border: `1px solid ${colors.brown}`,
                            borderRadius: '0.5rem',
                            overflow: 'hidden',
                        }}
                    >
                        <AspectRatio ratio="2" minHeight={80}>
                            <img
                                src={propertyImage}
                                srcSet={propertyImage}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </Box>
                    <Stack
                        direction="column"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Stack
                            direction="row"
                            spacing={1.5}
                            sx={{ alignItems: 'center' }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                }}
                            >
                                <Typography level="title-sm">
                                    {getUserName()}
                                </Typography>

                                {getUnreadMessagesCount() > 0 && (
                                    <Typography sx={{ fontWeight: 600 }}>
                                        {getUnreadMessagesCount()}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    lineHeight: 1.5,
                                    textAlign: 'right',
                                }}
                            >
                                <Typography
                                    level="body-xs"
                                    display={{ xs: 'none', md: 'block' }}
                                    noWrap
                                >
                                    {/* {DateTime.fromSeconds(
                                        props.user.timestamp.seconds
                                    ).toRelative()} */}
                                </Typography>
                            </Box>
                        </Stack>
                        <Box sx={{ display: 'grid', gap: 2 }}>
                            <Typography
                                level="body-sm"
                                sx={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontWeight:
                                        getUnreadMessagesCount() > 0
                                            ? 800
                                            : 400,
                                }}
                            >
                                {props.user.lastMessage || ''}
                            </Typography>
                        </Box>
                    </Stack>
                </ListItemButton>
            </ListItem>
            <ListDivider sx={{ margin: 0 }} />
        </React.Fragment>
    )
}
