import React from 'react'
import { Typography, Grid, Card, Box } from '@mui/joy'
import * as Sentry from '@sentry/react'
import { PropertyIcon } from '@/assets/svg-components/property-icon.tsx'
import { PropertyViewIcon } from '@/assets/svg-components/property-view-icon.tsx'
import { PropertyRentedIcon } from '@/assets/svg-components/property-rented-icon.tsx'
import styles from '@/src/pages/secure/dashboard/styles.module.css'

import { OtherProperties } from '@/src/pages/secure/dashboard/components/other-properties'
import { Text } from '@/components/text'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { SecureHeader } from '@/src/pages/secure/components/secure-header'
import { useGetLandlordProperties } from '@/services/use-get-properties'
import { SubscriptionBanner } from '@/components/banner'

export function Dashboard() {
    const userQuery = useGetAuthenticatedUser()
    const properties = useGetLandlordProperties()
    const propertiesViewCount = (properties.data?.properties || []).reduce(
        (t, c) => t + c.views_count,
        0
    )

    React.useEffect(() => {
        if (properties.error) {
            Sentry.captureException(properties.error)
        }
    }, [properties.error])
    return (
        <React.Fragment>
            <SubscriptionBanner />
            <SecureHeader />
            <Box padding={2}>
                <Box mt={2} mb={5} px={4}>
                    <Typography
                        sx={{
                            fontSize: { xs: 20, sm: 30, md: 40 },
                            fontWeight: 600,
                            mt: -3,
                        }}
                    >
                        Hello, {userQuery.user?.first_name}
                    </Typography>
                    <Typography sx={{ fontSize: 18 }}>
                        Welcome to your dashboard
                    </Typography>
                </Box>
                <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                    <Grid xs={12} sm={6} md={7} lg={8} container spacing={2}>
                        <Grid xs={12} md={6}>
                            <Card className={styles.card}>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    sx={{
                                        gap: 1.5,
                                    }}
                                >
                                    <PropertyViewIcon
                                        fill={'#5E8874'}
                                        width={40}
                                    />
                                    <Text
                                        sx={{ fontSize: 18, fontWeight: 600 }}
                                    >
                                        Property Views
                                    </Text>
                                </Box>
                                <Text sx={{ fontSize: 54, fontWeight: 'bold' }}>
                                    {propertiesViewCount || 0}
                                </Text>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Card className={styles.card}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1.5,
                                    }}
                                >
                                    <PropertyRentedIcon
                                        fill={'#5E8874'}
                                        width={40}
                                    />
                                    <Text
                                        sx={{ fontSize: 18, fontWeight: 600 }}
                                    >
                                        Properties Rented
                                    </Text>
                                </Box>
                                <Text sx={{ fontSize: 54, fontWeight: 'bold' }}>
                                    0
                                </Text>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Card className={styles.card}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1.5,
                                    }}
                                >
                                    <PropertyIcon fill={'#5E8874'} width={40} />
                                    <Text
                                        sx={{ fontSize: 18, fontWeight: 600 }}
                                    >
                                        Properties listed
                                    </Text>
                                </Box>
                                <Text sx={{ fontSize: 54, fontWeight: 'bold' }}>
                                    {(properties.data?.properties || []).length}
                                </Text>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <OtherProperties />
                </Box>
            </Box>
        </React.Fragment>
    )
}
