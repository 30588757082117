import { Box, Card, Typography } from '@mui/joy'
import { Link } from 'react-router-dom'
import ViewMoreCardIcon from '@/assets/svgs/view-more-card-icon.svg?react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

export function ViewMoreCard() {
    return (
        <Link to="/properties">
            <Card
                variant="outlined"
                sx={{
                    boxShadow: 'sm',
                    minHeight: '19rem',
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box display="grid" gap={2}>
                    <ViewMoreCardIcon style={{ margin: '0 auto' }} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography level="body-lg" sx={{ fontSize: '1.2rem' }}>
                            View More Properties
                        </Typography>
                        <ChevronRightIcon />
                    </Box>
                </Box>
            </Card>
        </Link>
    )
}
