type TPropertyIconProps = {
    fill: string
    width?: number
    height?: number
}

export function PropertyIcon(props: TPropertyIconProps) {
    return (
        <svg
            width={props.width || 60}
            height={props.height || 60}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.5 10.375C26.5 10.85 26.1 11.25 25.625 11.25H22.8C17.4 11.25 13 15.65 13 21.05V44.125C13 44.6 12.6 45 12.125 45H10.375C7.4 45 5 42.6 5 39.625V10.375C5 7.4 7.4 5 10.375 5H21.125C24.1 5 26.5 7.4 26.5 10.375Z"
                fill={props.fill}
            />
            <path
                d="M55 10.375V39.625C55 42.6 52.6 45 49.625 45H48.05C47.575 45 47.175 44.6 47.175 44.125V21.05C47.175 15.65 42.775 11.25 37.375 11.25H34.375C33.9 11.25 33.5 10.85 33.5 10.375C33.5 7.4 35.9 5 38.875 5H49.625C52.6 5 55 7.4 55 10.375Z"
                fill={props.fill}
            />
            <path
                d="M37.375 15H22.8C19.45 15 16.75 17.7 16.75 21.05V48.95C16.75 52.3 19.45 55 22.8 55H26.875C27.575 55 28.125 54.45 28.125 53.75V47.5C28.125 46.475 28.975 45.625 30 45.625C31.025 45.625 31.875 46.475 31.875 47.5V53.75C31.875 54.45 32.425 55 33.125 55H37.4C40.725 55 43.425 52.3 43.425 48.975V21.05C43.425 17.7 40.725 15 37.375 15ZM35 36.875H25C23.975 36.875 23.125 36.025 23.125 35C23.125 33.975 23.975 33.125 25 33.125H35C36.025 33.125 36.875 33.975 36.875 35C36.875 36.025 36.025 36.875 35 36.875ZM35 29.375H25C23.975 29.375 23.125 28.525 23.125 27.5C23.125 26.475 23.975 25.625 25 25.625H35C36.025 25.625 36.875 26.475 36.875 27.5C36.875 28.525 36.025 29.375 35 29.375Z"
                fill={props.fill}
            />
        </svg>
    )
}
