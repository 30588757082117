import { Box, Typography } from '@mui/joy'

export function NoProperty() {
    return (
        <Box px={3}>
            <h2>No results found for filter set</h2>
            <Typography>Other properties you may be interested in</Typography>
        </Box>
    )
}
