import { Footer } from '@/components/footer'
import { Navbar } from '@/components/navbar'
import { Box, Container, Typography } from '@mui/joy'
import React from 'react'

export function VirtualTourPage() {
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: '#F2F2F2',
                    minHeight: '100vh',
                }}
            >
                <Navbar />
                <Container sx={{ mt: { xs: '2rem', md: '5rem' } }}>
                    <Typography
                        level="body-lg"
                        sx={{
                            lineHeight: { xs: 1.6, md: 2 },
                            textAlign: { md: 'center' },
                        }}
                    >
                        Say goodbye to agent viewing fees with Openprop's
                        virtual tours. Our technology offers a stress-free
                        viewing experience, helping renters and landlords save
                        time, money and avoid usual stress that comes with
                        apartment hunting.
                    </Typography>
                    <Typography mt={4} level="h4">
                        Here is how it works :{' '}
                    </Typography>
                    <iframe
                        width="100%"
                        height="500"
                        src={
                            'https://kuula.co/share/collection/7Z7Jc?logo=1&info=1&logosize=122&fs=1&vr=0&sd=1&initload=0&thumbs=1'
                        }
                        allowFullScreen
                        allow="xr-spatial-tracking"
                    />
                </Container>
                <br />
                <Footer />
            </Box>
        </React.Fragment>
    )
}
