import { useMediaQuery } from '@mui/material'
import { Box, useTheme } from '@mui/joy'
import { useGetProperty } from '@/services/use-get-properties'
import GoBackIcon from '@/assets/svgs/arrow-circle-left.svg?react'
import { useNavigate, useParams } from 'react-router-dom'

export function SinglePropertyPictures() {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
    const { propertyId } = useParams()
    const property = useGetProperty(propertyId)
    const propertyPictures = property?.data?.property_images
    const navigation = useNavigate()

    if (isTablet) {
        return (
            <Box mt={2}>
                <Box mb={2} sx={{ padding: 2 }} onClick={() => navigation(-1)}>
                    <GoBackIcon />
                </Box>
                <Box
                    mb={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        padding: 2,
                    }}
                >
                    {propertyPictures &&
                        propertyPictures.map((img, idx) => (
                            <img
                                src={img.image_url}
                                alt={'property images'}
                                key={idx}
                                style={{
                                    height: '300px',
                                    width: '100%',
                                    borderRadius: 8,
                                    objectFit: 'cover',
                                }}
                            />
                        ))}
                </Box>
            </Box>
        )
    }

    return null
}
