import { ImageData } from '@/src/pages/secure/properties/create-property/hooks/use-create-property/types.ts'
import React from 'react'
import { nanoid } from 'nanoid'
import { useDropzone } from 'react-dropzone'
import { Box, Card, Typography } from '@mui/joy'

export function DropZone(props: { setImages: (file: ImageData) => void }) {
    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        // Do something with the files
        acceptedFiles.map((file) => {
            const reader = new FileReader()

            reader.onload = function (e) {
                if (typeof e.target?.result === 'string') {
                    props.setImages({
                        id: nanoid(),
                        src: e.target?.result,
                        file: file,
                    })
                }
            }

            reader.readAsDataURL(file)
            return file
        })
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true,
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    })

    return (
        <Box sx={{ mt: 4 }} {...getRootProps()}>
            <Card
                sx={{
                    height: 200,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <input {...getInputProps()} />

                <Typography level={'h3'}>
                    {isDragActive
                        ? 'Drop the files here...'
                        : "Drag 'n' drop or click to select images..."}
                </Typography>
            </Card>
        </Box>
    )
}
