import {
    Grid,
    Box,
    Typography,
    Container,
    // useMediaQuery
} from '@mui/material'
import { colors } from '@/src/utilities/colors.ts'

import UserIcon from '@/assets/svgs/user.svg?react'
import HomeIcon from '@/assets/svgs/home.svg?react'
import CubeIcon from '@/assets/svgs/3dcube.svg?react'

import styles from '@/src/components/footer/footer.module.css'

export function HowOpenPropsCanHelp() {
    // const isMobile = useMediaQuery('(max-width:768px)')
    const items = [
        {
            Icon: HomeIcon, // width={isMobile ? 25 : 32} height={isMobile ? 25 : 32} />,
            title: 'List properties for free',
            description:
                'Openprop allows property owners to list apartments and townhouses at no charge.',
        },
        {
            Icon: CubeIcon, // width={isMobile ? 25 : 32} height={isMobile ? 25 : 32} />,
            title: 'Get virtual tour for properties',
            description:
                'Save money on agent viewing fees by choosing a convenient way to view any property you want. Use virtual tour available on Openprop.',
        },
        {
            Icon: UserIcon, //width={isMobile ? 25 : 32} height={isMobile ? 25 : 32} />,
            title: 'Earn extra cash as an Openprop host',
            description: 'List spare rooms in your home for short stays.',
        },
    ]
    return (
        <Box
            className={styles.how_it_works_container}
            sx={{
                gap: { xs: '1rem', sm: '2rem' },
                mb: { xs: '2rem', sm: '0' },
            }}
        >
            <Typography
                variant="h3"
                className={styles.how_it_works_title}
                sx={{
                    fontSize: { xs: 21, sm: 28, md: 36 },
                    fontWeight: 700,
                    color: colors.primary,
                }}
            >
                See how Openprop can help you
            </Typography>
            <Container>
                <Grid
                    container
                    justifyContent={'center'}
                    gap={2}
                    sx={{
                        flexDirection: { sm: 'row', xs: 'column' },
                        gap: { sm: 0 },
                    }}
                >
                    {items.map(({ Icon, title, description }, index) => (
                        <Grid
                            item
                            sx={{
                                width: {
                                    sm: '100%',
                                    md: '33.333%',
                                    lg: '33.333%',
                                },
                                display: {
                                    sm: 'flex',
                                    xs: 'flex',
                                    md: 'block',
                                },
                            }}
                            key={index}
                        >
                            <Box
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    marginRight: { sm: 2, xs: 2 },
                                }}
                            >
                                <Box
                                    className={styles.item_icon}
                                    sx={{
                                        minWidth: { sm: '5rem' },
                                        minHeight: { sm: '5rem' },
                                        bgcolor: colors.primary,
                                    }}
                                >
                                    <Icon />
                                </Box>
                            </Box>
                            <Box className={styles.item_content}>
                                <Typography
                                    variant={'h3'}
                                    sx={{
                                        color: colors.secondary,
                                        my: { sm: '1.6rem' },
                                        textAlign: { sm: 'center' },
                                        fontSize: { sm: '19px' },
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    variant={'body1'}
                                    className={styles.item_description}
                                    sx={{
                                        color: colors.footerTextColor,
                                        textAlign: { sm: 'center' },
                                    }}
                                >
                                    {description}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}
