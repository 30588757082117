import { useMediaQuery } from '@mui/material'
import { Typography, Box } from '@mui/joy'

import Vector from '../../assets/vector.svg'
import { SearchFilter } from '@/src/pages/home/components/search-filter/index.tsx'
import { Navbar } from '@/src/components/navbar/index.tsx'
import style from './style.module.css'
import { colors } from '@/utilities/colors.ts'

export function Header() {
    const isMobile = useMediaQuery('(max-width:600px)')

    return (
        <Box
            className={style.header}
            sx={{
                backgroundColor: colors.lightGreen,
                height: {
                    xs: 450,
                    sm: 560,
                    md: 780,
                    lg: 800,
                    xl: 1100,
                },
            }}
        >
            <Navbar />
            <Box className={style.vector}>
                <img src={Vector} alt="Vector" />
            </Box>
            <Box className={style.main}>
                <Typography
                    className={style.main_h3}
                    sx={{
                        color: colors.brown,
                        whiteSpace: 'wrap',
                        marginTop: { xs: '2rem', sm: '2rem', md: '4rem' },
                        py: 2,
                        fontSize: 50,
                    }}
                >
                    {isMobile ? (
                        <>
                            Renting made easy <br />
                            for everyone.
                        </>
                    ) : (
                        'Renting made easy for everyone.'
                    )}
                </Typography>
                <SearchFilter />
            </Box>
        </Box>
    )
}
