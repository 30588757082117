import React from 'react'
import {
    Box,
    Button,
    FormLabel,
    formLabelClasses,
    LinearProgress,
    Stack,
    Typography,
} from '@mui/joy'
import { Link } from 'react-router-dom'
import { Divider, FormControl } from '@mui/material'
import { DateTime } from 'luxon'
import { useUserOnboardingController } from '@/services/use-user-onboarding'
import { useFormik } from 'formik'
import { signUpSchema } from '@/src/pages/auth/sign-up/schema'
import { TextInput } from '@/components/text-input'
import { PasswordCriteria } from '@/components/password-criteria'

import { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem'
import { Header } from '@/src/pages/auth/components/header'
import { colors } from '@/utilities/colors'
import { GoogleLoginButton } from '@/components/google-login-button'

export function SignUp() {
    const onboardingController = useUserOnboardingController()
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
        },
        validationSchema: signUpSchema,
        onSubmit: async (values) => {
            await onboardingController.onRegister(values)
        },
    })
    const [passwordStrength, setPasswordStrength] = React.useState({
        valid_password: false,
        feedback: {
            has_digit: false,
            has_lower_case: false,
            has_symbol: false,
            has_upper_case: false,
        },
        missing_characters: 0,
        missing_complexity: 0,
    })

    const [showPasswordCriteria, setShowPasswordCriteria] =
        React.useState(false)

    const passwordErrorMessage = React.useMemo(() => {
        let errorMessage = ''

        if (formik.errors.password) {
            errorMessage = formik.errors.password
            return errorMessage
        }

        if (!passwordStrength.valid_password) {
            errorMessage = 'Password is invalid'
            return errorMessage
        }

        return errorMessage
    }, [passwordStrength, formik.errors.password])

    function getPasswordStrengthState(password: string): {
        value: number
        color: DefaultColorPalette
    } {
        if (password.length < 10) {
            return {
                value: 25,
                color: 'danger',
            }
        }
        const isPasswordUpToStandard = Object.values(
            passwordStrength.feedback
        ).every((val) => val)
        if (
            password.length >= 10 &&
            !passwordStrength.valid_password &&
            !isPasswordUpToStandard
        ) {
            return {
                value: 60,
                color: 'warning',
            }
        }

        if (passwordStrength.valid_password) {
            return {
                value: 100,
                color: 'success',
            }
        }
        return {
            value: 0,
            color: 'danger',
        }
    }

    React.useEffect(() => {
        if (formik.values.password.length === 0) {
            return
        }

        async function checkPassword() {
            try {
                const passwordValidity =
                    await onboardingController.checkPasswordStrength(
                        formik.values.password
                    )

                if (!passwordValidity) {
                    return
                }
                setPasswordStrength({
                    valid_password: passwordValidity.valid_password,
                    feedback: {
                        has_digit:
                            passwordValidity.feedback.luds_requirements
                                .has_digit,
                        has_lower_case:
                            passwordValidity.feedback.luds_requirements
                                .has_lower_case,
                        has_symbol:
                            passwordValidity.feedback.luds_requirements
                                .has_symbol,
                        has_upper_case:
                            passwordValidity.feedback.luds_requirements
                                .has_upper_case,
                    },
                    missing_characters:
                        passwordValidity.feedback.luds_requirements
                            .missing_characters,
                    missing_complexity:
                        passwordValidity.feedback.luds_requirements
                            .missing_complexity,
                })
            } catch (e) {
                console.log(e)
            }
        }
        void checkPassword()
    }, [formik.values.password, onboardingController])

    const p = getPasswordStrengthState(formik.values.password)

    // if (authUserController.isAuth) {
    //     return <Navigate to={'/secure'} />
    // }

    return (
        <Box>
            <Header />
            <Box
                component="main"
                my={'auto'}
                py={2}
                pb={5}
                display={'flex'}
                flexDirection={'column'}
                gap={2}
                mx={'auto'}
                borderRadius={'sm'}
                sx={{
                    width: 400,
                    maxWidth: '100%',
                    '& form': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    },
                    [`& .${formLabelClasses.asterisk}`]: {
                        visibility: 'hidden',
                    },
                }}
            >
                <Stack gap={4} sx={{ mb: 2 }}>
                    <Stack gap={1}>
                        <Typography level="h3">Sign Up</Typography>
                        <Typography level="body-sm">
                            Already have an account?{' '}
                            <Link to={'/auth/login'}>
                                <Typography level="title-sm">
                                    Sign in!
                                </Typography>
                            </Link>
                        </Typography>
                    </Stack>
                    <GoogleLoginButton />
                </Stack>
                <Divider>or</Divider>
                <Stack gap={4} sx={{ mt: 2 }}>
                    <FormControl required>
                        <FormLabel>Firstname</FormLabel>
                        <TextInput
                            type="text"
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorText={
                                formik.touched.firstName &&
                                formik.errors.firstName
                                    ? formik.errors.firstName
                                    : ''
                            }
                        />
                    </FormControl>
                    <FormControl required>
                        <FormLabel>Lastname</FormLabel>
                        <TextInput
                            type="text"
                            name="lastName"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorText={
                                formik.touched.lastName &&
                                formik.errors.lastName
                                    ? formik.errors.lastName
                                    : ''
                            }
                        />
                    </FormControl>
                    <FormControl required>
                        <FormLabel>Email</FormLabel>
                        <TextInput
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorText={
                                formik.touched.email && formik.errors.email
                                    ? formik.errors.email
                                    : ''
                            }
                        />
                    </FormControl>
                    <FormControl required>
                        <FormLabel>Password</FormLabel>
                        <TextInput
                            type="password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={() => setShowPasswordCriteria(true)}
                            errorText={
                                formik.touched.password && passwordErrorMessage
                                    ? passwordErrorMessage
                                    : ''
                            }
                        />
                        {formik.values.password.length > 0 && (
                            <Box>
                                <LinearProgress
                                    determinate
                                    color={p.color}
                                    value={p.value}
                                />
                            </Box>
                        )}
                        {(formik.touched.password || showPasswordCriteria) && (
                            <Box
                                sx={{
                                    border: `1px solid ${colors.gray}`,
                                    borderRadius: 5,
                                    my: 2,
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1.5,
                                }}
                            >
                                <Typography sx={{ fontWeight: 'lg', mb: 1 }}>
                                    Your password must contain:
                                </Typography>
                                <PasswordCriteria
                                    text="At least one lowercase character"
                                    isValid={
                                        passwordStrength.feedback.has_lower_case
                                    }
                                />
                                <PasswordCriteria
                                    text="At least one uppercase character"
                                    isValid={
                                        passwordStrength.feedback.has_upper_case
                                    }
                                />
                                <PasswordCriteria
                                    text="At least one number"
                                    isValid={
                                        passwordStrength.feedback.has_digit
                                    }
                                />
                                <PasswordCriteria
                                    text=" At least one special character
                                        (e.g.,!@#$%^&*)"
                                    isValid={
                                        passwordStrength.feedback.has_symbol
                                    }
                                />
                            </Box>
                        )}
                    </FormControl>
                    <Stack>
                        <Typography>
                            By signing up, you agree to our{' '}
                            <Link
                                to={'/terms-of-service'}
                                style={{ color: colors.primary }}
                            >
                                Terms
                            </Link>{' '}
                            and{' '}
                            <Link
                                to={'/privacy-policy'}
                                style={{ color: colors.primary }}
                            >
                                Privacy Policy
                            </Link>{' '}
                        </Typography>
                    </Stack>
                    <Stack gap={4} sx={{ mt: 2 }}>
                        <Button
                            disabled={formik.isSubmitting}
                            type="submit"
                            fullWidth
                            onClick={() => formik.handleSubmit()}
                        >
                            Sign up
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            <Box component="footer" sx={{ py: 3 }}>
                <Typography level="body-xs" textAlign="center">
                    © Openprop {DateTime.now().year}
                </Typography>
            </Box>
        </Box>
    )
}
