import { Box } from '@mui/material'
import { Tab, TabList, TabPanel, Tabs } from '@mui/joy'
import { Identification } from '@/src/pages/secure/identity/identification'
// import { Guarantor } from '@/src/pages/secure/identity/guarantor'
import { colors } from '@/utilities/colors'
import { SecureHeader } from '@/src/pages/secure/components/secure-header'

const tabMenu = [
    'Identification',
    //  'Guarantor'
]

export function VerifyIdentity() {
    return (
        <>
            <SecureHeader />
            <Box p={3}>
                <Tabs
                    aria-label="Scrollable tabs"
                    defaultValue={0}
                    sx={{ '--Tab-indicatorThickness': '7px' }}
                >
                    <TabList
                        sx={{
                            overflow: 'auto',
                            scrollSnapType: 'x mandatory',
                            '&::-webkit-scrollbar': { display: 'none' },
                        }}
                    >
                        {tabMenu.map((title) => (
                            <Tab
                                key={title}
                                sx={{
                                    flex: 'none',
                                    scrollSnapAlign: 'start',
                                    '&.Mui-selected::after': {
                                        background: colors.secondary,
                                    },
                                    ['&.Mui-selected:focus']: {
                                        outline: 0,
                                    },
                                }}
                            >
                                {title}
                            </Tab>
                        ))}
                    </TabList>
                    <Box>
                        <TabPanel value={0}>
                            <Identification />
                        </TabPanel>
                        {/*<TabPanel value={1}>*/}
                        {/*    <Guarantor />*/}
                        {/*</TabPanel>*/}
                    </Box>
                </Tabs>
            </Box>
        </>
    )
}
