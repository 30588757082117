export function getFullAddress(data: {
    address1: string
    address2?: string | null
    neighbourhood: string
    city: string
}) {
    let fullAddress = data.address1
    if (data.address2) {
        fullAddress += `, ${data.address2}`
    }

    return `${fullAddress}, ${data.neighbourhood}, ${data.city}`
}
