import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Button, Container } from '@mui/joy'

import Close from '@/assets/svgs/close-icon.svg?react'
import { colors } from '@/utilities/colors.ts'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { UserType } from '@/services/use-get-auth-user/schema'
import { DateTime } from 'luxon'

const CLOSE_SUBSCRIPTION_BANNER_TIMESTAMP_KEY =
    'subscriptionBannerCloseTimestamp'

const bannerStatus = localStorage.getItem(
    CLOSE_SUBSCRIPTION_BANNER_TIMESTAMP_KEY
)

function shouldHideBanner() {
    if (!bannerStatus) {
        return false
    }

    return (
        DateTime.fromISO(bannerStatus).diff(DateTime.local(), 'hours').hours <
        24
    )
}

export function SubscriptionBanner() {
    const [hideBanner, setHideBanner] = React.useState(true)
    const navigation = useNavigate()
    const authController = useGetAuthenticatedUser()

    React.useEffect(() => {
        if (bannerStatus) {
            setHideBanner(shouldHideBanner())
        }
    }, [])

    if (hideBanner) return null

    if (authController.isSubscriptionActive) return null

    if (authController.isFreeTrial && authController.viewCount > 0) return null

    return (
        <Box
            bgcolor={colors.primary}
            paddingX={1}
            display={'flex'}
            position={'relative'}
            sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                gap: { xs: 2, sm: '2%' },
                alignItems: { xs: 'start', sm: 'center' },
            }}
        >
            <Container>
                <Box display={'flex'}>
                    <Box
                        flex={8}
                        sx={{
                            display: 'flex',
                            paddingY: 1,
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: { xs: 'stretch', sm: 'center' },
                        }}
                        justifyContent={'center'}
                        gap={2}
                    >
                        <Box>
                            <Typography sx={{ color: 'white' }}>
                                Save time and money — experience convenience
                                with virtual viewings!
                            </Typography>
                        </Box>
                        {authController.user?.user_type ===
                        UserType.LANDLORD ? (
                            <Button
                                sx={{
                                    bgcolor: colors.footerTextColor,
                                    color: colors.white,
                                    fontWeight: 600,
                                }}
                                onClick={() =>
                                    navigation('/secure/properties/create')
                                }
                            >
                                Subscribe now
                            </Button>
                        ) : (
                            <Button
                                sx={{
                                    bgcolor: colors.footerTextColor,
                                    color: colors.white,
                                    fontWeight: 600,
                                }}
                                onClick={() => navigation('/pricing')}
                            >
                                Subscribe now
                            </Button>
                        )}
                    </Box>
                    <Box
                        flex={1}
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            localStorage.setItem(
                                CLOSE_SUBSCRIPTION_BANNER_TIMESTAMP_KEY,
                                DateTime.local().toISO()
                            )
                            setHideBanner(true)
                        }}
                    >
                        <Close />
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
