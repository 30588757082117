import { PropsWithChildren } from 'react'
import { OverlayView, OverlayViewF, InfoWindow } from '@react-google-maps/api'
import { motion } from 'framer-motion'
import { colors } from '@/utilities/colors.ts'
import { Text } from '@/components/text'
import { Box } from '@mui/joy'

interface IPriceMarkerProps {
    cords: {
        lng: number
        lat: number
    }
    price: string
    onClick: () => void
    isSelected: boolean
}

export function PriceMarker(props: PropsWithChildren<IPriceMarkerProps>) {
    return (
        <OverlayViewF
            position={props.cords}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            // when users select it, move the marker to the foreground
            zIndex={props.isSelected ? 99 : 0}
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    type: 'spring',
                    stiffness: 400,
                    damping: 20,
                    delay: Math.random() * 0.3,
                }}
                whileHover={{
                    zIndex: 9999,
                }}
                style={{
                    border: `1px solid ${colors.white}`,
                }}
                onClick={props.onClick}
            >
                <Box
                    style={{
                        backgroundColor: props.isSelected
                            ? colors.brown
                            : colors.primary,
                        color: colors.white,
                        border: 0,
                        padding: 3,
                        borderRadius: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Text sx={{ fontSize: 12, color: colors.white }}>
                        {props.price}
                    </Text>
                </Box>
                {props.isSelected && (
                    <InfoWindow onCloseClick={() => {}}>
                        fill property
                    </InfoWindow>
                )}
            </motion.div>
        </OverlayViewF>
    )
}
