import { useSidebarController } from '@/services/use-sidebar'
import {
    Box,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    Tooltip,
} from '@mui/joy'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ListItemButton from '@mui/joy/ListItemButton'
import { colors } from '@/utilities/colors.ts'
import styles from '@/src/pages/secure/secure-layout/styles.module.css'
interface SidebarItemProps {
    icon: React.ReactNode
    rightIcon?: React.ReactNode
    text: string
    active: boolean
    link: string
}
export function SidebarItem({
    icon,
    text,
    active,
    link,
    rightIcon,
}: SidebarItemProps) {
    const { isExpanded, closeSidebar } = useSidebarController()
    const navigate = useNavigate()

    function onClickMenuItem() {
        closeSidebar()
        navigate(link)
    }

    return (
        <ListItem
            className={styles.transitionAll}
            sx={{
                px: 3,
                py: 1,
                mb: 1,
                mx: 1,
                borderRadius: 10,
                backgroundColor: active ? '#f1f1f1' : 'white',
                '&:hover': {
                    backgroundColor: '#f1f1f1',
                },
            }}
        >
            <Tooltip
                title={text}
                disableHoverListener={isExpanded}
                arrow
                placement="right"
            >
                <ListItemButton onClick={onClickMenuItem}>
                    {text !== 'Pricing' ? (
                        <ListItemDecorator>{icon}</ListItemDecorator>
                    ) : (
                        <Box
                            sx={{
                                bgcolor: colors.primary,
                                padding: '1px 10px',
                                borderRadius: 8,
                            }}
                        >
                            PRO
                        </Box>
                    )}
                    {isExpanded && (
                        <ListItemContent sx={{ fontSize: 17, fontWeight: 500 }}>
                            {text}
                        </ListItemContent>
                    )}
                    {rightIcon && (
                        <ListItemDecorator>{rightIcon}</ListItemDecorator>
                    )}
                </ListItemButton>
            </Tooltip>
        </ListItem>
    )
}
