import { Box, Typography } from '@mui/joy'
import CheckedInactive from '@/assets/svgs/checked-inactive.svg?react'
import CheckedActive from '@/assets/svgs/checked-active.svg?react'

type PasswordCriteriaProps = {
    text: string | React.ReactNode
    isValid: boolean
}

export function PasswordCriteria({ text, isValid }: PasswordCriteriaProps) {
    function displayText() {
        if (typeof text === 'string') {
            return <Typography level="body-sm">{text}</Typography>
        }

        return text
    }
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
            }}
        >
            {isValid ? <CheckedActive /> : <CheckedInactive />}
            {displayText()}
        </Box>
    )
}
