import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { FallbackProps } from 'react-error-boundary'

export function DeathScreen(props: PropsWithChildren<FallbackProps>) {
    return (
        <Box>
            <Container sx={{ mt: 4 }}>
                <Grid container>
                    <Grid item md={7}>
                        <Typography
                            variant={'h1'}
                            sx={{ mb: 5 }}
                            fontWeight={'bold'}
                        >
                            Oops!
                        </Typography>
                        <Typography variant={'h4'} sx={{ mb: 5 }}>
                            Its not you, its us and we now know about this
                            error. We will work on fixing this as soon as
                            possible
                        </Typography>
                        {props.resetErrorBoundary && (
                            <div>
                                <Button
                                    className={'retry-button'}
                                    onClick={props.resetErrorBoundary}
                                >
                                    🔄 Try Again!
                                </Button>
                            </div>
                        )}
                    </Grid>
                    <Grid item md={5}></Grid>
                </Grid>
            </Container>
        </Box>
    )
}
