import { Amenities } from '@/services/use-get-amenities/types.ts'

export function groupedAmenities(amenities: Amenities[]): {
    [key: string]: string[]
} {
    return (amenities || []).reduce(
        (acc: { [key: string]: string[] }, obj: Amenities) => {
            const { amenity_type, name } = obj
            acc[amenity_type] = acc[amenity_type] || []
            acc[amenity_type].push(name)
            return acc
        },
        {}
    )
}
