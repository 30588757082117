import { Box } from '@mui/joy'
import { useCreatePropertyController } from '@/src/pages/secure/properties/create-property/hooks/use-create-property'
import { Step1 } from '@/src/pages/secure/properties/create-property/components/steps/step-1'
import { Step2 } from '@/src/pages/secure/properties/create-property/components/steps/step-2'
import { Step3 } from '@/src/pages/secure/properties/create-property/components/steps/step-3'
import { SecureHeader } from '@/src/pages/secure/components/secure-header'

export function CreatePropertyForm() {
    const createPropertyController = useCreatePropertyController()
    const steps = [<Step1 />, <Step2 />, <Step3 />]

    return (
        <Box>
            <SecureHeader />
            <Box p={3}>{steps[createPropertyController.activeStep]}</Box>
        </Box>
    )
}
