import { useFormik } from 'formik'
import { TextInput } from '@/components/text-input'
import { colors } from '@/utilities/colors'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { Box, Button, Option, Select, Typography } from '@mui/joy'
import { useProfileUpdateMutation } from '@/services/use-user-profile-update'
import {
    EMPLOYMENT,
    updateProfileSchema,
} from '@/services/use-user-profile-update/types.ts'

type FormValues = {
    employment_status: EMPLOYMENT
    company_name: string
    occupation: string
}

export function Employment() {
    const profileMutation = useProfileUpdateMutation()
    const formik = useFormik<FormValues>({
        onSubmit: ({ employment_status, company_name }) => {
            profileMutation.mutate({
                employment: {
                    employment_status,
                    company_name,
                },
            })
        },
        initialValues: {
            employment_status: EMPLOYMENT.UNEMPLOYED,
            occupation: '',
            company_name: '',
        },
        validationSchema: updateProfileSchema,
    })

    return (
        <Box>
            <Box mb={2}>
                <Typography
                    sx={{
                        color: colors.brown,
                        fontWeight: 600,
                        py: 1,
                        fontSize: 16,
                    }}
                >
                    Employment status
                </Typography>
                <Select
                    indicator={<KeyboardArrowDown />}
                    value={formik.values.employment_status}
                    onChange={(_, value) => {
                        void formik.setFieldValue('employment_status', value)
                    }}
                    sx={{
                        width: { xs: '100%', sm: '25rem' },
                        [`& .MuiSelect-indicator`]: {
                            transition: '0.5s',
                            [`&.Mui-expanded`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                        '&.MuiSelect-variantOutlined > .MuiSelect-button:focus':
                            {
                                outline: 0,
                            },
                    }}
                >
                    <Option value={EMPLOYMENT.UNEMPLOYED}>Unemployed</Option>
                    <Option value={EMPLOYMENT.EMPLOYED}>Employed</Option>
                </Select>
            </Box>
            <Box mb={2}>
                <TextInput
                    size={'lg'}
                    value={formik.values.occupation}
                    name="occupation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={'Occupation'}
                    sx={{ width: { xs: '100%', sm: '25rem' } }}
                    sxLabel={{
                        color: colors.brown,
                        fontWeight: 600,
                        py: 1,
                        fontSize: 16,
                    }}
                />
            </Box>
            <Box mb={2}>
                <TextInput
                    size={'lg'}
                    label={'Company name'}
                    value={formik.values.company_name}
                    name="company_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{ width: { xs: '100%', sm: '25rem' } }}
                    sxLabel={{
                        color: colors.brown,
                        fontWeight: 600,
                        py: 1,
                        fontSize: 16,
                    }}
                />
            </Box>
            <Box sx={{ position: 'fixed', bottom: '5%', right: '5%' }}>
                <Button
                    disabled={profileMutation.isPending}
                    onClick={() => formik.handleSubmit()}
                >
                    Update
                </Button>
            </Box>
        </Box>
    )
}
