import React from 'react'
import { Context } from '@/src/pages/secure/properties/edit-property/hooks/use-edit-property/provider'

export function useEditPropertyController() {
    const context = React.useContext(Context)
    if (!context) {
        throw new Error(
            'You must wrapper with EditPropertyProvider to use this hook'
        )
    }
    return context
}
