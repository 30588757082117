import {
    PageQueryParams,
    PropertiesSchema,
    Property,
    PropertyResponse,
    PropertySchema,
    SinglePropertiesSchema,
} from '@/services/use-get-properties/types.ts'
import {
    keepPreviousData,
    useMutation,
    useQuery,
    UseQueryResult,
} from '@tanstack/react-query'
import { Axios } from '@/utilities/axios.ts'
import { AxiosResponse } from 'axios'
import { keys } from '@/services/query-keys.ts'
import { ErrorResponse } from '@/services/error.ts'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { queryClient } from '@/utilities/react-query-client.ts'

export function useGetPaginatedProperties(query?: PageQueryParams) {
    return useQuery({
        queryKey: [keys.GET_PROPERTIES.key, query],
        queryFn: async () => {
            const page = query?.page || '1'
            const params = { ...query, page }
            const res: AxiosResponse<PropertyResponse> = await Axios.get(
                `${keys.GET_PROPERTIES.url}`,
                {
                    params,
                }
            )
            return PropertySchema.parse(res)
        },
        placeholderData: keepPreviousData,
    })
}
export function useGetProperties(
    query?: string
): UseQueryResult<PropertyResponse, ErrorResponse> {
    return useQuery<PropertyResponse, ErrorResponse>({
        queryKey: [keys.GET_PROPERTIES.key, query || ''],
        queryFn: async () => {
            const res: AxiosResponse<PropertyResponse> = await Axios.get(
                `${keys.GET_PROPERTIES.url}${query ?? ''}`
            )
            return PropertySchema.parse(res)
        },
    })
}

export function useGetLatestProperties(
    query?: string
): UseQueryResult<Property[], ErrorResponse> {
    return useQuery<Property[], ErrorResponse>({
        queryKey: [keys.GET_LATEST_PROPERTIES.key, query || ''],
        queryFn: async () => {
            const res: AxiosResponse<PropertyResponse> = await Axios.get(
                `${keys.GET_LATEST_PROPERTIES.url}${query ?? ''}`
            )
            return PropertiesSchema.parse(res)
        },
    })
}

export function useGetProperty(id?: string) {
    return useQuery<Property, ErrorResponse>({
        queryKey: [keys.GET_PROPERTY.key, id || ''],
        queryFn: async () => {
            const res: AxiosResponse<Property> = await Axios.get(
                `${keys.GET_PROPERTIES.url}/${id || ''}`
            )
            return SinglePropertiesSchema.parse(res)
        },
        enabled: !!id,
    })
}

export function useGetLandlordProperties() {
    const authUser = useGetAuthenticatedUser()
    return useQuery<PropertyResponse, ErrorResponse>({
        queryKey: [keys.GET_LANDLORD_PROPERTY.key],
        queryFn: async () => {
            const res: AxiosResponse<PropertyResponse> = await Axios.get(
                keys.GET_LANDLORD_PROPERTY.url
            )
            return PropertySchema.parse(res)
        },
        enabled: authUser.isAuth,
        placeholderData: keepPreviousData,
    })
}

export function useGetUserFavouriteProperties(): UseQueryResult<
    Property[],
    ErrorResponse
> {
    const authUser = useGetAuthenticatedUser()
    return useQuery<Property[], ErrorResponse>({
        queryKey: [keys.GET_USER_PROPERTY_FAVOURITES.key],
        queryFn: async (): Promise<Property[]> => {
            const res: AxiosResponse<Property[]> = await Axios.get(
                keys.GET_USER_PROPERTY_FAVOURITES.url
            )
            return PropertiesSchema.parse(res)
        },
        enabled: authUser.isAuth,
    })
}

export function useToggleUserFavouriteProperties() {
    return useMutation({
        mutationFn: async (propertyId: string) => {
            return await Axios.post(
                `${keys.GET_USER_PROPERTY_FAVOURITES.url}/${propertyId}`
            )
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [
                    keys.GET_USER_PROPERTY_FAVOURITES.key,
                    keys.GET_LANDLORD_PROPERTY.key,
                    keys.GET_PROPERTY.key,
                    keys.GET_PROPERTIES.key,
                ],
            })
        },
    })
}

export function useRemovePropertiesMutation() {
    const properties = useGetLandlordProperties()
    return useMutation({
        mutationFn: async (propertyId: string) => {
            return await Axios.delete(
                `${keys.POST_REMOVE_PROPERTY.url}/${propertyId}`
            )
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [
                    keys.GET_USER_PROPERTY_FAVOURITES.key,
                    keys.GET_LANDLORD_PROPERTY.key,
                    keys.GET_PROPERTY.key,
                    keys.GET_PROPERTIES.key,
                ],
            })
            await properties.refetch()
        },
    })
}

export function usePostPropertyView() {
    return useMutation({
        mutationFn: async (propertyId: string) => {
            return await Axios.post(
                `${keys.POST_PROPERTY_VIEWS.url}/${propertyId}/views`
            )
        },
    })
}
