import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { useQuery } from '@tanstack/react-query'
import { ErrorResponse } from '@/services/error.ts'
import { keys } from '@/services/query-keys.ts'
import { AxiosResponse } from 'axios'
import { Axios } from '@/utilities/axios.ts'
import {
    Notification,
    NotificationsSchema,
} from '@/services/use-get-user-notifications/schema.ts'

export function useGetUserNotifications() {
    const authUser = useGetAuthenticatedUser()
    return useQuery<Notification[], ErrorResponse, Notification[], string[]>({
        queryKey: [keys.GET_USER_NOTIFICATIONS.key],
        queryFn: async (): Promise<Notification[]> => {
            const res: AxiosResponse<Notification[]> = await Axios.get(
                keys.GET_USER_NOTIFICATIONS.url
            )
            return NotificationsSchema.parse(res)
        },
        enabled: authUser.isAuth,
    })
}
