import React from 'react'
import {
    Box,
    Button,
    DialogContent,
    Modal,
    ModalDialog,
    Typography,
} from '@mui/joy'
import SvgIcons from '@/components/svg-icon'
import { DateTime } from 'luxon'

// Constants
const CLOSE_PROMPT_TIMESTAMP_KEY = 'pwaPromptClosedTimestamp'

// Helper Functions
function isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
}

function isPwaInstalled() {
    return (
        window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: fullscreen)').matches ||
        window.matchMedia('(display-mode: minimal-ui)').matches ||
        document.referrer.includes('android-app://')
    )
}

function isLastClosedLessThan48Hours() {
    const lastClosedTimestamp = localStorage.getItem(CLOSE_PROMPT_TIMESTAMP_KEY)
    if (!lastClosedTimestamp) {
        return false
    }
    const timeClosed = DateTime.fromISO(lastClosedTimestamp)
    const now = DateTime.now()
    const diff = now.diff(timeClosed, 'days').days
    return diff < 2
}

export function PwaInstallPrompt() {
    const [isPromptOpen, setIsPromptOpen] = React.useState(false)
    const [deferredPrompt, setDeferredPrompt] = React.useState<any>(null)
    const [isIos, setIsIos] = React.useState(false)
    const [isInStandaloneMode, setIsInStandaloneMode] = React.useState(false)
    const [hasClosedPrompt, setHasClosedPrompt] = React.useState(
        isLastClosedLessThan48Hours()
    )

    React.useEffect(() => {
        if (!isPromptOpen) {
            setHasClosedPrompt(isLastClosedLessThan48Hours())
        }
    }, [isPromptOpen])

    React.useEffect(() => {
        setIsInStandaloneMode(isPwaInstalled())
        const userAgent = window.navigator.userAgent.toLowerCase()
        const isIosDevice = /iphone|ipad|ipod/.test(userAgent)
        setIsIos(isIosDevice)
        if (isPwaInstalled()) {
            return
        }
        function handleBeforeInstallPrompt(e: any) {
            e.preventDefault()
            setDeferredPrompt(e)
        }
        const matchMedia = window.matchMedia('(display-mode: standalone)')
        if (!matchMedia.matches) {
            window.addEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt
            )
        }
        return () =>
            window.removeEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt
            )
    }, [])

    async function handleInstallClick() {
        deferredPrompt.prompt()
        try {
            const { outcome } = await deferredPrompt.userChoice
            if (outcome === 'accepted') {
                setDeferredPrompt(null)
                setIsPromptOpen(false)
            }
            setDeferredPrompt(null)
        } catch (e) {
            // log error
        }
    }

    function handleCloseClick() {
        setIsPromptOpen(false)
        setDeferredPrompt(null)
        setIsIos(false)
        setIsInStandaloneMode(false)
    }

    if (!isMobileDevice() || hasClosedPrompt) {
        return null
    }

    const showIosPrompt = isIos && !isInStandaloneMode

    if (deferredPrompt || showIosPrompt) {
        return (
            <>
                <AppPrompt
                    label="Add to Home Screen"
                    isPromptOpen={isPromptOpen}
                    onClose={() => {
                        handleCloseClick()
                        localStorage.setItem(
                            CLOSE_PROMPT_TIMESTAMP_KEY,
                            DateTime.now().toISO()
                        )
                        setHasClosedPrompt(true)
                    }}
                    onClick={async () => {
                        if (isIos) {
                            setIsPromptOpen(true)
                            return
                        }
                        await handleInstallClick()
                    }}
                />
                <Modal open={isPromptOpen} onClose={handleCloseClick}>
                    <ModalDialog>
                        <DialogContent>
                            <h2>Install our App</h2>
                            <ol>
                                <li>
                                    Tap the "Share" icon at the bottom of
                                    Safari.
                                </li>
                                <li>Find and tap "Add to Home Screen".</li>
                            </ol>
                            <Button color="danger" onClick={handleCloseClick}>
                                Close
                            </Button>
                        </DialogContent>
                    </ModalDialog>
                </Modal>
            </>
        )
    }

    return null
}

function AppPrompt(props: {
    label: string
    isPromptOpen: boolean
    onClick: () => void
    onClose: () => void
}) {
    return (
        <Box
            left={0}
            right={0}
            bgcolor={'white'}
            bottom={0}
            zIndex={9999}
            position={'fixed'}
            px={3}
            py={2}
            display={props.isPromptOpen ? 'none' : 'flex'}
            justifyContent={'space-between'}
            flexDirection={'row'}
        >
            <Box display={'flex'} alignItems={'center'}>
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    onClick={props.onClose}
                    mr={2}
                    sx={{ cursor: 'pointer' }}
                >
                    <SvgIcons iconName={'close_icon'} />
                </Box>
                <Box>
                    <Typography level={'h4'}>Openprop</Typography>
                    <Typography level={'body-sm'}>{props.label}</Typography>
                </Box>
            </Box>
            <Box>
                <Button onClick={props.onClick}>Install</Button>
            </Box>
        </Box>
    )
}
