import { Select, Typography, Option, FormHelperText, Box } from '@mui/joy'
import { Neighborhood } from '@/src/pages/secure/properties/create-property/components/steps/step-1'
import { colors } from '@/utilities/colors'

type INeighbourhoodSelector = {
    handleNeighbourhoodChange: (id: string) => void
    neighbourhood_id: string
    neighbourhoods: Neighborhood[]
    touched: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
    setClickedCoords: (
        value: {
            lat: number
            lng: number
        } | null
    ) => void
}

export function NeighbourhoodSelector(props: INeighbourhoodSelector) {
    return (
        <Box width={'30%'}>
            <Typography>Neighborhood</Typography>
            <Select
                placeholder="Choose a neighbourhood"
                name="neighbourhood"
                onChange={(_, value) => {
                    if (!value) {
                        return
                    }
                    props.handleNeighbourhoodChange(value)
                }}
                value={props.neighbourhood_id}
                sx={{ minWidth: '10rem' }}
            >
                {props.neighbourhoods.map((neighborhood) => (
                    <Option
                        key={neighborhood.id}
                        value={neighborhood.id}
                        onClick={() =>
                            props.setClickedCoords({
                                lat: neighborhood.coords.coordinates[0],
                                lng: neighborhood.coords.coordinates[1],
                            })
                        }
                    >
                        {neighborhood.neighbourhood_name}
                    </Option>
                ))}
            </Select>
            <FormHelperText sx={{ color: colors.red }}>
                {props.touched &&
                    props.error &&
                    'Neighbourhood must be selected'}
            </FormHelperText>
        </Box>
    )
}
