import { Box } from '@mui/joy'
import { useSidebarController } from '@/services/use-sidebar'
import MenuIcon from '@mui/icons-material/Menu'
import { NotificationDropdown } from '@/src/pages/secure/components/secure-header/components/notification-dropdown'

export function SecureHeader() {
    const { isMobileDevice, toggleSidebar } = useSidebarController()
    return (
        <Box
            sx={{
                p: 2,
            }}
        >
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box width={100}>
                    {isMobileDevice && (
                        <MenuIcon
                            onClick={toggleSidebar}
                            sx={{
                                ':hover': {
                                    cursor: 'pointer',
                                },
                            }}
                        />
                    )}
                </Box>

                <NotificationDropdown />
            </Box>
        </Box>
    )
}
