import { Text } from '@/components/text'
import { FontSize } from '@/components/text/types'
import { colors } from '@/utilities/colors'
import { Box, Chip } from '@mui/joy'

type TSelectedAmenities = {
    label: string
    amenities: { id: string; name: string }[]
}
export function SelectedAmenity(props: TSelectedAmenities) {
    return (
        <Box sx={{ mb: 3 }}>
            <Text textFontSize={FontSize.Title}>{props.label}</Text>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1.2,
                    width: '90%',
                    border: `1px solid ${colors.brown}`,
                    px: 1.25,
                    py: 1,
                    borderRadius: 8,
                }}
            >
                {props.amenities.map((am) => (
                    <Chip
                        key={am.id}
                        size="lg"
                        sx={{
                            backgroundColor: colors.brown,
                            color: colors.gray,
                        }}
                    >
                        {am.name}
                    </Chip>
                ))}
            </Box>
        </Box>
    )
}
