import { Text } from '@/components/text'
import { TextInput } from '@/components/text-input'
import { colors } from '@/utilities/colors'
import { Box, Grid, Button } from '@mui/joy'

export function NextOfKin() {
    return (
        <Box sx={{ display: 'grid', gap: '2rem' }}>
            <Box>
                <Text
                    sx={{
                        color: colors.brown,
                        fontWeight: 600,
                        py: 1,
                        fontSize: 16,
                    }}
                >
                    Name of next of kin
                </Text>
                <Grid container spacing={2}>
                    <Grid>
                        <TextInput
                            placeholder={'Last Name'}
                            sx={{ width: { xs: '100%', sm: '25rem' } }}
                        />
                    </Grid>
                    <Grid>
                        <TextInput
                            placeholder={'First Name'}
                            sx={{ width: { xs: '100%', sm: '25rem' } }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Text
                    sx={{
                        color: colors.brown,
                        fontWeight: 600,
                        py: 1,
                        fontSize: 16,
                    }}
                >
                    Relationship
                </Text>
                <Grid container spacing={2}>
                    <Grid>
                        <TextInput
                            sx={{ width: { xs: '100%', sm: '25rem' } }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid>
                        <Text
                            sx={{
                                color: colors.brown,
                                fontWeight: 600,
                                py: 1,
                                fontSize: 16,
                            }}
                        >
                            Phone Number
                        </Text>
                        <TextInput
                            sx={{ width: { xs: '100%', sm: '25rem' } }}
                        />
                    </Grid>
                    <Grid>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Text
                                sx={{
                                    color: colors.brown,
                                    fontWeight: 600,
                                    py: 1,
                                    fontSize: 16,
                                }}
                            >
                                Phone Number
                            </Text>
                            <Text> &nbsp;(alternative)</Text>
                        </Box>
                        <TextInput
                            sx={{ width: { xs: '100%', sm: '25rem' } }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Text
                    sx={{
                        color: colors.brown,
                        fontWeight: 600,
                        py: 1,
                        fontSize: 16,
                    }}
                >
                    Address
                </Text>
                <Grid container spacing={2}>
                    <Grid>
                        <TextInput
                            placeholder="region"
                            sx={{ width: { xs: '100%', sm: '25rem' } }}
                        />
                    </Grid>
                    <Grid>
                        <TextInput
                            placeholder="neigbourhood"
                            sx={{ width: { xs: '100%', sm: '25rem' } }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ position: 'fixed', bottom: '5%', right: '5%' }}>
                <Button>Save Changes</Button>
            </Box>
        </Box>
    )
}
