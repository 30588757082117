export const colors: Record<string, string> = {
    primary: '#EFA12D',
    secondary: '#55917F',
    lightGreen: '#E1F0C4',
    white: '#FFFFFF',
    brown: '#5E4C5A',
    footerTextColor: '#5E4C5A',
    buttonHover: '#754B0D',
    buttonDisabled: '#969696',
    gray: '#e1e1e1',
    gray10: '#d9d9d9',
    gray5: '#F8F8F8',
    gray15: '#F1F1F1',
    red: '#c21a1a',
    red5: '#E2412B',
    lightBlue: '#CDD7E1',
}
