import { Avatar, Stack, Typography, IconButton } from '@mui/joy'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { toggleMessagesPane } from '@/utilities/message'
import MenuIcon from '@mui/icons-material/Menu'
import { useSidebarController } from '@/services/use-sidebar'

type MessagesPaneHeaderProps = {
    sender: {
        first_name: string
        last_name: string
    }
}

export function MessagesPaneHeader(props: MessagesPaneHeaderProps) {
    const sidebarController = useSidebarController()
    const { sender } = props
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'background.body',
            }}
            py={{ xs: 2, md: 2 }}
            px={{ xs: 1, md: 2 }}
        >
            <Stack
                direction="row"
                spacing={{ xs: 1, md: 2 }}
                alignItems="center"
            >
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{
                        display: { xs: 'inline-flex', sm: 'none' },
                    }}
                    onClick={() => sidebarController.toggleSidebar()}
                >
                    <MenuIcon />
                </IconButton>
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{
                        display: { xs: 'inline-flex', sm: 'none' },
                    }}
                    onClick={() => toggleMessagesPane()}
                >
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>
                <Avatar size="lg">
                    {sender.first_name.charAt(0)}
                    {sender.last_name.charAt(0)}
                </Avatar>
                <div>
                    <Typography
                        fontWeight="lg"
                        fontSize="lg"
                        component="h2"
                        noWrap
                    >
                        {sender.first_name}
                    </Typography>
                </div>
            </Stack>
        </Stack>
    )
}
