import { Grid, Skeleton } from '@mui/joy'

interface IPropertyCardLoadingProps {
    length?: number
    md?: number
}
export function PropertyCardLoading(props: IPropertyCardLoadingProps) {
    const noOfPropertyCards = Array(props.length || 1)
        .fill(null)
        .map((_, i) => i)
    return (
        <Grid container spacing={2}>
            {(noOfPropertyCards || []).map((n) => (
                <Grid
                    key={n}
                    xs={12}
                    md={props.md || 3}
                    maxHeight={'25rem'}
                    mt={4}
                    zIndex={0}
                >
                    <Skeleton variant="rectangular" height={150} />
                    <Skeleton
                        variant="text"
                        width={'50%'}
                        sx={{ fontSize: '2rem' }}
                    />
                    <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
                    <Skeleton
                        variant="text"
                        width={'50%'}
                        sx={{ fontSize: '1.5rem' }}
                    />
                </Grid>
            ))}
        </Grid>
    )
}
