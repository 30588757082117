type TPropertyViewIconProps = {
    fill: string
    width?: number
    height?: number
}

export function PropertyViewIcon(props: TPropertyViewIconProps) {
    return (
        <svg
            width={props.width || 60}
            height={props.height || 60}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M40.08 13.6394L28.56 5.57939C25.42 3.37939 20.6 3.49939 17.58 5.83939L7.55998 13.6594C5.55998 15.2194 3.97998 18.4194 3.97998 20.9394V34.7394C3.97998 39.8394 8.11998 43.9994 13.22 43.9994H34.78C39.88 43.9994 44.02 39.8594 44.02 34.7594V21.1994C44.02 18.4994 42.28 15.1794 40.08 13.6394ZM33.76 26.7994C33.76 27.5794 33.14 28.1994 32.36 28.1994C31.58 28.1994 30.96 27.5794 30.96 26.7994V26.4394L25.52 31.8794C25.22 32.1794 24.82 32.3194 24.4 32.2794C24 32.2394 23.62 31.9994 23.4 31.6594L21.36 28.6194L16.6 33.3794C16.32 33.6594 15.98 33.7794 15.62 33.7794C15.26 33.7794 14.9 33.6394 14.64 33.3794C14.1 32.8394 14.1 31.9594 14.64 31.3994L20.6 25.4394C20.9 25.1394 21.3 24.9994 21.72 25.0394C22.14 25.0794 22.52 25.2994 22.74 25.6594L24.78 28.6994L29 24.4794H28.64C27.86 24.4794 27.24 23.8594 27.24 23.0794C27.24 22.2994 27.86 21.6794 28.64 21.6794H32.36C32.54 21.6794 32.72 21.7194 32.9 21.7794C33.24 21.9194 33.52 22.1994 33.66 22.5394C33.74 22.7194 33.76 22.8994 33.76 23.0794V26.7994Z"
                fill="#5E8874"
            />
        </svg>
    )
}
