import { TextInput } from '@/components/text-input'
import { colors } from '@/utilities/colors'
import { Box, Grid, Button, Typography } from '@mui/joy'
import { updateProfileSchema } from '@/services/use-user-profile-update/types'
import { useFormik } from 'formik'
import { useProfileUpdateMutation } from '@/services/use-user-profile-update'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'

export function Profile() {
    const profileMutation = useProfileUpdateMutation()
    const authController = useGetAuthenticatedUser()

    const formik = useFormik({
        onSubmit: (values) => {
            profileMutation.mutate(values)
        },
        initialValues: {
            first_name: '',
            last_name: '',
            phone: '',
        },
        validationSchema: updateProfileSchema,
    })
    return (
        <Box>
            <Box mb={2}>
                <Grid container spacing={3}>
                    <Grid>
                        <TextInput
                            label={'First name'}
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sxLabel={{
                                color: colors.brown,
                                fontWeight: 600,
                                py: 1,
                                fontSize: 16,
                            }}
                        />
                    </Grid>
                    <Grid>
                        <TextInput
                            label={'Last name'}
                            type="text"
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sxLabel={{
                                color: colors.brown,
                                fontWeight: 600,
                                py: 1,
                                fontSize: 16,
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mb={2}>
                <TextInput
                    label={'Mobile number'}
                    sx={{ width: { xs: '100%', md: '40%' } }}
                    type="text"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sxLabel={{
                        color: colors.brown,
                        fontWeight: 600,
                        py: 1,
                        fontSize: 16,
                    }}
                />
            </Box>
            <Box mb={2}>
                <Typography
                    level={'body-sm'}
                    sx={{
                        color: colors.brown,
                        fontWeight: 600,
                        py: 1,
                        fontSize: 16,
                    }}
                >
                    Email
                </Typography>
                {authController.user?.email}
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        marginTop: 2,
                    }}
                >
                    <Button onClick={() => formik.handleSubmit()}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
