import React, { PropsWithChildren } from 'react'
import { useTheme } from '@mui/joy'
import { useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router-dom'

function useSidebar() {
    const theme = useTheme()
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'))
    const [collapsedWidth, setCollapsedWidth] = React.useState<string>('0')
    const [isExpanded, setIsExpanded] = React.useState<boolean>(true)

    const { pathname } = useLocation()
    const [width, setWidth] = React.useState<number>(380)

    const toggleExpanded = React.useCallback(() => {
        if (isExpanded) {
            setWidth(80)
            setIsExpanded(false)
            return
        }
        setWidth(380)
        setIsExpanded(true)
    }, [isExpanded])

    React.useEffect(() => {
        if (isMobileDevice) {
            setWidth(380)
            setIsExpanded(true)
        }
    }, [isMobileDevice])

    function openSidebar() {
        if (typeof window !== 'undefined') {
            document.body.style.overflow = 'hidden'
            document.documentElement.style.setProperty(
                '--SideNavigation-slideIn',
                '1'
            )
        }
    }

    function closeSidebar() {
        if (typeof window !== 'undefined') {
            document.documentElement.style.removeProperty(
                '--SideNavigation-slideIn'
            )
            document.body.style.removeProperty('overflow')
        }
    }

    function toggleSidebar() {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            const slideIn = window
                .getComputedStyle(document.documentElement)
                .getPropertyValue('--SideNavigation-slideIn')
            if (slideIn) {
                closeSidebar()
            } else {
                openSidebar()
            }
        }
    }

    return {
        pathname,
        width,
        collapsedWidth,
        isMobileDevice,
        setCollapsedWidth,
        setWidth,
        toggleSidebar,
        isExpanded,
        closeSidebar,
        toggleExpanded,
    }
}

type SidebarContext = ReturnType<typeof useSidebar>
const Context = React.createContext<SidebarContext | undefined>(undefined)

export function useSidebarController() {
    const context = React.useContext(Context)
    if (!context) {
        throw new Error(
            'You must wrapper with SidebarProvider to use this hook'
        )
    }
    return context
}

type TSidebarProvider = PropsWithChildren<{
    value?: SidebarContext
}>

export function SidebarProvider(props: TSidebarProvider) {
    const value = useSidebar()
    return (
        <Context.Provider value={props.value || value}>
            {props.children}
        </Context.Provider>
    )
}
