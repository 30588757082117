import currency from 'currency.js'

export function formatPrice(amount: number | string): string {
    return currency(amount, { precision: 0, symbol: 'GH₵' }).format()
}

export function sumAmounts(...amounts: number[]): string {
    return amounts.reduce((total, cur) => {
        return currency(total, { precision: 0, symbol: 'GH₵' })
            .add(cur)
            .format()
    }, '0')
}
