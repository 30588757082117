import { Footer } from '@/components/footer'
import { Navbar } from '@/components/navbar'
import { colors } from '@/utilities/colors'
import {
    Box,
    Container,
    List,
    ListItem,
    ListItemContent,
    Typography,
} from '@mui/joy'
import { useLayoutEffect } from 'react'

export function TermsOfService() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <Box>
            <Navbar />
            <Container disableGutters sx={{ my: '1.5rem' }}>
                <Typography level="h4" sx={{ color: colors.brown }}>
                    Openprop Terms of Service
                </Typography>

                <List marker="decimal" sx={{ gap: 2, mt: '0.5rem' }}>
                    <ListItem nested>
                        <ListItemContent>Acceptance of Terms</ListItemContent>
                        <ListItemContent>
                            By accessing and using Open Prop - Manage your real
                            estate listings ("the Service"), you agree to comply
                            with and be bound by these Terms of Service. If you
                            do not agree to these terms, please do not use the
                            Service.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>Changes to Terms</ListItemContent>
                        <ListItemContent>
                            razorlabs reserves the right to modify or replace
                            these Terms of Service at any time. It is your
                            responsibility to check the Terms of Service
                            periodically for changes. Your continued use of the
                            Service following the posting of any changes
                            constitutes acceptance of those changes.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>User Accounts</ListItemContent>
                        <List marker="disc">
                            <ListItem>
                                You must provide accurate and complete
                                information when creating an account.
                            </ListItem>
                            <ListItem>
                                You are responsible for maintaining the
                                confidentiality of your account credentials.
                            </ListItem>
                            <ListItem>
                                You are responsible for all activities that
                                occur under your account.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>
                            Content and Use of Service
                        </ListItemContent>
                        <List marker="disc">
                            <ListItem>
                                You agree not to upload, post, or transmit any
                                harmful content or content that violates any
                                applicable laws. raazorlabs reserves the right
                                to remove any content that violates these Terms
                                of Service.
                            </ListItem>
                            <ListItem>
                                You may not use the Service for any illegal or
                                unauthorized purpose.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>Intellectual Property</ListItemContent>
                        <ListItemContent>
                            The Service and its original content, features, and
                            functionality are owned by razorlabs and are
                            protected by international copyright, trademark,
                            patent, trade secret, and other intellectual
                            property or proprietary rights laws.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>
                            Limitation of Liability
                        </ListItemContent>
                        <ListItemContent>
                            Razorlabs and its directors, employees, partners,
                            agents, suppliers, or affiliates shall not be liable
                            for any indirect, incidental, special,
                            consequential, or punitive damages, including
                            without limitation, loss of profits, data, use,
                            goodwill, or other intangible losses.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>Governing Law</ListItemContent>
                        <ListItemContent>
                            These Terms of Service shall be governed by and
                            construed in accordance with the laws of Ghana,
                            without regard to its conflict of law provisions.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>Contact Information</ListItemContent>
                        <ListItemContent>
                            For any questions about these Terms of Service,
                            please contact us at{' '}
                            <a href="mailto:contact@razorlabs.tech">
                                contact@razorlabs.tech
                            </a>
                            .
                        </ListItemContent>
                    </ListItem>
                </List>
            </Container>
            <Footer />
        </Box>
    )
}
