import NoPropertyFound from '@/assets/svgs/no-connection.svg'
import { Box, Typography } from '@mui/joy'

export function Property404() {
    return (
        <Box
            sx={{
                mt: 15,
                mb: 5,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
            }}
        >
            <img src={NoPropertyFound} alt="no property found" />
            <Typography sx={{ fontSize: '28px' }} fontWeight={'bold'}>
                Property not found
            </Typography>
            <Typography sx={{ color: '#5E4C5A' }}>
                Select other properties you will be interested in.
            </Typography>
        </Box>
    )
}
