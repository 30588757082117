import { useMediaQuery } from '@mui/material'
import { Box, Typography, useTheme } from '@mui/joy'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { PropertyCard } from '@/components/property-card'
import { useGetProperties } from '@/services/use-get-properties'

import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css'
import { colors } from '@/utilities/colors'

export function MorePropertySlider() {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
    const isMedium = useMediaQuery(theme.breakpoints.down('md'))
    const isSmall = useMediaQuery(theme.breakpoints.down(500))
    const properties = useGetProperties()

    return (
        <Box sx={{ mb: 4 }}>
            <Typography
                level={'body-md'}
                fontWeight={'600'}
                sx={{ color: colors.footerTextColor, mt: 4 }}
            >
                View other properties for rent
            </Typography>
            <Box>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={
                        isSmall ? 0 : isTablet ? 20 : isMedium ? 30 : 40
                    }
                    slidesPerView={
                        isSmall ? 1 : isTablet ? 2 : isMedium ? 3 : 4
                    }
                    navigation
                    style={{ padding: '1rem 0' }}
                >
                    {(properties.data?.properties || []).map((property) => (
                        <SwiperSlide key={property.id}>
                            <PropertyCard property={property} noShadow />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </Box>
    )
}
