import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/utilities/react-query-client.ts'
import { keys } from '@/services/query-keys.ts'
import { Axios } from '@/utilities/axios.ts'
import {
    UserProfile,
    USERTYPE,
} from '@/services/use-user-profile-update/types.ts'
import { useToastController } from '@/services/use-toast'

export function useProfileUpdateMutation() {
    const toast = useToastController()

    return useMutation({
        mutationKey: [keys.UPDATE_USER_PROFILE.key],
        mutationFn: async (data: UserProfile) =>
            Axios.post(keys.UPDATE_USER_PROFILE.url, data),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: [keys.GET_USER.key],
            })
            toast.onOpen('Updated user profile successfully', 'success')
        },
        onError: () => {
            toast.onOpen(
                'An error occurred, while trying to update. Please try again',
                'error'
            )
        },
    })
}

export function useUserVerificationMutation() {
    const toast = useToastController()

    return useMutation({
        mutationKey: [keys.UPDATE_USER_VERIFICATION.key],
        mutationFn: async (data: {
            ghana_card_number: string
            front: string
            back: string
        }) => Axios.post(keys.UPDATE_USER_VERIFICATION.url, data),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: [keys.GET_USER.key],
            })
            toast.onOpen('Updated user profile successfully', 'success')
        },
        onError: () => {
            toast.onOpen(
                'An error occurred, while trying to update. Please try again',
                'error'
            )
        },
    })
}

export function useUserTypeUpdateMutation() {
    const toast = useToastController()

    return useMutation({
        mutationKey: [keys.UPDATE_USER_TYPE.key],
        mutationFn: async (data: { user_type: USERTYPE }) =>
            Axios.post(keys.UPDATE_USER_TYPE.url, data),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: [keys.GET_USER.key],
            })
            toast.onOpen('Welcome to openprop', 'success')
        },
        onError: () => {
            toast.onOpen(
                'An error occurred, while trying to update. Please try again',
                'error'
            )
        },
    })
}
