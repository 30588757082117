import { ErrorBoundary } from 'react-error-boundary'
import { Routes, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { ErrorPage } from '@/src/components/error-page/index.tsx'
import { Home } from '@/src/pages/home/index.tsx'
import { PropertiesScreen } from '@/src/pages/properties/index.tsx'

import './App.css'
import { PropertyScreen } from '@/src/pages/properties/[id]/index.tsx'
import { DeathScreen } from '@/src/pages/death-screen/screen.tsx'
import { SecureLayout } from '@/src/pages/secure/secure-layout'
import { CreateProperty } from '@/src/pages/secure/properties/create-property'
import { ViewProperty } from '@/src/pages/secure/properties/view-property'
import { Dashboard } from '@/src/pages/secure/dashboard'
import { ToastProvider } from '@/services/use-toast'
import { AccountSettings } from '@/src/pages/secure/account-settings'
import { Login } from '@/src/pages/auth/login'
import { SignUp } from '@/src/pages/auth/sign-up'
import { UpdatePassword } from '@/src/pages/auth/update-password'
import { ResetPassword } from '@/src/pages/auth/reset-password'
import { AuthLayout } from '@/src/pages/auth/auth-layout'
import { VerifyIdentity } from '@/src/pages/secure/identity'
import { Favourites } from '@/src/pages/secure/favourites'
import { TermsOfService } from '@/src/pages/terms-of-service'
import { PrivacyPolicy } from '@/src/pages/privacy-policy'
import { ChatMessaging } from '@/src/pages/secure/messaging'
import { UserOnboardingProvider } from '@/services/use-user-onboarding/provider'
import { Notifications } from '@/src/pages/secure/notifications'
import { OauthVerification, Verification } from '@/src/pages/auth/verification'
import { ChatProvider } from '@/services/use-user-messaging/provider'
import { SinglePropertyPictures } from '@/src/pages/properties/[id]/components/property-pictures'
import { EditProperty } from '@/src/pages/secure/properties/edit-property'
import { GettingStarted } from '@/src/pages/auth/getting-started'
import { VirtualTourPage } from '@/src/pages/virtual-tour'
import { MetaTags } from '@/components/meta-tags'
import { SubscriptionPage } from '@/src/pages/subscription'
import { PwaInstallPrompt } from '@/components/pwa-install-prompt'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function App() {
    return (
        <ErrorBoundary
            FallbackComponent={DeathScreen}
            onError={(error, errorInfo) => {
                console.log('Error caught!', error)
                console.error(error)
                console.error(errorInfo)
            }}
            onReset={() => {
                console.log('reloading the page...')
                window.location.reload()
            }}
        >
            <MetaTags
                title={'Openprop Africa'}
                description={'Africas gateway to property search.'}
                image={'https://www.openprop.africa/assets/vector-Dwc50PjY.svg'}
            />
            <ToastProvider>
                <UserOnboardingProvider>
                    <ChatProvider>
                        <PwaInstallPrompt />
                        <SentryRoutes>
                            <Route path={'properties'}>
                                <Route
                                    path={':propertyId'}
                                    element={<PropertyScreen />}
                                />
                                <Route
                                    path={':propertyId/propertyPictures'}
                                    element={<SinglePropertyPictures />}
                                />
                                <Route index element={<PropertiesScreen />} />
                            </Route>
                            <Route path={'secure'} element={<SecureLayout />}>
                                <Route
                                    path={'properties/create'}
                                    element={<CreateProperty />}
                                />
                                <Route
                                    path={'properties/edit/:propertyId'}
                                    element={<EditProperty />}
                                />
                                <Route
                                    path={'properties'}
                                    element={<ViewProperty />}
                                />
                                <Route
                                    path={'verify-identity'}
                                    element={<VerifyIdentity />}
                                />
                                <Route
                                    path={'messages'}
                                    element={<ChatMessaging />}
                                />
                                <Route
                                    path={'favourites'}
                                    element={<Favourites />}
                                />
                                <Route
                                    path={'settings'}
                                    element={<AccountSettings />}
                                />
                                <Route
                                    path={'notifications'}
                                    element={<Notifications />}
                                />
                                <Route
                                    path={'notifications'}
                                    element={<AccountSettings />}
                                />
                                <Route index element={<Dashboard />} />
                            </Route>
                            <Route path={'auth'} element={<AuthLayout />}>
                                <Route path={'sign-up'} element={<SignUp />} />
                                <Route
                                    path={'getting-started'}
                                    element={<GettingStarted />}
                                />
                                <Route path={'login'} element={<Login />} />
                                <Route
                                    path={'oauth-verification'}
                                    element={<OauthVerification />}
                                />
                                <Route
                                    path={'verification'}
                                    element={<Verification />}
                                />
                                <Route
                                    path={'reset-password'}
                                    element={<ResetPassword />}
                                />
                                <Route
                                    path={'update-password'}
                                    element={<UpdatePassword />}
                                />
                                <Route index element={<ErrorPage />} />
                            </Route>
                            <Route
                                path={'/terms-of-service'}
                                element={<TermsOfService />}
                            />
                            <Route
                                path={'/privacy-policy'}
                                element={<PrivacyPolicy />}
                            />
                            <Route
                                path={'/pricing'}
                                element={<SubscriptionPage />}
                            />
                            <Route
                                path="/virtual-tour"
                                element={<VirtualTourPage />}
                            />
                            <Route path={'/'} element={<Home />} />
                            <Route path={'*'} element={<ErrorPage />} />
                        </SentryRoutes>
                    </ChatProvider>
                </UserOnboardingProvider>
            </ToastProvider>
        </ErrorBoundary>
    )
}

export default App
