import KitchenIcon from '@/assets/svgs/kettle.svg?react'
import LivingroomIcon from '@/assets/svgs/livingroom.svg?react'
import BedRoomIcon from '@/assets/svgs/bedroom.svg?react'
import BulbIcon from '@/assets/svgs/bulb.svg?react'
import OutdoorIcon from '@/assets/svgs/outdoor.svg?react'
import FacilityIcon from '@/assets/svgs/facility.svg?react'

export function GetAmenityTypeIcon(name: string) {
    if (name === 'kitchen') {
        return <KitchenIcon style={{ height: 25, width: 25 }} />
    }

    if (name === 'livingroom') {
        return <LivingroomIcon style={{ height: 25, width: 25 }} />
    }

    if (name === 'bedroom') {
        return <BedRoomIcon style={{ height: 25, width: 25 }} />
    }

    if (name === 'general amenities') {
        return <BulbIcon style={{ height: 25, width: 25 }} />
    }

    if (name === 'outdoor') {
        return <OutdoorIcon style={{ height: 25, width: 25 }} />
    }

    if (name === 'facility') {
        return <FacilityIcon style={{ height: 25, width: 25 }} />
    }
}
