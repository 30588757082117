import Grid from '@mui/joy/Grid'
import { TextInput } from '@/components/text-input'
import { replaceAlphabetsWithEmptyString } from '@/utilities/property.tsx'

interface IPropertyFilterRangeProps {
    leftInputTitle: string
    rightInputTitle: string
    onChangeLeftValue: (value: string) => void
    leftInputValue: string
    onChangeRightValue: (value: string) => void
    rightInputValue: string
}

export function PropertyFilterRange(props: IPropertyFilterRangeProps) {
    return (
        <Grid
            container
            spacing={1}
            sx={{
                padding: 1,
                margin: 'auto',
                backgroundColor: 'white',
                alignItems: 'center',
            }}
        >
            <Grid xs={6}>
                <TextInput
                    showCurrency
                    placeholder={'Min Price'}
                    value={props.leftInputValue}
                    onChange={(el) => {
                        const word = replaceAlphabetsWithEmptyString(
                            `${el.target.value}`
                        )
                        props.onChangeLeftValue(word)
                    }}
                />
            </Grid>
            <Grid xs={6}>
                <TextInput
                    showCurrency
                    placeholder={'Max Price'}
                    value={props.rightInputValue}
                    onChange={(el) => {
                        const word = replaceAlphabetsWithEmptyString(
                            `${el.target.value}`
                        )
                        props.onChangeRightValue(word)
                    }}
                />
            </Grid>
        </Grid>
    )
}
