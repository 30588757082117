import { z } from 'zod'

export const AmenitySchema = z.object({
    id: z.string(),
    name: z.string(),
    amenity_type: z.string(),
})

export const AmenitiesSchema = AmenitySchema.array()
export type Amenities = z.infer<typeof AmenitySchema>
