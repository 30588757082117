import { useMediaQuery, useTheme } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Scrollbar, A11y } from 'swiper/modules'
import { useGetProperties } from '@/services/use-get-properties'
import { PropertyCardLoading } from '@/components/property-card/components/property-card-loading'
import React from 'react'
import { PropertyCard } from '@/components/property-card'
import { Typography } from '@mui/joy'
import { colors } from '@/utilities/colors'

export function OtherProperties() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))
    const isSmall = useMediaQuery(theme.breakpoints.down('xs'))

    const properties = useGetProperties()
    const propertyList = (properties.data?.properties || []).slice(0, 6)

    return (
        <React.Fragment>
            <Typography level={'h3'} sx={{ color: colors.brown }}>
                View other properties for rent
            </Typography>
            {properties.isLoading ? (
                <PropertyCardLoading length={isMobile ? 1 : isTablet ? 2 : 4} />
            ) : properties.isError ? (
                <>
                    <Typography>an error occurred</Typography>
                </>
            ) : (
                <Swiper
                    modules={[Navigation, Scrollbar, A11y]}
                    navigation
                    scrollbar={{ draggable: true }}
                    style={{
                        marginTop: 5,
                        marginBottom: 5,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 2,
                        paddingRight: 2,
                    }}
                    spaceBetween={
                        isSmall ? 6 : isTablet ? 20 : isMobile ? 30 : 40
                    }
                    slidesPerView={
                        isSmall ? 1 : isTablet ? 1 : isMobile ? 1 : 4
                    }
                    autoplay={true}
                >
                    {propertyList.map((property) => (
                        <SwiperSlide key={property.id}>
                            <PropertyCard property={property} noShadow />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </React.Fragment>
    )
}
