import { Property } from '@/services/use-get-properties/types.ts'
import { PropertyType } from '@/src/pages/home/components/search-filter/types.ts'

export type TPropertyLocationAutoCompleteOption = {
    name: string
    isRegion: boolean
    id: string
}

export function isCommercial(property?: Property) {
    return property?.property_types === PropertyType.COMMERCIAL
}

export function isResidential(property?: Property) {
    return property?.property_types === PropertyType.RESIDENTIAL
}

export function makePrural(word: string, value: number, pruralSuffix: string) {
    if (value > 1) {
        return word + pruralSuffix
    }

    return word
}

export function replaceAlphabetsWithEmptyString(word: string) {
    return word.replace(/\D/g, '')
}
