import { Box, Button } from '@mui/joy'
import { colors } from '@/utilities/colors'

type TFilterModalFooterProps = {
    onReset: () => void
    onFilter: () => void
}

export function FilterModalFooter(props: TFilterModalFooterProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: '0.45rem',
                px: '0.45rem',
                mt: '0.25rem',
            }}
        >
            <Button
                variant="soft"
                sx={{
                    color: colors.primary,
                    backgroundColor: colors.white,
                    border: `1px solid ${colors.primary}`,
                }}
                onClick={props.onReset}
            >
                Reset All
            </Button>
            <Button
                sx={{
                    backgroundColor: colors.primary,
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#0039ffb8',
                    },
                }}
                onClick={props.onFilter}
            >
                Show Results
            </Button>
        </Box>
    )
}
