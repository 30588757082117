import { Box, Button } from '@mui/joy'
import { colors } from '@/utilities/colors.ts'

type PaginationProps = {
    currentPage: number
    totalPages: number
    hasPreviousPage?: boolean
    fetchPreviousPage: (page?: number) => void
    hasNextPage?: boolean
    fetchNextPage: (page?: number) => void
}

export function Pagination(props: PaginationProps) {
    function onFetchDataByPage(pageNumber: number) {
        if (props.currentPage > pageNumber) {
            props.fetchPreviousPage(pageNumber)
            return
        }

        props.fetchNextPage(pageNumber)
    }

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={'10px'}
            mb={4}
        >
            <Button
                disabled={!props.hasPreviousPage}
                onClick={() => props.fetchPreviousPage()}
            >
                Previous
            </Button>
            {Array.from({ length: props.totalPages || 1 }, (_, index) => {
                const pageNum = index + 1
                return (
                    <Button
                        key={index}
                        onClick={() => onFetchDataByPage(pageNum)}
                        sx={{
                            background: `${props.currentPage !== pageNum ? colors.white : ''}`,
                            color: `${props.currentPage !== pageNum ? colors.primary : ''}`,
                            border: '1px solid rgba(0,0,0,0.2)',
                            borderRadius: '0.45rem',
                            '&:hover': {
                                backgroundColor: '#EFA12D',
                                color: 'white',
                                border: '1px solid rgba(0,0,0,0.2)',
                            },
                            '&:focus': {
                                outline: 0,
                            },
                        }}
                    >
                        {pageNum}
                    </Button>
                )
            })}
            <Button
                disabled={!props.hasNextPage}
                onClick={() => props.fetchNextPage()}
            >
                Next
            </Button>
        </Box>
    )
}
