import React from 'react'
import { Context } from '@/src/pages/secure/properties/create-property/hooks/use-create-property/provider'

export function useCreatePropertyController() {
    const context = React.useContext(Context)
    if (!context) {
        throw new Error(
            'You must wrapper with CreatePropertyProvider to use this hook'
        )
    }
    return context
}
