import { Dropdown } from '@/components/dropdown'
import { Text } from '@/components/text'
import { NumberOfBedroomsOrBathroomSelector } from '@/components/number-of-bedrooms-bathrooms-selector'

type TSelectBathsBedsDropdownProps = {
    noOfBaths: string
    noOfBeds: string
    isOpen: boolean
    onToggle: (value: boolean) => void
    setNoOfBaths: (value: string) => void
    setNoOfBeds: (value: string) => void
    isSmall?: boolean
}

export function SelectBathsBedsDropdown(props: TSelectBathsBedsDropdownProps) {
    return (
        <Dropdown
            title={'Beds/ Baths'}
            isSelected={
                Number(props.noOfBaths) !== 0 || Number(props.noOfBeds) !== 0
            }
            onToggle={props.onToggle}
            isOpen={props.isOpen}
        >
            <Text sx={{ mb: 1 }}>Bedrooms</Text>
            <NumberOfBedroomsOrBathroomSelector
                value={props.noOfBeds}
                onSelect={(value) => props.setNoOfBeds(`${value}`)}
                max={5}
            />
            <Text sx={{ mb: 1 }}>Bathrooms</Text>
            <NumberOfBedroomsOrBathroomSelector
                value={props.noOfBaths}
                onSelect={(value) => props.setNoOfBaths(`${value}`)}
                max={5}
            />
        </Dropdown>
    )
}
