import { Box } from '@mui/material'
import { Tab, TabList, TabPanel, Tabs, tabClasses } from '@mui/joy'
import { Profile } from '@/src/pages/secure/account-settings/profile'
import { Employment } from '@/src/pages/secure/account-settings/employment'
import { NextOfKin } from '@/src/pages/secure/account-settings/next-of-kin'
// import { colors } from '@/utilities/colors'
import { SecureHeader } from '@/src/pages/secure/components/secure-header'

const tabMenu = ['Profile', 'Employment', 'Next of kin']

export function AccountSettings() {
    return (
        <>
            <SecureHeader />
            <Box p={3}>
                <Tabs
                    aria-label="Scrollable tabs"
                    defaultValue={0}
                    sx={{ '--Tab-indicatorThickness': '7px' }}
                >
                    <TabList
                        disableUnderline
                        sx={{
                            p: 0.5,
                            gap: 0.5,
                            borderRadius: 'xl',
                            bgcolor: 'background.level1',
                            [`& .${tabClasses.root}[aria-selected="true"]`]: {
                                boxShadow: 'sm',
                                bgcolor: 'background.surface',
                            },
                        }}
                    >
                        {tabMenu.map((title) => (
                            <Tab
                                disableIndicator
                                key={title}
                                sx={{
                                    flex: 'none',
                                    scrollSnapAlign: 'start',
                                    // '&.Mui-selected::after': {
                                    //     background: colors.secondary,
                                    // },
                                    // ['&.Mui-selected:focus']: {
                                    //     outline: 0,
                                    // },
                                }}
                            >
                                {title}
                            </Tab>
                        ))}
                    </TabList>
                    <Box>
                        <TabPanel value={0}>
                            <Profile />
                        </TabPanel>
                        <TabPanel value={1}>
                            <Employment />
                        </TabPanel>
                        <TabPanel value={2}>
                            <NextOfKin />
                        </TabPanel>
                    </Box>
                </Tabs>
            </Box>
        </>
    )
}
