import React from 'react'
import {
    Box,
    ListItemDecorator,
    Sheet,
    List,
    ListItem,
    ListItemContent,
    ListItemButton,
    Badge,
} from '@mui/joy'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { colors } from '@/utilities/colors.ts'

import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import MessageIcon from '@mui/icons-material/Message'
import PersonIcon from '@mui/icons-material/Person'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ApartmentIcon from '@mui/icons-material/Apartment'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LogoutIcon from '@mui/icons-material/Logout'
import LogoIcon from '@/assets/logo.svg?react'

import styles from '@/src/pages/secure/secure-layout/styles.module.css'
import { Loading } from '@/src/pages/secure/secure-layout/components/loading'
import { SidebarProvider, useSidebarController } from '@/services/use-sidebar'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
// import MenuIcon from '@mui/icons-material/Menu'
// import ClearIcon from '@mui/icons-material/Clear'
import { SidebarItem } from '@/src/pages/secure/secure-layout/components/sidebar-item'
import { useUserChatController } from '@/services/use-user-messaging'
import { UserChatList } from '@/services/use-user-messaging/provider/types'
import { useUserOnboardingController } from '@/services/use-user-onboarding'

export function SecureLayout() {
    return (
        <SidebarProvider>
            <SecureBase />
        </SidebarProvider>
    )
}

function SecureBase() {
    const navigation = useNavigate()
    const authUserController = useGetAuthenticatedUser()
    const onboardingController = useUserOnboardingController()
    const { getUserList } = useUserChatController()
    const [counter, setCounter] = React.useState<number>(0)
    const {
        isExpanded,
        pathname,
        toggleExpanded,
        closeSidebar,
        isMobileDevice,
    } = useSidebarController()

    React.useEffect(() => {
        if (authUserController.user?.id) {
            const unsubscribe = getUserList((userList: UserChatList[]) => {
                setCounter(
                    userList.filter(
                        (user) =>
                            user.count_unread_messages > 0 &&
                            user.last_message_sender_id !==
                                authUserController.user?.id
                    ).length
                )
            })

            return () => {
                unsubscribe()
            }
        }
    }, [authUserController.user?.id])

    function showSidebarIcon() {
        if (isMobileDevice) {
            return
        }

        if (isExpanded) {
            return <ChevronLeftIcon />
        }
        return <ChevronRightIcon />
    }

    if (authUserController.isPending) {
        return <Loading />
    }

    if (!authUserController.isAuth) {
        return <Navigate to={'/auth/login'} replace />
    }

    const menuItems = [
        {
            text: 'Dashboard',
            icon: <GridViewRoundedIcon />,
            link: '/secure',
        },
        {
            text: 'Properties',
            icon: <ApartmentIcon />,
            link: '/secure/properties',
        },
        {
            text: 'Messages',
            icon: <MessageIcon />,
            link: '/secure/messages',
            rightIcon: <Badge color="primary" badgeContent={counter} />,
        },
        {
            text: 'Verify your identity',
            icon: <PersonIcon />,
            link: '/secure/verify-identity',
        },
        {
            text: 'Favourites',
            icon: <FavoriteIcon />,
            link: '/secure/favourites',
        },
        {
            text: 'Setup profile',
            icon: <ManageAccountsIcon />,
            link: '/secure/settings',
        },
    ]

    return (
        <Box display={'flex'} height={'100vh'} bgcolor={'white'}>
            <Sheet
                sx={{
                    position: { xs: 'fixed', md: 'sticky' },
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                        md: 'none',
                    },
                    transition: 'transform 0.4s, width 0.4s',
                    zIndex: 10000,
                    height: '100dvh',
                    backgroundColor: 'white',
                    width: isMobileDevice ? '100%' : 'var(--Sidebar-width)',
                    top: 0,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRight: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Box
                    component={'nav'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <Box
                        justifyContent={'space-between'}
                        display={'flex'}
                        p={4}
                        pb={2}
                        alignItems={'center'}
                    >
                        {isExpanded ? (
                            <LogoIcon
                                onClick={() => {
                                    closeSidebar()
                                    navigation('/')
                                }}
                            />
                        ) : null}
                        <button
                            className={styles.btn}
                            onClick={() => toggleExpanded()}
                        >
                            {showSidebarIcon()}
                        </button>
                    </Box>
                    <List
                        size={'lg'}
                        sx={{
                            '& .MuiListItemButton-root:hover': {
                                backgroundColor: 'transparent !important',
                            },
                        }}
                    >
                        {menuItems.map((item, idx) => (
                            <SidebarItem
                                key={idx}
                                icon={item.icon}
                                rightIcon={item.rightIcon}
                                active={pathname === item.link}
                                link={item.link}
                                text={item.text}
                            />
                        ))}
                    </List>
                    <List
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            px: 3,
                            '& .MuiListItemButton-root:hover': {
                                backgroundColor: 'transparent !important',
                            },
                        }}
                    >
                        <ListItem>
                            <ListItemButton
                                onClick={onboardingController.onLogout}
                            >
                                <ListItemDecorator>
                                    <LogoutIcon />
                                </ListItemDecorator>
                                {isExpanded && (
                                    <ListItemContent
                                        sx={{ fontSize: 17, fontWeight: 500 }}
                                    >
                                        Logout
                                    </ListItemContent>
                                )}
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Sheet>
            <Box
                width={'100%'}
                maxHeight={'100vh'}
                sx={{
                    backgroundColor: colors.gray5,
                    overflowY: 'scroll',
                    zIndex: 999,
                    position: 'relative',
                }}
            >
                <Outlet />
            </Box>
        </Box>
    )
}
