import React from 'react'
import Apartment from '@/assets/svg-icons/apartment.svg?react'
import Bathroom from '@/assets/svg-icons/bathroom.svg?react'
import Bedroom from '@/assets/svg-icons/bedroom.svg?react'
import Bulb from '@/assets/svg-icons/bulb.svg?react'
import Heart from '@/assets/svg-icons/heart.svg?react'
import Cube from '@/assets/svg-icons/cube.svg?react'
import Cancel from '@/assets/svg-icons/cancel.svg?react'
import CloseCircle from '@/assets/svg-icons/close-circle.svg?react'
import CloseIcon from '@/assets/svg-icons/close-icon.svg?react'
import CommercialIcon from '@/assets/svg-icons/commercial-icon.svg?react'
import DownArrowIcon from '@/assets/svg-icons/down-arrow-icon.svg?react'
import LeftArrowCircle from '@/assets/svg-icons/left-arrow-circle.svg?react'
import RightArrow from '@/assets/svg-icons/right-arrow.svg?react'
import Facility from '@/assets/svg-icons/facility.svg?react'
import GhanaCardBack from '@/assets/svg-icons/ghana-card-back.svg?react'
import GhanaCardFront from '@/assets/svg-icons/ghana-card-front.svg?react'
import HomeFullColorIcon from '@/assets/svg-icons/home-full-color-icon.svg?react'
import Home from '@/assets/svg-icons/home.svg?react'
import House from '@/assets/svg-icons/house.svg?react'
import Instagram from '@/assets/svg-icons/instagram.svg?react'
import XIcon from '@/assets/svg-icons/x.svg?react'
import Kettle from '@/assets/svg-icons/kettle.svg?react'
import Landlord from '@/assets/svg-icons/landlord.svg?react'
import LinkedIn from '@/assets/svg-icons/linkedin.svg?react'
import LivingRoom from '@/assets/svg-icons/livingroom.svg?react'
import Location from '@/assets/svg-icons/location.svg?react'
import Logo from '@/assets/svg-icons/logo.svg?react'
import Logo2 from '@/assets/svg-icons/logo2.svg?react'
import Messages from '@/assets/svg-icons/messages.svg?react'
import NoConnection from '@/assets/svg-icons/no-connection.svg?react'
import OpenPropLogo from '@/assets/svg-icons/openproplogo.svg?react'
import Outdoor from '@/assets/svg-icons/outdoor.svg?react'
import Properties from '@/assets/svg-icons/properties.svg?react'
import Rented from '@/assets/svg-icons/rented.svg?react'
import Renter from '@/assets/svg-icons/renter.svg?react'
import SearchFilter from '@/assets/svg-icons/search-filter.svg?react'
import Settings from '@/assets/svg-icons/settings.svg?react'
import TickCircle from '@/assets/svg-icons/tick-circle.svg?react'
import Townhouse from '@/assets/svg-icons/townhouse.svg?react'
import UserSetup from '@/assets/svg-icons/user-setup.svg?react'
import User from '@/assets/svg-icons/user.svg?react'
import ViewProperties from '@/assets/svg-icons/view-properties.svg?react'
import Warning from '@/assets/svg-icons/warning.svg?react'
import GoogleIcon from '@/assets/svg-components/google-icon.tsx'

const defaultHeight = 20
const defaultWidth = 20

const svgIconsList = {
    apartment: Apartment,
    bathroom: Bathroom,
    bedroom: Bedroom,
    bulb: Bulb,
    heart: Heart,
    cube: Cube,
    cancel: Cancel,
    close_circle: CloseCircle,
    close_icon: CloseIcon,
    commercial_icon: CommercialIcon,
    down_arrow_icon: DownArrowIcon,
    left_arrow_circle: LeftArrowCircle,
    right_arrow: RightArrow,
    facility: Facility,
    google_icon: GoogleIcon,
    ghana_card_back: GhanaCardBack,
    ghana_card_front: GhanaCardFront,
    home_full_color_icon: HomeFullColorIcon,
    home: Home,
    house: House,
    instagram: Instagram,
    x_icon: XIcon,
    kettle: Kettle,
    landlord: Landlord,
    linkedin: LinkedIn,
    livingroom: LivingRoom,
    location: Location,
    logo: Logo,
    logo2: Logo2,
    messages: Messages,
    no_connection: NoConnection,
    openprop_logo: OpenPropLogo,
    outdoor: Outdoor,
    properties: Properties,
    rented: Rented,
    renter: Renter,
    search_filter: SearchFilter,
    settings: Settings,
    tick_circle: TickCircle,
    townhouse: Townhouse,
    user_setup: UserSetup,
    user: User,
    view_properties: ViewProperties,
    warning: Warning,
}

type TIconName = keyof typeof svgIconsList

type SvgIconsProps = {
    iconName: TIconName
    width?: number
    height?: number
    style?: React.CSSProperties
}

function SvgIcons(props: SvgIconsProps) {
    const selectedIcon = svgIconsList[props.iconName]

    if (!selectedIcon) {
        console.warn(`Icon with name "${props.iconName}" not found.`)
        return null
    }

    const IconComponent = selectedIcon
    const svgHeight = props.height || defaultHeight
    const svgWidth = props.width || defaultWidth

    return (
        <IconComponent
            width={svgWidth}
            height={svgHeight}
            style={props.style}
        />
    )
}

export default SvgIcons
