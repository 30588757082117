import React from 'react'
import isNil from 'lodash/isNil'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, Button, Grid, styled, Typography } from '@mui/joy'
import { Text } from '@/components/text'
import { TextInput } from '@/components/text-input'
import { colors } from '@/utilities/colors'

import { useUserVerificationMutation } from '@/services/use-user-profile-update'
import { fileUpload } from '@/utilities/azure-file-upload.ts'
import { useToastController } from '@/services/use-toast'
import GhanaCardFrontIcon from '@/assets/svgs/ghana-card-front.svg?react'
import GhanaCardBackIcon from '@/assets/svgs/ghana-card-back-icon.svg?react'
import { IMaskInput } from 'react-imask'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { VerifyIdentityModal } from '@/src/pages/secure/properties/view-property/components/verify-identity-modal'
import { useNavigate } from 'react-router-dom'

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

const TextMaskAdapter = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskAdapter(props, ref) {
        const { onChange, ...other } = props
        return (
            <IMaskInput
                {...other}
                mask="GHA-000000000-0"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                inputRef={ref}
                onAccept={(value) =>
                    onChange({ target: { name: props.name, value } })
                }
                overwrite
            />
        )
    }
)

const validationSchema = Yup.object().shape({
    ghanaCardNumber: Yup.string()
        .matches(/^GHA-\d{9}-\d$/, 'Not a valid Ghana card number')
        .required('Ghana Card Number is required'),
})

export function Identification() {
    const navigate = useNavigate()
    const authUserController = useGetAuthenticatedUser()
    const updateProfileMutation = useUserVerificationMutation()
    const toast = useToastController()
    const formik = useFormik({
        initialValues: {
            ghanaCardNumber: '',
        },
        validationSchema: validationSchema,
        onSubmit: async () => {
            await onSubmit()
        },
    })

    const [ghanaCardImageFront, setGhanaCardImageFront] = React.useState<
        | {
              src: string
              file: File
          }
        | undefined
    >(undefined)
    const [ghanaCardImageBack, setGhanaCardImageBack] = React.useState<
        | {
              src: string
              file: File
          }
        | undefined
    >(undefined)

    async function onSubmit() {
        if (authUserController.user?.is_identity_card_valid) {
            toast.onOpen('Identity already verified successfully', 'success')
            return
        }
        if (
            isNil(ghanaCardImageFront) ||
            isNil(ghanaCardImageBack) ||
            isNil(formik.values.ghanaCardNumber)
        ) {
            return
        }

        try {
            const fileUploadPromises = ['front', 'back'].map((type, idx) => {
                const file =
                    idx === 0
                        ? ghanaCardImageFront.file
                        : ghanaCardImageBack.file
                return fileUpload(file, 'imagess').then((fileUrl) => ({
                    type,
                    fileUrl,
                }))
            })

            const results = await Promise.all(fileUploadPromises)
            const variables = results.reduce(
                (t, c) => {
                    if (c.type === 'front') {
                        t.front = c.fileUrl || ''
                    } else {
                        t.back = c.fileUrl || ''
                    }
                    return t
                },
                {
                    front: '',
                    back: '',
                }
            )
            updateProfileMutation.mutate(
                {
                    ...variables,
                    ghana_card_number: formik.values.ghanaCardNumber,
                },
                {
                    onSuccess: () => {
                        toast.onOpen(
                            'Identity Uploaded Successfully',
                            'success'
                        )
                        setGhanaCardImageFront(undefined)
                        setGhanaCardImageBack(undefined)
                        formik.resetForm()
                    },
                }
            )
        } catch (e) {
            toast.onOpen('Error Uploading Identity Card', 'error')
        }
    }
    function onChange(files: File[], side: 'front' | 'back') {
        const file = files.length > 0 ? files[0] : null

        if (isNil(file)) {
            return
        }

        const sizeInMB = file.size / (1024 * 1024)

        if (sizeInMB > 2) {
            // validate file size. Here limit is 2 MB
            toast.onOpen(
                'File is too large, please select a file less than 2MB',
                'error'
            )
            return
        }

        const reader = new FileReader()

        reader.onload = function (e) {
            if (typeof e.target?.result === 'string') {
                if (side === 'front') {
                    setGhanaCardImageFront({
                        src: e.target?.result,
                        file: file,
                    })
                } else {
                    setGhanaCardImageBack({
                        src: e.target?.result,
                        file: file,
                    })
                }
            }
        }

        reader.readAsDataURL(file)
    }

    return (
        <Box sx={{ display: 'grid', gap: '2rem' }}>
            <Box>
                <Text
                    sx={{
                        color: colors.brown,
                        fontWeight: 600,
                        py: 1,
                        fontSize: 16,
                    }}
                >
                    Ghana Card Number
                </Text>
                <TextInput
                    size={'lg'}
                    placeholder={'GHA-123456789-0'}
                    name={'ghanaCardNumber'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{ width: { xs: '100%', sm: '35rem' } }}
                    errorText={
                        formik.touched.ghanaCardNumber &&
                        formik.errors.ghanaCardNumber
                            ? formik.errors.ghanaCardNumber
                            : undefined
                    }
                    slotProps={{ input: { component: TextMaskAdapter } }}
                />
            </Box>
            <Box>
                <Typography sx={{ color: colors.brown, fontWeight: 600 }}>
                    ID Verification
                </Typography>
                <Text
                    sx={{
                        color: colors.brown,
                        fontWeight: 400,
                        py: 2,
                        fontSize: 16,
                    }}
                >
                    Please click on the appropriate section to upload an image
                    of your valid ID card that clearly shows your name.
                </Text>
            </Box>
            <Box>
                <Grid container>
                    <Grid xs={4}>
                        <InputFileUpload
                            isFront
                            value={ghanaCardImageFront?.src}
                            onChange={(e) => {
                                onChange(
                                    // @ts-expect-error -- for now let's ignore this
                                    (e.target?.files || []) as File[],
                                    'front'
                                )
                            }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <InputFileUpload
                            isFront={false}
                            value={ghanaCardImageBack?.src}
                            onChange={(e) => {
                                onChange(
                                    // @ts-expect-error -- for now let's ignore this
                                    (e.target?.files || []) as File[],
                                    'back'
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Button
                    disabled={
                        isNil(ghanaCardImageFront) ||
                        isNil(ghanaCardImageBack) ||
                        !formik.isValid ||
                        !formik.dirty
                    }
                    onClick={onSubmit}
                    sx={{
                        px: { xs: 1.2, sm: 3, md: 3.4 },
                        ml: 0.5,
                        height: 50,
                    }}
                >
                    {updateProfileMutation.isPending
                        ? 'Uploading...'
                        : 'Verify Identity'}
                </Button>
            </Box>
            <VerifyIdentityModal
                onToggle={() => navigate('/secure')}
                isPendingVerification={Boolean(
                    authUserController.user?.has_uploaded_id_card
                )}
                isOpen={
                    Boolean(authUserController.user?.has_uploaded_id_card) &&
                    !authUserController.user?.is_identity_card_valid
                }
            />
        </Box>
    )
}

const VisuallyHiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
`

type InputFileUploadProps = React.HTMLProps<HTMLInputElement> & {
    isFront: boolean
    value?: string
}
export default function InputFileUpload(props: InputFileUploadProps) {
    function display() {
        if (props.value && props.isFront) {
            return (
                <img
                    style={{
                        border: 0,
                        objectFit: 'cover',
                        width: '100%',
                    }}
                    alt={'identity card front'}
                    src={props.value}
                />
            )
        }
        if (props.value && !props.isFront) {
            return (
                <img
                    style={{
                        border: 0,
                        objectFit: 'cover',
                        width: '100%',
                    }}
                    src={props.value}
                    alt={'identity card back'}
                />
            )
        }
        if (props.isFront) {
            return <GhanaCardFrontIcon />
        }

        return <GhanaCardBackIcon />
    }
    return (
        <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            variant="outlined"
            color="neutral"
            style={{
                border: 0,
                objectFit: 'cover',
                padding: '0',
                width: '80%',
                height: 'auto',
                margin: '0 0',
            }}
        >
            {display()}
            <VisuallyHiddenInput
                name={props.name}
                onChange={props.onChange}
                type="file"
                accept="image/png, image/jpeg"
            />
        </Button>
    )
}
