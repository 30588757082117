import { Link, NavLink } from 'react-router-dom'
import {
    Box,
    Typography,
    List,
    ListItem,
    Divider,
    Container,
    ListItemDecorator,
} from '@mui/joy'
import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import InstagramIcon from '@/assets/svgs/instagram-icon.svg?react'
import TwitterIcon from '@/assets/svgs/x-icon.svg?react'
import LinkedInIcon from '@/assets/svgs/linkedin-icon.svg?react'

import { colors } from '@/utilities/colors.ts'
import styles from '@/components/footer/footer.module.css'
import { DateTime } from 'luxon'

export function Footer() {
    const links = [
        {
            href: `${process.env.VITE_APP_URL}/properties?property_types=residential&isRegion=true&region=652390a94e8b1f63133f565e`,
            title: 'Apartment for rent in Accra',
        },
        {
            href: `${process.env.VITE_APP_URL}/properties?property_types=residential&isRegion=true&region=652390a94e8b1f63133f565e`,
            title: 'Townhouse for rent in Accra',
        },
    ]

    return (
        <Box className={styles.wrapper}>
            <Box
                pt={3}
                pb={3}
                pl={{
                    xs: 2,
                    sm: 0,
                }}
                className={styles.footer}
                sx={{
                    bgcolor: colors.lightGreen,
                }}
            >
                <Container maxWidth={'xl'}>
                    <Box display="flex" justifyContent="center" flexWrap="wrap">
                        <Box sx={{ flex: { xs: '1 1 100%', sm: '1' }, pr: 2 }}>
                            <Typography
                                sx={{
                                    fontSize: 17,
                                    fontWeight: 700,
                                    color: colors.secondary,
                                    pt: 0,
                                    pb: 0,
                                }}
                                className={styles.footer_item_title}
                            >
                                ABOUT US
                            </Typography>
                            <List>
                                <ListItem sx={{ pl: 0 }}>
                                    <Typography
                                        level={'body-sm'}
                                        sx={{ color: '#5E4C5A' }}
                                    >
                                        Our mission is to make rental process
                                        easy for tenants and landlords in Ghana.
                                    </Typography>
                                </ListItem>
                                <ListItem
                                    sx={{
                                        pl: 0,
                                        mt: { xs: 0.5, sm: 0 },
                                    }}
                                >
                                    <Typography
                                        level={'body-sm'}
                                        sx={{ color: '#5E4C5A' }}
                                    >
                                        Openprop allows you to manage your
                                        listing, verify your future landlords
                                        and prospective tenants.
                                    </Typography>
                                </ListItem>
                            </List>
                        </Box>
                        <Box sx={{ flex: 1, pl: { xs: 0, sm: 2 } }}>
                            <Typography
                                sx={{
                                    fontSize: 17,
                                    fontWeight: 700,
                                    color: colors.secondary,
                                }}
                                className={styles.footer_item_title}
                            >
                                POPULAR SEARCHES
                            </Typography>
                            <List>
                                {links.map((link, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            pl: 0,
                                            py: 0,
                                            mb: 0,
                                            fontWeight: 400,
                                        }}
                                        component={Link}
                                        to={link.href}
                                    >
                                        <Typography
                                            level={'body-sm'}
                                            sx={{ color: '#5E4C5A' }}
                                        >
                                            {link.title}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            mt: 1,
                            gap: '0.5rem',
                        }}
                    >
                        <List orientation={'horizontal'} sx={{ pl: 0 }}>
                            <Link to={'tel:+233546919820'}>
                                <ListItem sx={{ pl: 0 }}>
                                    <ListItemDecorator>
                                        <CallIcon
                                            width={30}
                                            sx={{ color: colors.brown }}
                                        />
                                    </ListItemDecorator>
                                    <Typography
                                        level={'body-sm'}
                                        sx={{ color: '#5E4C5A' }}
                                    >
                                        +233546919820
                                    </Typography>
                                </ListItem>
                            </Link>
                            <Link to={'mail:info@razorlabs.tech'}>
                                <ListItem sx={{ pl: 0 }}>
                                    <ListItemDecorator>
                                        <EmailIcon
                                            sx={{ color: colors.brown }}
                                        />
                                    </ListItemDecorator>
                                    <Typography
                                        level={'body-sm'}
                                        sx={{ color: '#5E4C5A' }}
                                    >
                                        info@razorlabs.tech
                                    </Typography>
                                </ListItem>
                            </Link>
                        </List>
                    </Box>

                    <Box className={styles.footer_item_list}>
                        <List
                            orientation={'horizontal'}
                            sx={{
                                pl: 0,
                                mt: { xs: 0.6, sm: 0.6 },
                            }}
                        >
                            <ListItem
                                sx={{
                                    pl: 0,
                                    width: { xs: 20, sm: 'auto' },
                                    px: { sm: '0.5rem' },
                                }}
                            >
                                <NavLink
                                    to={'https://www.instagram.com/razor.labs/'}
                                    target={'_blank'}
                                >
                                    <ListItemDecorator sx={{ minWidth: 30 }}>
                                        <InstagramIcon />
                                    </ListItemDecorator>
                                </NavLink>
                            </ListItem>
                            <ListItem
                                sx={{
                                    px: { xs: '1.3rem', sm: '0.5rem' },
                                    width: { xs: 20, sm: 'auto' },
                                }}
                            >
                                <NavLink
                                    to={'https://twitter.com/openprop_'}
                                    target={'_blank'}
                                >
                                    <ListItemDecorator sx={{ minWidth: 30 }}>
                                        <TwitterIcon />
                                    </ListItemDecorator>
                                </NavLink>
                            </ListItem>
                            <ListItem
                                sx={{
                                    px: { xs: '1.3rem', sm: '0.5rem' },
                                    width: { xs: 20, sm: 'auto' },
                                }}
                            >
                                <NavLink
                                    to={
                                        'https://www.linkedin.com/company/razorlabs-tech'
                                    }
                                    target={'_blank'}
                                >
                                    <ListItemDecorator sx={{ minWidth: 30 }}>
                                        <LinkedInIcon />
                                    </ListItemDecorator>
                                </NavLink>
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ width: '100%', mt: { xs: 1, sm: 0 } }}>
                        <List
                            orientation={'horizontal'}
                            sx={{
                                display: 'flex',
                                mx: { xs: 0, sm: 0 },
                                placeItems: 'center',
                                gap: 2,
                                pl: 0,
                                justifyContent: {
                                    xs: 'flex-start',
                                    sm: 'center',
                                },
                            }}
                        >
                            <ListItem
                                sx={{
                                    px: 0,
                                    pl: { xs: 0, sm: 0 },
                                }}
                                component={Link}
                                to={'terms'}
                            >
                                <Typography
                                    level={'body-sm'}
                                    sx={{ color: '#467565' }}
                                >
                                    Terms of Service
                                </Typography>
                            </ListItem>
                            <ListItem
                                sx={{ px: 0, ml: { xs: '-0.5rem', sm: 0 } }}
                                component={Link}
                                to={'privacy'}
                            >
                                <Typography
                                    level={'body-sm'}
                                    sx={{ color: '#467565' }}
                                >
                                    Privacy Policy
                                </Typography>
                            </ListItem>
                        </List>
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            width: '100%',
                            marginBottom: '10px',
                            ml: { xs: -2, sm: 0 },
                            mt: { xs: '-0.7rem', sm: 0 },
                        }}
                    />
                    <Typography
                        level="body-sm"
                        sx={{
                            width: '100',
                            textAlign: { xs: 'left', sm: 'center' },
                            pl: { xs: 0, sm: 0 },
                            color: '#5E4C5A',
                            mt: { xs: '1rem', sm: 0 },
                        }}
                    >
                        &copy; RAZORLABS, {DateTime.now().year}
                    </Typography>
                </Container>
            </Box>
        </Box>
    )
}
