import { Notification } from '@/services/use-get-user-notifications/schema'
import { colors } from '@/utilities/colors'
import { Box, MenuItem, Typography } from '@mui/joy'

interface NotificationListProps {
    isError: boolean
    notifications: Notification[]
}
export function NotificationList(props: NotificationListProps) {
    if (props.isError) {
        return (
            <Box
                sx={{
                    minHeight: '10rem',
                    minWidth: '5rem',
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography>Whoops!!!... something went wrong</Typography>
            </Box>
        )
    }
    if (props.notifications.length < 0) {
        return (
            <Box
                sx={{
                    minHeight: '10rem',
                    minWidth: '5rem',
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography>You have no notifications</Typography>
            </Box>
        )
    }

    return (
        <>
            {props.notifications.map((notification) => {
                return (
                    <MenuItem
                        key={notification._id}
                        sx={{
                            display: 'block',
                            textAlign: 'left',
                            px: 2,
                            py: 1,
                            mt: 0.4,
                        }}
                    >
                        <Typography
                            sx={{
                                color: colors.secondary,
                                fontWeight: 600,
                                fontSize: '1.2rem',
                            }}
                        >
                            {notification.title}
                        </Typography>
                        <Typography sx={{ color: colors.brown }}>
                            {notification.description}
                        </Typography>
                    </MenuItem>
                )
            })}
        </>
    )
}
