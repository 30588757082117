import { z } from 'zod'

export const PropertyLocationSchema = z.object({
    id: z.string(),
    name: z.string(),
    isRegion: z.boolean(),
})

export const PropertyLocationPlainSchema = z.object({
    id: z.string(),
    region_name: z.string(),
    neighbourhoods: z
        .object({
            id: z.string(),
            neighbourhood_name: z.string(),
            coords: z.object({
                type: z.string(),
                coordinates: z.array(z.number()),
            }),
        })
        .array(),
})

export const PropertyLocationListSchema = PropertyLocationSchema.array()
export type PropertyLocation = z.infer<typeof PropertyLocationSchema>
export const PropertyLocationListPlainSchema =
    PropertyLocationPlainSchema.array()
export type PropertyLocationPlain = z.infer<typeof PropertyLocationPlainSchema>
