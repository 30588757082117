import { CreatePropertyProvider } from '@/src/pages/secure/properties/create-property/hooks/use-create-property/provider'
import { CreatePropertyForm } from '@/src/pages/secure/properties/create-property/components/create-property-form'

export function CreateProperty() {
    return (
        <CreatePropertyProvider>
            <CreatePropertyForm />
        </CreatePropertyProvider>
    )
}
