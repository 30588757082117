import * as React from "react";
const SvgSearchFilter = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 62 48", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M62,6.995C62,3.135 58.865,0 55.005,0L6.995,0C3.135,0 0,3.135 0,6.995L0,41.005C0,44.865 3.135,48 6.995,48L55.005,48C58.865,48 62,44.865 62,41.005L62,6.995Z", style: {
  fill: "rgb(217,217,217)",
  fillOpacity: 0.4
} }), /* @__PURE__ */ React.createElement("path", { d: "M34,22.5C34.754,22.5 35.487,22.257 36.092,21.806C36.696,21.356 37.139,20.722 37.355,20L40,20C40.265,20 40.52,19.895 40.707,19.707C40.895,19.52 41,19.265 41,19C41,18.735 40.895,18.48 40.707,18.293C40.52,18.105 40.265,18 40,18L37.355,18C37.139,17.278 36.696,16.645 36.091,16.195C35.487,15.745 34.754,15.502 34,15.502C33.246,15.502 32.513,15.745 31.909,16.195C31.304,16.645 30.861,17.278 30.645,18L22,18C21.735,18 21.48,18.105 21.293,18.293C21.105,18.48 21,18.735 21,19C21,19.265 21.105,19.52 21.293,19.707C21.48,19.895 21.735,20 22,20L30.645,20C30.861,20.722 31.304,21.356 31.908,21.806C32.513,22.257 33.246,22.5 34,22.5ZM22,28C21.735,28 21.48,28.105 21.293,28.293C21.105,28.48 21,28.735 21,29C21,29.265 21.105,29.52 21.293,29.707C21.48,29.895 21.735,30 22,30L24.145,30C24.361,30.722 24.804,31.355 25.409,31.805C26.013,32.255 26.746,32.498 27.5,32.498C28.254,32.498 28.987,32.255 29.591,31.805C30.196,31.355 30.639,30.722 30.855,30L40,30C40.265,30 40.52,29.895 40.707,29.707C40.895,29.52 41,29.265 41,29C41,28.735 40.895,28.48 40.707,28.293C40.52,28.105 40.265,28 40,28L30.855,28C30.639,27.278 30.196,26.645 29.591,26.195C28.987,25.745 28.254,25.502 27.5,25.502C26.746,25.502 26.013,25.745 25.409,26.195C24.804,26.645 24.361,27.278 24.145,28L22,28Z", style: {
  fill: "rgb(94,76,90)"
} }));
export default SvgSearchFilter;
