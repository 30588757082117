import { Box, Radio, RadioGroup } from '@mui/joy'
import { PropertyType } from '@/src/pages/home/components/search-filter/types.ts'

type TSelectPropertyTypesProps = {
    value: PropertyType
    onChange: (value: PropertyType) => void
}

export function SelectPropertyTypes(props: TSelectPropertyTypesProps) {
    return (
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={PropertyType.RESIDENTIAL}
            name="property-type-group"
        >
            <Box>
                <Radio
                    checked={props.value === PropertyType.RESIDENTIAL}
                    onChange={() => props.onChange(PropertyType.RESIDENTIAL)}
                    label={'Residential'}
                />
            </Box>
            {/*<Box>*/}
            {/*    <Radio*/}
            {/*        checked={props.value === PropertyType.COMMERCIAL}*/}
            {/*        onChange={() => props.onChange(PropertyType.COMMERCIAL)}*/}
            {/*        label={'Commercial'}*/}
            {/*    />*/}
            {/*</Box>*/}
        </RadioGroup>
    )
}
