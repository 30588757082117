import React from 'react'
import { useStytch } from '@stytch/react'
import { Box, Button, Stack, Typography } from '@mui/joy'
import { Header } from '@/src/pages/auth/components/header'
import { TextInput } from '@/components/text-input'
import { colors } from '@/utilities/colors.ts'
import { useFormik } from 'formik'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { StytchAPIError } from '@stytch/vanilla-js'
import { useToastController } from '@/services/use-toast'

export function ResetPassword() {
    const stytchClient = useStytch()
    const toast = useToastController()
    const [isEmailSent, setIsEmailSent] = React.useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: toFormikValidationSchema(
            z.object({
                email: z.string().email(),
            })
        ),
        onSubmit: async ({ email }) => {
            await resetPasswordStart(email)
        },
    })

    const resetPasswordStart = React.useCallback(
        async (email: string) => {
            try {
                await stytchClient.passwords.resetByEmailStart({
                    email: email,
                    login_expiration_minutes: 45000,
                    reset_password_redirect_url: import.meta.env
                        .VITE_PASSWORD_RESET_PASSWORD_REDIRECT_URL,
                })
                setIsEmailSent(true)
                toast.onOpen(`Password link sent to ${email}`, 'success')
            } catch (err) {
                let message = 'Oops! Something went wrong!'
                const error = err as StytchAPIError
                if (error.error_type === 'email_not_found') {
                    message = 'Email not found!'
                } else if (error.error_type === 'too_many_requests') {
                    message = 'Too many requests'
                }
                toast.onOpen(message, 'error')
            }
        },
        [stytchClient]
    )

    if (isEmailSent) {
        return (
            <Box>
                <Header />
                <Box
                    sx={{
                        mx: 'auto',
                        width: {
                            xs: '90%',
                            sm: '80%',
                            md: '50%',
                        },
                    }}
                >
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        gap={'24px'}
                    >
                        <Typography
                            fontSize={40}
                            fontWeight={600}
                            textAlign={'center'}
                        >
                            Check your email
                        </Typography>
                        <Typography
                            sx={{
                                width: {
                                    xs: '90%',
                                    sm: '80%',
                                    md: '50%',
                                },
                                textAlign: 'center',
                            }}
                        >
                            We sent a password reset link to{' '}
                            {formik.values.email}
                        </Typography>
                        <Button sx={{ width: '40%', fontSize: '15px' }}>
                            Go to email
                        </Button>
                        <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                            <Typography sx={{ color: 'gray' }}>
                                Didn't receive email?
                            </Typography>
                            <Typography sx={{ color: colors.primary }}>
                                Click to resend
                            </Typography>
                        </Box>
                        <Box sx={{ fontWeight: '600' }}>Back to Log in</Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            <Header />
            <Box
                sx={{
                    mx: 'auto',
                    width: {
                        xs: '90%',
                        sm: '80%',
                        md: '50%',
                    },
                }}
            >
                <Stack mt={4} gap={4} direction={'column'}>
                    <Box
                        textAlign={'center'}
                        gap={4}
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        <Typography level={'h2'} sx={{ color: colors.brown }}>
                            Reset Password
                        </Typography>
                        <Typography>
                            Please enter the email you used to sign-up. A reset
                            key will be sent to your email to help reset your
                            password.
                        </Typography>
                    </Box>
                    <TextInput
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && !!formik.errors.email}
                        name={'email'}
                        placeholder="Email"
                        size={'lg'}
                    />
                    <Button
                        onClick={() => formik.handleSubmit()}
                        sx={{
                            py: 1.5,
                            px: 10,
                            width: 120,
                            borderRadius: 10,
                            alignSelf: 'center',
                        }}
                    >
                        Reset
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}
