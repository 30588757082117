import { Footer } from '@/components/footer'
import { Navbar } from '@/components/navbar'
import { colors } from '@/utilities/colors'
import {
    Box,
    Container,
    List,
    ListItem,
    ListItemContent,
    Typography,
} from '@mui/joy'
import { useLayoutEffect } from 'react'

export function PrivacyPolicy() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <Box>
            <Navbar />
            <Container disableGutters sx={{ my: '1.5rem' }}>
                <Typography level="h4" sx={{ color: colors.brown }}>
                    Openprop Privacy Policy
                </Typography>

                <List marker="decimal" sx={{ gap: 2, mt: '0.5rem' }}>
                    <ListItem nested>
                        <ListItemContent>Introduction</ListItemContent>
                        <ListItemContent>
                            Welcome to Open Prop - Manage your real estate
                            listings . This Privacy Policy is designed to inform
                            you about the types of information we collect, how
                            we use that information, and the choices you have
                            regarding the information you provide to us.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>
                            Information We Collect
                        </ListItemContent>
                        <List marker="disc">
                            <ListItem>
                                <Typography sx={{ fontWeight: 600 }}>
                                    Personal Information:
                                </Typography>{' '}
                                We may collect personal information such as your
                                name, email address, and other contact details
                                when you voluntarily provide them.
                            </ListItem>
                            <ListItem>
                                <Typography sx={{ fontWeight: 600 }}>
                                    Non-Personal Information:
                                </Typography>{' '}
                                We may also collect non-personal information,
                                such as browser information, IP addresses, and
                                cookies, to enhance your experience on our
                                Website.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>
                            How We Use Your Information
                        </ListItemContent>
                        <List marker="disc">
                            <ListItem>
                                We use the information collected for purposes
                                such as improving our services, providing
                                customer support, and sending updates about our
                                products or services.
                            </ListItem>
                            <ListItem>
                                Your personal information will not be sold,
                                exchanged, transferred, or given to any other
                                company without your consent, except for the
                                express purpose of delivering the purchased
                                product or service requested.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>
                            Cookies and Tracking Technologies
                        </ListItemContent>
                        <List marker="disc">
                            <ListItem>
                                We may use cookies and similar tracking
                                technologies to enhance your experience on the
                                Website.
                            </ListItem>
                            <ListItem>
                                You can choose to disable cookies through your
                                browser settings, but this may affect your
                                ability to access or use certain features of the
                                Website.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>Third-Party Links</ListItemContent>
                        <ListItemContent>
                            Our Website may contain links to third-party
                            websites. We are not responsible for the privacy
                            practices or the content of such websites.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>Your Choices</ListItemContent>
                        <ListItemContent>
                            You may choose to opt-out of receiving promotional
                            emails from us by following the unsubscribe
                            instructions provided in the emails.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>
                            Children's Online Privacy Protection Act (COPPA)
                        </ListItemContent>
                        <ListItemContent>
                            Our Website is not directed to children under the
                            age of 13. We do not knowingly collect personal
                            information from children under 13. If you are a
                            parent or guardian and believe that your child has
                            provided us with personal information, please
                            contact us.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>
                            Changes to this Privacy Policy
                        </ListItemContent>
                        <ListItemContent>
                            We reserve the right to update or modify this
                            Privacy Policy at any time. Changes will be
                            effective immediately upon posting.
                        </ListItemContent>
                    </ListItem>
                    <ListItem nested>
                        <ListItemContent>Contact Information</ListItemContent>
                        <ListItemContent>
                            For any questions about these Terms of Service,
                            please contact us at{' '}
                            <a href="mailto:contact@razorlabs.tech">
                                contact@razorlabs.tech
                            </a>
                            .
                        </ListItemContent>
                    </ListItem>
                </List>
            </Container>
            <Footer />
        </Box>
    )
}
