import { DateTime } from 'luxon'

export const currentYear = DateTime.now().year

export function getListOfYears(startYear: number, endYear?: number) {
    const finalEndYear = endYear || DateTime.now().year
    return Array.from(
        { length: finalEndYear - startYear },
        (_, i) => finalEndYear + i * -1
    )
}

export function isAfter(futureDate: DateTime, beforeDate: DateTime) {
    return beforeDate > futureDate
}
