import { Outlet } from 'react-router-dom'
import { Box } from '@mui/joy'

export function AuthLayout() {
    return (
        <Box sx={{ px: 2 }}>
            <Box></Box>
            <Outlet />
        </Box>
    )
}
