import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import {
    PropertyLocation,
    PropertyLocationListPlainSchema,
    PropertyLocationListSchema,
    PropertyLocationPlain,
} from '@/services/use-get-properties-locations/types.ts'
import { Axios } from '@/utilities/axios.ts'
import { keys } from '@/services/query-keys.ts'
import { ErrorResponse } from '@/services/error.ts'

export function useGetPropertiesLocations() {
    return useQuery<
        PropertyLocation[],
        ErrorResponse,
        PropertyLocation[],
        string[]
    >({
        queryKey: [keys.GET_PROPERTY_LOCATION.key],
        queryFn: async (): Promise<PropertyLocation[]> => {
            const res: AxiosResponse<PropertyLocation[]> = await Axios.get(
                keys.GET_PROPERTY_LOCATION.url
            )
            return PropertyLocationListSchema.parse(res)
        },
        // networkMode: "online"
    })
}

export function useGetPropertiesLocationsFormatPlain() {
    return useQuery<
        PropertyLocationPlain[],
        ErrorResponse,
        PropertyLocationPlain[],
        string[]
    >({
        queryKey: [keys.GET_PROPERTY_LOCATION_PLAIN.key],
        queryFn: async (): Promise<PropertyLocationPlain[]> => {
            const res: AxiosResponse<PropertyLocationPlain[]> = await Axios.get(
                keys.GET_PROPERTY_LOCATION_PLAIN.url
            )
            return PropertyLocationListPlainSchema.parse(res)
        },
    })
}
