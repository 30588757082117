import React from 'react'
import {
    Button,
    Grid,
    Card,
    Typography,
    CardCover,
    Box,
    CardActions,
} from '@mui/joy'
import { colors } from '@/utilities/colors'
import isNil from 'lodash/isNil'
import { Delete } from '@mui/icons-material'
import { useGetProperty } from '@/services/use-get-properties'
import { useEditPropertyController } from '@/src/pages/secure/properties/edit-property/hooks/use-edit-property'
import { DropZone } from '@/src/pages/secure/properties/components/drop-zone'

export function Step2() {
    const {
        setActiveStep,
        onSaveImages,
        images,
        onRemoveImages,
        propertyId,
        setOldImages,
        oldImages,
        onRemoveOldImages,
        isPreviousImagesUploaded,
        setIsPreviousImagesUploaded,
    } = useEditPropertyController()
    // need to deal with the file field for the images
    const propertyData = useGetProperty(propertyId)
    const previous_images = propertyData.data?.property_images || []

    function onSetCoverPhoto(id: string, type: 'old' | 'new') {
        const isNew = type === 'new'
        const isOld = type === 'old'
        const temp = images.map((img) => {
            if (isNew) {
                return {
                    ...img,
                    is_cover: id === img.id,
                }
            }

            return {
                ...img,
                is_cover: false,
            }
        })
        const updatedOldImages = oldImages.map((img) => {
            if (isOld) {
                return {
                    ...img,
                    is_cover: id === img.id,
                }
            }
            return {
                ...img,
                is_cover: false,
            }
        })
        setOldImages(updatedOldImages)
        onSaveImages(temp)
    }

    React.useEffect(() => {
        if (!isPreviousImagesUploaded) {
            setOldImages(previous_images)
            setIsPreviousImagesUploaded(true)
        }
    }, [])

    return (
        <Box>
            <Box>
                <Typography level={'title-lg'}>
                    Step 2: Upload at least 5 images
                </Typography>
            </Box>
            <Box>
                <DropZone setImages={onSaveImages} />
                {images.length > 0 && (
                    <Typography textAlign={'center'} mt={2}>
                        Click on image to select cover picture
                    </Typography>
                )}
                <Grid container mt={3} spacing={1}>
                    {images.map((img) => {
                        if (!isNil(img.src)) {
                            return (
                                <Grid md={4} lg={3} sm={6} xs={12} key={img.id}>
                                    <Card
                                        sx={{
                                            minHeight: 200,
                                            flexGrow: 1,
                                            border: img.is_cover
                                                ? `2px Solid ${colors.primary}`
                                                : '',
                                        }}
                                        onClick={() =>
                                            onSetCoverPhoto(img.id, 'new')
                                        }
                                    >
                                        <CardCover>
                                            <img
                                                src={img.src}
                                                loading="lazy"
                                                alt=""
                                            />
                                        </CardCover>
                                        <CardCover
                                            sx={{
                                                background:
                                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 100px)',
                                            }}
                                        />
                                        <CardActions
                                            sx={{ justifyContent: 'flex-end' }}
                                        >
                                            <Delete
                                                sx={{
                                                    color: colors.red,
                                                }}
                                                onClick={(e) => {
                                                    if (e.stopPropagation) {
                                                        e.stopPropagation()
                                                    }
                                                    onRemoveImages(img.id)
                                                }}
                                            />
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        }
                    })}
                    {oldImages.map((img) => {
                        if (!isNil(img.image_url)) {
                            return (
                                <Grid md={4} lg={3} sm={6} xs={12} key={img.id}>
                                    <Card
                                        sx={{
                                            minHeight: 200,
                                            flexGrow: 1,
                                            border: img.is_cover
                                                ? `2px Solid ${colors.primary}`
                                                : '',
                                        }}
                                        onClick={() =>
                                            onSetCoverPhoto(img.id, 'old')
                                        }
                                    >
                                        <CardCover>
                                            <img
                                                src={img.image_url}
                                                loading="lazy"
                                                alt=""
                                            />
                                        </CardCover>
                                        <CardCover
                                            sx={{
                                                background:
                                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 100px)',
                                            }}
                                        />
                                        <CardActions
                                            sx={{ justifyContent: 'flex-end' }}
                                        >
                                            <Delete
                                                sx={{
                                                    color: colors.red,
                                                }}
                                                onClick={(e) => {
                                                    if (e.stopPropagation) {
                                                        e.stopPropagation()
                                                    }
                                                    onRemoveOldImages(img.id)
                                                }}
                                            />
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        }
                    })}
                </Grid>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: '2rem',
                }}
            >
                <Button
                    onClick={() => setActiveStep(0)}
                    sx={{
                        backgroundColor: colors.white,
                        color: colors.primary,
                        border: `1px solid ${colors.primary}`,
                        '&:hover': {
                            color: colors.white,
                        },
                    }}
                >
                    Back
                </Button>
                <Button
                    onClick={() => {
                        setActiveStep(2)
                    }}
                    disabled={images.length + oldImages.length < 4}
                >
                    Next
                </Button>
            </Box>
        </Box>
    )
}
