import { useMediaQuery, useTheme } from '@mui/material'
import { PropertyCardLoading } from '@/components/property-card/components/property-card-loading'
import { Property } from '@/services/use-get-properties/types'
import { Box, Grid, Typography } from '@mui/joy'
import { NoFavourite } from '@/src/pages/secure/favourites/components/no-favourites'
import { PropertyCard } from '@/components/property-card'

interface FavouritePropertiesProps {
    isLoading: boolean
    isFetching: boolean
    isError: boolean
    propertyList: Property[]
}

export function FavouriteProperties(props: FavouritePropertiesProps) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))

    if (props.isLoading) {
        return <PropertyCardLoading length={isMobile ? 1 : isTablet ? 2 : 3} />
    }

    if (props.isError) {
        return <Typography>An error occurred</Typography>
    }

    if (props.propertyList.length === 0) {
        return <NoFavourite />
    }

    return (
        <>
            {props.isFetching && <Box>Refetching...</Box>}
            <Grid container spacing={2}>
                {props.propertyList.map((property) => (
                    <Grid key={property.id} sm={12} xs={12} md={4} lg={4}>
                        <PropertyCard property={property} isFave noShadow />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
