import {
    Box,
    RadioGroup,
    useTheme,
    radioClasses,
    List,
    ListItem,
    Sheet,
    Radio,
    Avatar,
    FormLabel,
    FormHelperText,
} from '@mui/joy'
import { useMediaQuery, capitalize } from '@mui/material'
import { colors } from '@/utilities/colors'
import {
    PropertyType,
    ResidentialType,
} from '@/src/pages/home/components/search-filter/types.ts'
import { FontSize } from '@/components/text/types.ts'
import { Text } from '@/components/text'
import HomeIcon from '@/assets/svgs/home.svg?react'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import TownHouseIcon from '@/assets/svgs/townhouse-icon.svg?react'
import ApartmentIcon from '@/assets/svgs/apartment-icon.svg?react'
import HouseIcon from '@/assets/svgs/house-icon.svg?react'

interface IPropertyType {
    property_type: PropertyType
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    residential_type: string
    touched: boolean
    error: string
}

export function PropertyTypes(props: IPropertyType) {
    const theme = useTheme()
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'))
    const residentialTypes = [
        ResidentialType.Apartment,
        ResidentialType.House,
        ResidentialType.Townhouse,
    ]

    function getResidentialIcon(name: string): React.ReactNode {
        if (name === 'townhouse') {
            return <TownHouseIcon style={{ height: 25, width: 25 }} />
        }

        if (name === 'house') {
            return <HouseIcon style={{ height: 25, width: 25 }} />
        }

        if (name === 'apartment') {
            return <ApartmentIcon style={{ height: 25, width: 25 }} />
        }
    }

    return (
        <Box>
            <Box>
                <Text textFontSize={FontSize.Title}>
                    Which of these describes your property?
                </Text>
                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                    <RadioGroup
                        overlay
                        value={props.property_type}
                        name="property_types"
                        onChange={props.handleChange}
                        sx={{
                            flexDirection: 'row',
                            gap: 2,
                            [`& .${radioClasses.checked}`]: {
                                [`& .${radioClasses.action}`]: {
                                    inset: -1,
                                    border: '3px solid',
                                    borderColor: colors.primary,
                                },
                            },
                            [`& .${radioClasses.radio}`]: {
                                display: 'contents',
                                '& > svg': {
                                    zIndex: 2,
                                    position: 'absolute',
                                    top: '-8px',
                                    right: '-8px',
                                    bgcolor: 'background.surface',
                                    borderRadius: '50%',
                                },
                            },
                        }}
                    >
                        <List
                            orientation={
                                isMobileDevice ? 'vertical' : 'horizontal'
                            }
                            sx={{
                                minWidth: 240,
                                '--List-gap': '0.5rem',
                                // '--ListItem-paddingY': '1rem',
                                '--ListItem-radius': '8px',
                                '--ListItemDecorator-size': '32px',
                            }}
                        >
                            <ListItem>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 'md',
                                        boxShadow: 'sm',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 1.5,
                                        p: 2,
                                        minWidth: 120,
                                    }}
                                >
                                    <Radio
                                        id={PropertyType.RESIDENTIAL}
                                        value={PropertyType.RESIDENTIAL}
                                        checkedIcon={<CheckCircleRoundedIcon />}
                                    />
                                    <Avatar variant="soft" size="sm">
                                        <HomeIcon />
                                    </Avatar>
                                    <FormLabel
                                        htmlFor={PropertyType.RESIDENTIAL}
                                    >
                                        Residential
                                    </FormLabel>
                                </Sheet>
                            </ListItem>
                        </List>
                    </RadioGroup>
                </Box>
            </Box>
            {props.property_type === PropertyType.RESIDENTIAL && (
                <Box>
                    <Text
                        textFontSize={FontSize.Title}
                        sx={{ color: colors.brown, mb: 2 }}
                    >
                        Specify the type of property{' '}
                    </Text>
                    <RadioGroup
                        aria-label="platform"
                        overlay
                        name="residential_type"
                        value={props.residential_type}
                        onChange={props.handleChange}
                        sx={{
                            mt: 2,
                            flexDirection: 'row',
                            gap: 2,
                            [`& .${radioClasses.checked}`]: {
                                [`& .${radioClasses.action}`]: {
                                    inset: -1,
                                    border: '3px solid',
                                    borderColor: colors.primary,
                                },
                            },
                            [`& .${radioClasses.radio}`]: {
                                display: 'contents',
                                '& > svg': {
                                    zIndex: 2,
                                    position: 'absolute',
                                    top: '-8px',
                                    right: '-8px',
                                    bgcolor: 'background.surface',
                                    borderRadius: '50%',
                                },
                            },
                        }}
                    >
                        <List
                            orientation={
                                isMobileDevice ? 'vertical' : 'horizontal'
                            }
                            sx={{
                                // minWidth: 240,
                                '--List-gap': '0.5rem',
                                // '--ListItem-paddingY': '1rem',
                                '--ListItem-radius': '8px',
                                '--ListItemDecorator-size': '32px',
                            }}
                        >
                            {residentialTypes.map((item, idx) => (
                                <ListItem key={idx}>
                                    <Sheet
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 'md',
                                            boxShadow: 'sm',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1.5,
                                            p: 2,
                                            minWidth: 120,
                                        }}
                                    >
                                        <Radio
                                            value={item}
                                            checkedIcon={
                                                <CheckCircleRoundedIcon />
                                            }
                                        />
                                        <Avatar variant="soft" size="sm">
                                            {getResidentialIcon(item)}
                                        </Avatar>
                                        <FormLabel htmlFor={item}>
                                            {capitalize(item)}
                                        </FormLabel>
                                    </Sheet>
                                </ListItem>
                            ))}
                        </List>
                    </RadioGroup>
                    <FormHelperText sx={{ color: colors.red }}>
                        {props.touched && props.error ? props.error : undefined}
                    </FormHelperText>
                </Box>
            )}
        </Box>
    )
}
