import { Navbar } from '@/components/navbar'
import { useParams } from 'react-router-dom'
import {
    useGetProperty,
    usePostPropertyView,
} from '@/services/use-get-properties'
import { Footer } from '@/components/footer'
import { SingleProperty } from '@/src/pages/properties/[id]/components/property'
import React from 'react'
import isNil from 'lodash/isNil'
import { DateTime } from 'luxon'
import { MetaTags } from '@/components/meta-tags'
import * as Sentry from '@sentry/react'

export function PropertyScreen() {
    const { propertyId } = useParams()
    const property = useGetProperty(propertyId)
    const propertyViewMutation = usePostPropertyView()

    React.useEffect(() => {
        if (property.error) {
            Sentry.captureException(property.error)
        }
    }, [property.error])

    React.useEffect(() => {
        //TODO: move to function
        const propertyListInLocalStorage =
            localStorage.getItem('property_views')
        if (!isNil(propertyId)) {
            if (isNil(propertyListInLocalStorage)) {
                localStorage.setItem(
                    'property_views',
                    JSON.stringify([
                        { [propertyId]: DateTime.now().toMillis() },
                    ])
                )
                propertyViewMutation.mutate(propertyId)
            } else {
                const propertyList = JSON.parse(propertyListInLocalStorage)
                const propertyIndex = propertyList.findIndex(
                    (item: { [key: string]: number }) =>
                        Object.keys(item)[0] === propertyId
                )
                if (propertyIndex === -1) {
                    propertyList.push({
                        [propertyId]: DateTime.now().toMillis(),
                    })
                    localStorage.setItem(
                        'property_views',
                        JSON.stringify(propertyList)
                    )
                    //make api call
                    propertyViewMutation.mutate(propertyId)
                    return
                }
                const currentTime = DateTime.now().toMillis()
                const timeDifference =
                    currentTime - propertyList[propertyIndex][propertyId]
                if (timeDifference > 300000) {
                    propertyList[propertyIndex][propertyId] =
                        DateTime.now().toMillis()
                    localStorage.setItem(
                        'property_views',
                        JSON.stringify(propertyList)
                    )
                    //send api request
                    propertyViewMutation.mutate(propertyId)
                    return
                }
            }
        }
    }, [propertyId])

    return (
        <div>
            <MetaTags
                title={
                    property.data
                        ? `${property.data.number_of_bathrooms} Bathrooms ${property.data.number_of_bedrooms} Bedrooms at ${property.data.neighbourhood.neighbourhood_name}`
                        : 'Openprop Africa'
                }
                description={property.data?.overview || ''}
                image={
                    (property.data?.property_images || []).find(
                        (img) => img.is_cover
                    )?.image_url
                }
            />
            <Navbar />
            <SingleProperty
                isLoading={property.isPending}
                property={property.data}
            />
            <Footer />
        </div>
    )
}
