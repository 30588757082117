import * as React from 'react'
import { Property } from '@/services/use-get-properties/types'
import { AspectRatio, Box, Button, Modal, ModalClose, Sheet } from '@mui/joy'
import { Swiper as ImageSwiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Thumbs } from 'swiper/modules'

import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css'
import { Swiper } from 'swiper/types'

import styles from '@/src/pages/properties/[id]/components/modals/images/styles.module.css'

interface IPropertyDetailsProps {
    property?: Property
}

export function MorePropertyPhotosModal(props: IPropertyDetailsProps) {
    const [open, setOpen] = React.useState(false)
    const [activeThumb, setActiveThumb] = React.useState<
        Swiper | undefined | null
    >()
    function handleOpen() {
        setOpen(true)
    }
    function handleClose() {
        setOpen(false)
    }

    function onSwipe(sw: Swiper) {
        setActiveThumb(sw)
    }

    return (
        <React.Fragment>
            <Button
                sx={{
                    fontSize: 'xs',
                    backgroundColor: '#efefef',
                    color: 'black',
                    borderColor: 'white',
                    fontWeight: 'semibold',
                    '&:hover': {
                        backgroundColor: '#FFFFFF',
                    },
                }}
                onClick={handleOpen}
            >
                View more photos
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 'md',
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1, mb: 5 }} />
                    <Box mt={3}>
                        <ImageSwiper
                            modules={[Navigation, Thumbs, Pagination]}
                            slidesPerView={1}
                            navigation
                            style={{ padding: '1rem 0' }}
                            className={'property-images-slider'}
                            thumbs={{
                                swiper:
                                    activeThumb && !activeThumb.destroyed
                                        ? activeThumb
                                        : null,
                                slideThumbActiveClass: styles.activeSlider,
                            }}
                        >
                            {(props.property?.property_images || []).map(
                                (propertyImage, idx) => (
                                    <SwiperSlide key={propertyImage.image_url}>
                                        <AspectRatio
                                            objectFit="contain"
                                            minHeight={350}
                                        >
                                            <img
                                                src={propertyImage.image_url}
                                                loading="lazy"
                                                alt={`${propertyImage.image_url}-${idx}`}
                                            />
                                        </AspectRatio>
                                    </SwiperSlide>
                                )
                            )}
                        </ImageSwiper>
                    </Box>
                    <ImageSwiper
                        spaceBetween={2}
                        onSwiper={onSwipe}
                        modules={[Navigation, Thumbs, Pagination]}
                        slidesPerView={7}
                        className={`${styles.propertyImagesSliderThumbs} propertyImagesSliderThumbs`}
                    >
                        {(props.property?.property_images || []).map(
                            (propertyImage, idx) => (
                                <SwiperSlide
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    key={propertyImage.image_url}
                                >
                                    <AspectRatio
                                        sx={{
                                            width: '10rem',
                                            height: '6rem',
                                        }}
                                    >
                                        <img
                                            src={propertyImage.image_url}
                                            loading="lazy"
                                            alt={`${propertyImage.image_url}-${idx}`}
                                        />
                                    </AspectRatio>
                                </SwiperSlide>
                            )
                        )}
                    </ImageSwiper>
                </Sheet>
            </Modal>
        </React.Fragment>
    )
}
