import { CssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/joy/CssBaseline'
import Box from '@mui/joy/Box'
import Header from '@/src/pages/secure/messaging/components/header'
import MyMessages from '@/src/pages/secure/messaging/components/my-messages'

export function ChatMessaging() {
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Header />
                <Box component="main" className="MainContent" sx={{ flex: 1 }}>
                    <MyMessages />
                </Box>
            </Box>
        </CssVarsProvider>
    )
}
