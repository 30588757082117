import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ErrorResponse } from '@/services/error.ts'
import { keys } from '@/services/query-keys.ts'
import { AxiosResponse } from 'axios'
import { Axios } from '@/utilities/axios.ts'
import {
    Amenities,
    AmenitiesSchema,
} from '@/services/use-get-amenities/types.ts'

export function useGetAmenities(): UseQueryResult<Amenities[], ErrorResponse> {
    return useQuery<Amenities[], ErrorResponse>({
        queryKey: [keys.GET_AMENITIES.key],
        queryFn: async (): Promise<Amenities[]> => {
            const res: AxiosResponse<Amenities[]> = await Axios.get(
                keys.GET_AMENITIES.url
            )
            return AmenitiesSchema.parse(res)
        },
    })
}
