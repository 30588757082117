import { Box, Typography, Dropdown, Menu, MenuButton } from '@mui/joy'
import { Link } from 'react-router-dom'
import { colors } from '@/utilities/colors'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { useGetUserNotifications } from '@/services/use-get-user-notifications'
import { NotificationList } from '@/src/pages/secure/components/secure-header/components/notification-dropdown/components/notification-list'

export function NotificationDropdown() {
    const notifications = useGetUserNotifications()
    return (
        <Box>
            <Dropdown>
                <MenuButton
                    sx={{
                        boxShadow: '0px 0px 0px 0px rgba(157, 154, 154, 0.75)',
                        p: 1,
                        mb: 2,
                        display: 'flex',
                        borderRadius: '50%',
                        width: 50,
                        height: 50,
                        justifyContent: 'center',
                        backgroundColor: colors.white,
                        '&:focus': {
                            outline: 'none',
                        },
                        '&:hover': {
                            border: 'none',
                        },
                    }}
                >
                    <NotificationsActiveIcon sx={{ color: colors.brown }} />
                </MenuButton>
                <Menu
                    placement={'bottom-end'}
                    sx={{
                        backgroundColor: colors.gray15,
                        minWidth: '25rem',
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        px={2}
                        mb={2}
                        alignItems="center"
                    >
                        <Typography>Notifications</Typography>
                        <Typography
                            sx={{
                                borderRadius: '2rem',
                                padding: '0.1rem 0.8rem',
                                width: 'fit-content',
                                boxShadow:
                                    '0px 0px 6px 0px rgba(157,154,154,0.75)',
                                backgroundColor: colors.white,
                            }}
                        >
                            {(notifications.data || []).length || 0} new
                            notifications
                        </Typography>
                    </Box>

                    <NotificationList
                        isError={notifications.isError}
                        notifications={notifications.data || []}
                    />

                    {(notifications.data || []).length > 1 && (
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            mt={4}
                            px={4}
                        >
                            <Link
                                to="/secure/notifications"
                                style={{ color: colors.brown }}
                            >
                                See All
                            </Link>
                        </Box>
                    )}
                </Menu>
            </Dropdown>
        </Box>
    )
}
