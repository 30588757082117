import { Box, Option, Select, Typography } from '@mui/joy'
import { SortType } from '@/services/use-get-properties/types.ts'

type SortPropertyProps = {
    sortType: SortType
    handleChange: (s: unknown, value: string) => void
}

export function SortProperty(props: SortPropertyProps) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
                level="body-md"
                sx={{ fontWeight: 500, fontSize: '1rem' }}
            >
                Sort:
            </Typography>
            <Select
                size={'sm'}
                value={props.sortType}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore --- for now just this
                onChange={props.handleChange}
                sx={{
                    '& .MuiSelect-button:focus': {
                        outline: 0,
                    },
                }}
            >
                <Option value="newest">Newest</Option>
                <Option value="low-price">Price(Low to High)</Option>
                <Option value="high-price">Price(High to Low)</Option>
            </Select>
        </Box>
    )
}
