import React from 'react'
import { Text } from '@/components/text'
import { colors } from '@/utilities/colors.ts'
import { useMediaQuery } from '@mui/material'
import {
    Button,
    Box,
    useTheme,
    Container,
    Autocomplete,
    CircularProgress,
    AutocompleteOption,
    ListItemDecorator,
} from '@mui/joy'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { TPropertyLocationAutoCompleteOption } from '@/utilities/property.tsx'
import { useGetPropertiesLocations } from '@/services/use-get-properties-locations'
import { FilterModal } from '@/components/filter-modal'
import { SelectBathsBedsDropdown } from '@/components/select-baths-beds-dropdown'
import { SelectPriceDropdown } from '@/components/select-price-dropdown'
import { SelectPropertyTypeDropdown } from '@/components/select-property-type-dropdown'
import { PropertyType } from '@/src/pages/home/components/search-filter/types.ts'
import ListItemContent from '@mui/joy/ListItemContent'
import { LocationOn } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useQueryParams } from '@/utilities/use-query-params.tsx'
import isNil from 'lodash/isNil'
import { URLParams } from '@/src/pages/properties/components/search-filter/types'

export function SearchFilter() {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))
    const navigate = useNavigate()
    const params = useQueryParams()

    const [max_price, setMaxPrice] = React.useState<string>('')
    const [min_price, setMinPrice] = React.useState<string>('')
    const [max_number_of_beds, setNoOfBeds] = React.useState<string>('')
    const [max_number_of_bathrooms, setNoOfBaths] = React.useState<string>('')
    const [property_types, setPropertyType] = React.useState<PropertyType>(
        PropertyType.UNKNOWN
    )
    const [openBedBathDropdown, setOpenBedBathDropdown] =
        React.useState<boolean>(false)
    const [openPropertyTypeDropdown, setOpenPropertyTypeDropdown] =
        React.useState<boolean>(false)
    const [openPriceDropdown, setOpenPriceDropdown] =
        React.useState<boolean>(false)
    const [displayMoreFilterModal, setDisplayMoreFilterModal] =
        React.useState<boolean>(false)
    const [query, setQuery] =
        React.useState<TPropertyLocationAutoCompleteOption>({
            name: '',
            id: '',
            isRegion: false,
        })

    const propertyLocations = useGetPropertiesLocations()
    const options = propertyLocations.data || []

    function getParams() {
        const obj: { [key: string]: string } = {}
        for (const [key, value] of params.entries()) {
            obj[key] = value
        }
        return obj
    }

    function updateUrl(queryParams: URLParams) {
        const currentParams = getParams()
        const finalQueryParams = {
            ...currentParams,
            ...queryParams,
        }

        const queryString = Object.entries(finalQueryParams)
            .filter(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ([_, value]) =>
                    !isNil(value) && value !== '' && value !== 'unknown'
            )
            .map(([key, value]) => `${key}=${value}`)
            .join('&')
        navigate(`/properties?${queryString}`)
    }

    function onLocationSearch(res = { id: '', name: '', isRegion: false }) {
        setQuery(res)
        updateUrl({
            isRegion: res.isRegion,
            region: res.id,
        })
    }

    return (
        <React.Fragment>
            {isTablet ? (
                <Container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: `1px solid ${colors.gray10}`,
                        py: 0.5,
                        pr: '5px !important',
                        borderRadius: 10,
                        justifyContent: 'space-between',
                    }}
                    onClick={() => setDisplayMoreFilterModal(true)}
                >
                    <Box display={'flex'} my={1} gap={1}>
                        <LocationOn />
                        <Text
                            sx={{
                                width: {
                                    xs: 160,
                                    sm: 350,
                                    md: 450,
                                },
                                color: colors.gray10,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            Enter your preferred city or neighborhoods
                        </Text>
                    </Box>
                    <Button
                        endDecorator={<KeyboardArrowDownIcon />}
                        onClick={() => setDisplayMoreFilterModal(true)}
                        sx={{
                            backgroundColor: colors.primary,
                            color: colors.white,
                            fontWeight: 400,
                            fontSize: 14,
                            height: 40,
                            py: 0,
                        }}
                    >
                        Filter
                    </Button>
                </Container>
            ) : (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            sx={{
                                minWidth: 400,
                            }}
                        >
                            <Autocomplete
                                endDecorator={
                                    propertyLocations.isLoading ? (
                                        <CircularProgress
                                            size="sm"
                                            sx={{
                                                bgcolor: 'background.surface',
                                            }}
                                        />
                                    ) : null
                                }
                                placeholder={
                                    'Enter your preferred city or neighborhoods'
                                }
                                loading={propertyLocations.isLoading}
                                loadingText={<div>loading...</div>}
                                freeSolo
                                startDecorator={<LocationOn />}
                                onChange={(_, value) => {
                                    if (typeof value !== 'string') {
                                        onLocationSearch(value)
                                    }
                                }}
                                disableClearable
                                size={'lg'}
                                autoComplete
                                value={query}
                                options={options}
                                sx={{
                                    paddingRight: 0,
                                    '& MuiAutocomplete-popper': {
                                        backgroundColor: 'green',
                                    },
                                    ['.MuiAutocomplete-input::placeholder']: {
                                        fontSize: 16,
                                    },
                                    width: '100%',
                                }}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option
                                    }
                                    return option.name
                                }}
                                renderOption={(props, option) => (
                                    <AutocompleteOption
                                        {...props}
                                        sx={{
                                            borderTopColor: colors.gray10,
                                            borderTopStyle: 'solid',
                                            borderTopWidth: 0.6,
                                        }}
                                    >
                                        <ListItemDecorator>
                                            <LocationOn />
                                        </ListItemDecorator>
                                        <ListItemContent
                                            sx={{ fontSize: 'sm' }}
                                        >
                                            {option.name}
                                        </ListItemContent>
                                    </AutocompleteOption>
                                )}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    mr: 0.5,
                                    alignItems: 'center',
                                }}
                            >
                                <SelectBathsBedsDropdown
                                    noOfBaths={max_number_of_bathrooms}
                                    noOfBeds={max_number_of_beds}
                                    onToggle={(val) => {
                                        updateUrl({
                                            max_number_of_beds,
                                            max_number_of_bathrooms,
                                        })
                                        setOpenBedBathDropdown(val)
                                    }}
                                    isOpen={openBedBathDropdown}
                                    setNoOfBaths={setNoOfBaths}
                                    setNoOfBeds={setNoOfBeds}
                                />
                                <SelectPropertyTypeDropdown
                                    isOpen={openPropertyTypeDropdown}
                                    onToggle={(val) => {
                                        setOpenPropertyTypeDropdown(val)
                                        updateUrl({ property_types })
                                    }}
                                    onChange={setPropertyType}
                                    value={property_types}
                                />
                                <SelectPriceDropdown
                                    onToggle={(val) => {
                                        setOpenPriceDropdown(val)
                                        updateUrl({ max_price, min_price })
                                    }}
                                    isOpen={openPriceDropdown}
                                    onSetMinPrice={setMinPrice}
                                    onSetMaxPrice={setMaxPrice}
                                    maxPrice={max_price}
                                    isSelected={
                                        Number(max_price) > 0 ||
                                        Number(min_price) > 0
                                    }
                                    minPrice={min_price}
                                />
                                <Button
                                    variant={'outlined'}
                                    endDecorator={<KeyboardArrowDownIcon />}
                                    onClick={() =>
                                        setDisplayMoreFilterModal(true)
                                    }
                                    sx={{
                                        fontSize: 16,
                                        border: `1px solid ${colors.gray10} !important`,
                                        color: colors.brown,
                                        fontWeight: 400,
                                        height: 45,
                                        py: 0,
                                        ml: 1,
                                        mr: 1,
                                    }}
                                >
                                    Filter
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
            <FilterModal
                showLocationSearch
                onFilter={updateUrl}
                isOpen={displayMoreFilterModal}
                onClose={() => setDisplayMoreFilterModal(false)}
                onOpen={() => setDisplayMoreFilterModal(true)}
            />
        </React.Fragment>
    )
}
