import { Box, Button, Modal, ModalClose, ModalDialog } from '@mui/joy'
import Wallet from '@/assets/images/wallet.png'
import { Typography } from '@mui/material'
import { colors } from '@/utilities/colors'
import { PayWithPayStack } from '@/components/pay-with-paystack'

type TVirtualTourPaymentModal = {
    show: boolean
    setShow: (val: boolean) => void
    amount: number
    onSuccess: () => void
}

export function VirtualTourPaymentModal(props: TVirtualTourPaymentModal) {
    return (
        <Modal open={props.show} onClose={() => props.setShow(false)}>
            <ModalDialog>
                <ModalClose />
                <Box
                    sx={{
                        padding: 4,
                        textAlign: 'center',
                        gap: 2,
                    }}
                >
                    <img src={Wallet} alt="Wallet icon" />
                    <Box
                        display={'flex'}
                        gap={2}
                        mb={4}
                        flexDirection={'column'}
                    >
                        <Typography
                            fontSize={'32px'}
                            fontWeight={700}
                            color={colors.footerTextColor}
                        >
                            Payment Required to Publish property
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 5,
                        }}
                    >
                        <Button
                            sx={{
                                p: 1.5,
                                width: '150px',
                                bgcolor: colors.white,
                                color: colors.footerTextColor,
                                border: '1px solid',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    opacity: '0.8',
                                },
                            }}
                            onClick={() => props.setShow(false)}
                        >
                            Cancel
                        </Button>
                        <PayWithPayStack
                            amount={props.amount}
                            label="Make Payment"
                            onSuccess={props.onSuccess}
                        />
                    </Box>
                </Box>
            </ModalDialog>
        </Modal>
    )
}
