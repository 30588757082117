import { Text } from '@/components/text'
import { FontSize } from '@/components/text/types.ts'
import { Box, Checkbox, Typography } from '@mui/joy'
import React from 'react'

type TVirtualTour = {
    has_virtual_tour: boolean
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function VirtualTourSelect(props: TVirtualTour) {
    return (
        <Box>
            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                Please tick this box if you would like to include a virtual tour
                of your property
            </Text>
            <Checkbox
                name={'has_virtual_tour'}
                label="Virtual tour"
                checked={props.has_virtual_tour}
                color="warning"
                onChange={props.handleChange}
            />
            {props.has_virtual_tour && (
                <Typography level={'body-sm'}>
                    A fee of 2% of property rental price will be added for this
                    service.
                </Typography>
            )}
        </Box>
    )
}
