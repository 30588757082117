// import React from 'react'
// import { useGetAmenities } from '@/services/use-get-amenities'
import { useMediaQuery } from '@mui/material'
import {
    Autocomplete,
    Box,
    Grid,
    useTheme,
    Typography,
    Sheet,
    Drawer,
    radioClasses,
    Radio,
    Avatar,
    FormLabel,
    RadioGroup,
    Divider,
    ModalClose,
    DialogTitle,
    DialogContent,
    CircularProgress,
    AutocompleteOption,
    ListItemDecorator,
} from '@mui/joy'
import { FilterModalFooter } from '@/components/filter-modal/components/filter-modal-footer'

import { colors } from '@/utilities/colors.ts'
import { Text } from '@/components/text'
import { FontSize } from '@/components/text/types.ts'
import TownHouseIcon from '@/assets/svgs/townhouse-icon.svg?react'
import HouseIcon from '@/assets/svgs/house-icon.svg?react'
import ApartmentIcon from '@/assets/svgs/apartment-icon.svg?react'
import { useFormik } from 'formik'
import { TextInput } from '@/components/text-input'
import { NumberOfBedroomsOrBathroomSelector } from '@/components/number-of-bedrooms-bathrooms-selector'
import { useGetPropertiesLocations } from '@/services/use-get-properties-locations'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { LocationOn } from '@mui/icons-material'
import ListItemContent from '@mui/joy/ListItemContent'
import { ResidentialType } from '@/src/pages/home/components/search-filter/types.ts'
import { URLParams } from '@/src/pages/properties/components/search-filter/types.ts'

type TFilterModalProps = {
    showLocationSearch?: boolean
    onClose: () => void
    onOpen: () => void
    isOpen: boolean
    onFilter: (data: URLParams) => void
}

export function FilterModal(props: TFilterModalProps) {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))

    const formik = useFormik({
        initialValues: {
            min_price: '',
            max_price: '',
            max_number_of_beds: 0,
            max_number_of_bathrooms: 0,
            location: {
                name: '',
                id: '',
                isRegion: false,
            },
        },
        onSubmit: (values) => {
            onCompleteFilterSettings({
                max_price: values.max_price,
                min_price: values.min_price,
                max_number_of_beds: `${values.max_number_of_beds}`,
                max_number_of_bathrooms: `${values.max_number_of_bathrooms}`,
                isRegion: values.location.isRegion,
                region: values.location.id,
            })
        },
    })

    const propertyLocations = useGetPropertiesLocations()
    const residentialTypes = [
        ResidentialType.Apartment,
        ResidentialType.House,
        ResidentialType.Townhouse,
    ]
    const options = propertyLocations.data || []

    function onCompleteFilterSettings(values: URLParams) {
        props.onFilter(values)
        props.onClose()
    }

    function onResetFilterSettings() {
        formik.resetForm()
        props.onClose()
    }

    function getResidentialIcon(name: string) {
        if (name === 'townhouse') {
            return <TownHouseIcon style={{ height: 25, width: 25 }} />
        }

        if (name === 'house') {
            return <HouseIcon style={{ height: 25, width: 25 }} />
        }

        if (name === 'apartment') {
            return <ApartmentIcon style={{ height: 25, width: 25 }} />
        }
    }

    return (
        <Drawer
            anchor={'right'}
            size="lg"
            variant="plain"
            open={props.isOpen}
            onClose={props.onClose}
            slotProps={{
                content: {
                    sx: {
                        bgcolor: 'transparent',
                        p: { md: 3, sm: 0 },
                        boxShadow: 'none',
                    },
                },
            }}
        >
            <Sheet
                sx={{
                    borderRadius: 'md',
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    height: '100%',
                    overflow: 'auto',
                }}
                className={'no-scrollbar'}
            >
                <DialogTitle>Filters</DialogTitle>
                <ModalClose />
                <Divider sx={{ mt: 'auto' }} />
                <DialogContent
                    sx={{ gap: 2, px: 2 }}
                    className={'no-scrollbar'}
                >
                    {props.showLocationSearch && isTablet && (
                        <Box mb={2}>
                            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                                Location
                            </Text>
                            <Box
                                sx={{
                                    py: 0.7,
                                    px: 0.3,
                                }}
                            >
                                <Autocomplete
                                    endDecorator={
                                        propertyLocations.isLoading ? (
                                            <CircularProgress
                                                size="sm"
                                                sx={{
                                                    bgcolor:
                                                        'background.surface',
                                                }}
                                            />
                                        ) : null
                                    }
                                    placeholder={
                                        'Enter your preferred city or neighborhoods'
                                    }
                                    loading={propertyLocations.isLoading}
                                    loadingText={<div>loading...</div>}
                                    freeSolo
                                    startDecorator={<LocationOn />}
                                    onChange={(_, value) => {
                                        if (typeof value !== 'string') {
                                            formik.setFieldValue(
                                                'location',
                                                value
                                            )
                                        }
                                    }}
                                    disableClearable
                                    size={'lg'}
                                    autoComplete
                                    value={formik.values.location}
                                    options={options}
                                    sx={{
                                        paddingRight: 0,
                                        '& MuiAutocomplete-popper': {
                                            backgroundColor: 'green',
                                        },
                                        width: '100%',
                                    }}
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option
                                        }
                                        return option.name
                                    }}
                                    renderOption={(props, option) => (
                                        <AutocompleteOption
                                            {...props}
                                            sx={{
                                                borderTopColor: colors.gray10,
                                                borderTopStyle: 'solid',
                                                borderTopWidth: 0.6,
                                            }}
                                        >
                                            <ListItemDecorator>
                                                <LocationOn />
                                            </ListItemDecorator>
                                            <ListItemContent
                                                sx={{ fontSize: 'sm' }}
                                            >
                                                {option.name}
                                            </ListItemContent>
                                        </AutocompleteOption>
                                    )}
                                />
                            </Box>
                        </Box>
                    )}
                    {isTablet && (
                        <>
                            <Box>
                                <Box mb={3}>
                                    <Typography
                                        level={'title-md'}
                                        sx={{ mb: 2 }}
                                    >
                                        Price Range
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid md={6} xs={12} sm={12}>
                                            <TextInput
                                                size={'lg'}
                                                showCurrency
                                                placeholder={'Min'}
                                                value={formik.values.min_price}
                                                onChange={formik.handleChange}
                                                name={'min_price'}
                                                errorText={
                                                    formik.errors.min_price
                                                }
                                            />
                                        </Grid>
                                        <Grid md={6} xs={12} sm={12}>
                                            <TextInput
                                                size={'lg'}
                                                showCurrency
                                                placeholder={'Max'}
                                                value={formik.values.max_price}
                                                onChange={formik.handleChange}
                                                name={'max_price'}
                                                errorText={
                                                    formik.errors.max_price
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Box>
                                        <Text
                                            textFontSize={FontSize.Title}
                                            sx={{ mb: 2 }}
                                        >
                                            Beds
                                        </Text>
                                        <Box>
                                            <NumberOfBedroomsOrBathroomSelector
                                                value={
                                                    formik.values
                                                        .max_number_of_beds
                                                }
                                                onSelect={(value) =>
                                                    formik.setFieldValue(
                                                        'max_number_of_beds',
                                                        value
                                                    )
                                                }
                                                max={5}
                                            />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Text
                                            textFontSize={FontSize.Title}
                                            sx={{ mb: 2 }}
                                        >
                                            Baths
                                        </Text>
                                        <Box>
                                            <NumberOfBedroomsOrBathroomSelector
                                                value={
                                                    formik.values
                                                        .max_number_of_bathrooms
                                                }
                                                onSelect={(value) =>
                                                    formik.setFieldValue(
                                                        'max_number_of_bathrooms',
                                                        value
                                                    )
                                                }
                                                max={5}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                    <Box sx={{ my: 2 }}>
                        <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                            Residential Type
                        </Text>
                        <RadioGroup
                            orientation={isTablet ? 'vertical' : 'horizontal'}
                            aria-label="residential type group"
                            overlay
                            name="residential_type"
                            onClick={formik.handleChange}
                            sx={{
                                mt: 2,
                                // flexDirection: 'row',
                                gap: 2,
                                [`& .${radioClasses.checked}`]: {
                                    [`& .${radioClasses.action}`]: {
                                        inset: -1,
                                        border: '3px solid',
                                        borderColor: colors.primary,
                                    },
                                },
                                [`& .${radioClasses.radio}`]: {
                                    display: 'contents',
                                    '& > svg': {
                                        zIndex: 2,
                                        position: 'absolute',
                                        top: '-8px',
                                        right: '-8px',
                                        bgcolor: 'background.surface',
                                        borderRadius: '50%',
                                    },
                                },
                            }}
                        >
                            {residentialTypes.map((item, idx) => (
                                <Sheet
                                    key={idx}
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 'md',
                                        boxShadow: 'sm',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 1,
                                        p: 2,
                                        minWidth: 70,
                                    }}
                                >
                                    <Radio
                                        value={item}
                                        checkedIcon={<CheckCircleRoundedIcon />}
                                    />
                                    <Avatar variant="soft" size="sm">
                                        {getResidentialIcon(item)}
                                    </Avatar>
                                    <FormLabel htmlFor={item}>{item}</FormLabel>
                                </Sheet>
                            ))}
                        </RadioGroup>
                    </Box>
                </DialogContent>
                <FilterModalFooter
                    onReset={onResetFilterSettings}
                    onFilter={() => formik.handleSubmit()}
                />
            </Sheet>
        </Drawer>
    )
}
