import { colors } from '@/utilities/colors'
import { FontSize } from '@/components/text/types.ts'
import { Text } from '@/components/text'
import { Box, FormHelperText, Textarea } from '@mui/joy'

interface IPropertyOverview {
    touched: boolean
    overview: string
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    handleBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void
    error: string
}

export function PropertyOverview(props: IPropertyOverview) {
    return (
        <Box>
            <Text textFontSize={FontSize.Title} sx={{ mb: 2 }}>
                Write a brief description of your property
            </Text>
            <Textarea
                placeholder="Briefly describe your property..."
                minRows={5}
                name="overview"
                error={props.touched && !!props.error}
                value={props.overview}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
            />
            <FormHelperText sx={{ color: colors.red }}>
                {props.touched && props.error ? props.error : undefined}
            </FormHelperText>
        </Box>
    )
}
