import { Box } from '@mui/joy'
import { Text } from '@/components/text'
import { FontSize } from '@/components/text/types.ts'
import { GoogleMapsView } from '@/components/google-maps-view'

type TClickedCoords = {
    lat: number
    lng: number
} | null

interface IMap {
    clickedCoords: TClickedCoords
    handleClickMap: (e: google.maps.MapMouseEvent) => void
}

export function LocatePropertyOnMap(props: IMap) {
    return (
        <Box mt={2}>
            <Box
                justifyContent="space-between"
                alignItems="center"
                mb={1}
                sx={{ display: { sm: 'flex' } }}
            >
                <Text textFontSize={FontSize.Title}>
                    Locate your property on the map
                </Text>
            </Box>
            <GoogleMapsView
                containerStyle={{ width: '100%', height: '15rem' }}
                onClick={props.handleClickMap}
                clickedCoords={props.clickedCoords}
                center={
                    props.clickedCoords
                        ? {
                              lat: props.clickedCoords?.lat,
                              lng: props.clickedCoords?.lng,
                          }
                        : undefined
                }
            />
        </Box>
    )
}
