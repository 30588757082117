import { Box, Autocomplete, Chip, FormHelperText, Typography } from '@mui/joy'
import { Close } from '@mui/icons-material'
import { colors } from '@/utilities/colors.ts'

type TAmenitySelectorProps = {
    placeholder?: string
    label: string
    options: { name: string; id: string }[]
    description?: string
    error?: string
    value: { name: string; id: string }[]
    onChange: (tags: { name: string; id: string }[]) => void
}

export function AmenitySelector(props: TAmenitySelectorProps) {
    function handleChange(value: { name: string; id: string }[]) {
        props.onChange(value)
    }
    return (
        <Box mt={2} mb={2}>
            <Typography level={'title-md'}>{props.label}</Typography>
            <Box>
                <Autocomplete
                    size={'lg'}
                    multiple
                    placeholder={props.placeholder}
                    options={props.options}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => handleChange(value)}
                    value={props.value}
                    renderTags={(tags, getTagProps) =>
                        tags.map((item, index) => (
                            <Chip
                                variant="solid"
                                endDecorator={<Close fontSize="small" />}
                                {...getTagProps({ index })}
                                sx={{
                                    height: 2,
                                    '.MuiChip-action': {
                                        backgroundColor: colors.brown,
                                    },
                                    '.MuiChip-action:hover': {
                                        backgroundColor: colors.red,
                                    },
                                }}
                            >
                                {item.name}
                            </Chip>
                        ))
                    }
                />
                {props.description && (
                    <FormHelperText>{props.description}</FormHelperText>
                )}
            </Box>
        </Box>
    )
}
