import React from 'react'
import { Box } from '@mui/material'
import {
    AspectRatio,
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardOverflow,
    Modal,
    ModalDialog,
    Tooltip,
    Typography,
} from '@mui/joy'
import { formatPrice } from '@/utilities/format-price.ts'

import { Property } from '@/services/use-get-properties/types.ts'
import { Link } from 'react-router-dom'
import { isResidential } from '@/utilities/property.tsx'
import { HeartIcon } from '@/assets/svg-components/heart-icon.tsx'
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone'
import BathtubTwoToneIcon from '@mui/icons-material/BathtubTwoTone'
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone'
import {
    useRemovePropertiesMutation,
    useToggleUserFavouriteProperties,
} from '@/services/use-get-properties'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { colors } from '@/utilities/colors.ts'
import { handlePropertyInformation } from '@/utilities/property-information.ts'
import Warning from '@/assets/svgs/warning.svg'
import SvgIcons from '@/components/svg-icon'

interface PropertyCardProps {
    noShadow?: boolean
    property: Property
    isFaveDisabled?: boolean
    isFave?: boolean
    hasEditAndDeleteActions?: boolean
}

export function PropertyCard(props: PropertyCardProps) {
    const [open, setOpen] = React.useState(false)
    const favouriteMutation = useToggleUserFavouriteProperties()
    const removePropertyMutation = useRemovePropertiesMutation()

    const userQuery = useGetAuthenticatedUser()
    const isUserFavourite = Boolean(
        userQuery.user?.favourites?.includes(props.property.id)
    )
    const [fakeIsFav, setFaveIsFav] = React.useState(
        Boolean(props.isFave) || isUserFavourite
    )
    const propertyImage =
        props.property.property_images.find((img) => img.is_cover)?.image_url ??
        ''
    async function onClickFave(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        e.preventDefault()
        e.stopPropagation()
        setFaveIsFav(!fakeIsFav)
        await favouriteMutation.mutateAsync(props.property.id)
    }

    return (
        <>
            <Link to={`/properties/${props.property.id}`}>
                <Card
                    variant="outlined"
                    sx={{ boxShadow: 'sm', minHeight: '19rem' }}
                >
                    <CardOverflow>
                        <AspectRatio ratio="2" minHeight={200}>
                            <img
                                src={propertyImage}
                                srcSet={propertyImage}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                        <Box
                            display={'flex'}
                            sx={{
                                left: 0,
                                right: 0,
                                position: 'absolute',
                                justifyContent: 'space-between',
                                px: 2.25,
                                pt: 2,
                                zIndex: 1,
                            }}
                        >
                            {props.property.has_virtual_tour ? (
                                <Box
                                    display={'flex'}
                                    gap={0.5}
                                    alignItems={'center'}
                                    px={0.8}
                                    borderRadius={1.2}
                                    sx={{
                                        backgroundColor: 'rgba(6, 6, 6, 0.477)',
                                        borderRadius: 15,
                                    }}
                                >
                                    <SvgIcons
                                        iconName="cube"
                                        width={20}
                                        height={20}
                                        style={{ color: 'white' }}
                                    />
                                    <Typography
                                        sx={{
                                            color: 'white',
                                            fontWeight: 600,
                                            fontSize: 11,
                                        }}
                                    >
                                        Virtual tour
                                    </Typography>
                                </Box>
                            ) : (
                                <Box
                                    display={'flex'}
                                    gap={2}
                                    alignItems={'center'}
                                />
                            )}
                            {!props.isFaveDisabled && (
                                <HeartIcon
                                    onClick={onClickFave}
                                    isSelected={fakeIsFav}
                                />
                            )}
                        </Box>
                    </CardOverflow>
                    <CardContent>
                        {props.property.property_information.length > 0 && (
                            <Box
                                display={'flex'}
                                gap={1}
                                alignItems={'center'}
                                sx={{
                                    fontWeight: 400,
                                }}
                            >
                                <img src={Warning} alt="warning/info" />
                                <Typography fontSize={12} noWrap>
                                    {handlePropertyInformation(
                                        props.property.property_information
                                    )}
                                </Typography>
                            </Box>
                        )}
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            level="h4"
                            fontWeight={600}
                            color={'primary'}
                        >
                            {formatPrice(props.property.price)}
                            <Typography
                                fontSize={15}
                                color={'neutral'}
                                textTransform="lowercase"
                            >
                                /{props.property.rental_term}
                            </Typography>
                        </Typography>
                        {isResidential(props.property) ? (
                            <Box display={'flex'} gap={2} alignItems={'center'}>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={1}
                                >
                                    <Tooltip
                                        title="Bedrooms"
                                        arrow
                                        variant="soft"
                                        color="warning"
                                    >
                                        <HotelTwoToneIcon />
                                    </Tooltip>
                                    <Typography level="body-md">
                                        {props.property.number_of_bedrooms}
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={1}
                                >
                                    <Tooltip
                                        title="Bathrooms"
                                        arrow
                                        variant="soft"
                                        color="warning"
                                    >
                                        <BathtubTwoToneIcon />
                                    </Tooltip>
                                    <Typography level="body-md">
                                        {props.property.number_of_bathrooms}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box mt={-0.5}>
                                <BusinessTwoToneIcon />
                            </Box>
                        )}
                        <Typography level="body-sm" noWrap>
                            {props.property.address1}
                        </Typography>
                    </CardContent>
                    {props.hasEditAndDeleteActions && (
                        <CardOverflow>
                            <CardActions buttonFlex="1">
                                <ButtonGroup
                                    variant="outlined"
                                    sx={{
                                        bgcolor: 'background.surface',
                                        gap: '0.25rem',
                                        zIndex: 900,
                                    }}
                                >
                                    <Button
                                        component={Link}
                                        color={'danger'}
                                        variant={'solid'}
                                        sx={{
                                            border: 0,
                                            borderRadius: '1rem',
                                            ':hover': {
                                                color: 'white',
                                            },
                                        }}
                                        to={'/'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setOpen(true)
                                        }}
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        sx={{
                                            padding: 0,
                                            border: 'none',
                                            borderLeft: '0px !important',
                                        }}
                                    >
                                        <Link
                                            to={`edit/${props.property.id}`}
                                            style={{
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                flex: 1,
                                                color: colors.brown,
                                                fontWeight: 600,
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            Edit
                                        </Link>
                                    </Button>
                                </ButtonGroup>
                            </CardActions>
                        </CardOverflow>
                    )}
                </Card>
            </Link>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            borderRadius: 0,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}
                >
                    <Typography id="nested-modal-title" level="h2">
                        Are you absolutely sure?
                    </Typography>
                    <Typography
                        id="nested-modal-description"
                        textColor="text.tertiary"
                    >
                        This action cannot be undone. This will permanently
                        delete your property and remove your data from our
                        servers.
                    </Typography>
                    <Box
                        mt={1}
                        display={'flex'}
                        gap={1}
                        sx={{
                            flexDirection: { xs: 'column', sm: 'row-reverse' },
                        }}
                    >
                        <Button
                            variant="solid"
                            color="primary"
                            onClick={() => {
                                removePropertyMutation.mutate(props.property.id)
                                setOpen(false)
                            }}
                        >
                            Continue
                        </Button>
                        <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        </>
    )
}
