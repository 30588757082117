import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

type MetaTagsProps = {
    title: string
    description: string
    image?: string
    url?: string
}

export function MetaTags(props: MetaTagsProps) {
    const location = useLocation()
    return (
        <Helmet>
            <title>Openprop: {props.title}</title>
            <meta property="og:title" content={props.title} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={props.description} />
            {props.image && <meta property="og:image" content={props.image} />}
            <meta
                property="og:url"
                content={
                    props.url || window.location.origin + location.pathname
                }
            />
        </Helmet>
    )
}
