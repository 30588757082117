import React from 'react'

export function useDocumentTitle(title: string) {
    React.useEffect(() => {
        document.title = `${document.title} - ${title}`

        return () => {
            document.title = 'Open Props'
        }
    }, [title])

    return null
}
