import { useStytchSession } from '@stytch/react'
import { useQuery } from '@tanstack/react-query'
import isNil from 'lodash/isNil'
import { keys } from '@/services/query-keys.ts'
import { AxiosResponse } from 'axios'
import { Axios } from '@/utilities/axios.ts'
import {
    SubscriptionType,
    User,
    userSchema,
} from '@/services/use-get-auth-user/schema.ts'
import { isAfter } from '@/utilities/dates.ts'
import { DateTime } from 'luxon'

function useGetAuthUser(enabled: boolean) {
    return useQuery({
        queryKey: [keys.GET_USER.key],
        queryFn: async (): Promise<User> => {
            const res: AxiosResponse<User> = await Axios.get(keys.GET_USER.url)
            return userSchema.parse(res)
        },
        retry: 1,
        gcTime: 900000,
        enabled,
    })
}

export function useGetAuthenticatedUser() {
    const stytchClientSession = useStytchSession()
    const userQuery = useGetAuthUser(
        Boolean(stytchClientSession.session?.session_id)
    )

    return {
        hasActiveSession: Boolean(stytchClientSession.session),
        isPending: userQuery.isLoading,
        isAuth: !isNil(userQuery.data) && !isNil(stytchClientSession.session),
        user: userQuery.data,
        viewCount: userQuery.data?.virtual_tour_view_count || 0,
        isSubscriptionActive: isAfter(
            DateTime.fromJSDate(
                new Date(userQuery.data?.subscription_expiry || '')
            ),
            DateTime.local()
        ),
        isFreeTrial:
            userQuery.data?.subscription_type === SubscriptionType.FREE_TRIAL,
    }
}
