import React from 'react'
import { ChatContext } from '@/services/use-user-messaging/provider'
export function useUserChatController() {
    const context = React.useContext(ChatContext)
    if (!context) {
        throw new Error(
            'useUserChatController must be called inside ChatProvider'
        )
    }
    return context
}
