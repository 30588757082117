import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { Grid, Typography, Box, Divider } from '@mui/joy'

import { Navbar } from '@/components/navbar/index.tsx'
import {
    PropertyList,
    ViewOnMap,
} from '@/src/pages/properties/components/property-list'
import { useGetPaginatedProperties } from '@/services/use-get-properties/index.tsx'
import { useQueryParams } from '@/utilities/use-query-params.tsx'
import { useDocumentTitle } from '@/utilities/use-document-title.tsx'
import { GoogleMapsView } from '@/components/google-maps-view/index.tsx'
import { SearchFilter } from '@/src/pages/properties/components/search-filter'
import {
    PageQueryParams,
    Property,
    SortType,
} from '@/services/use-get-properties/types'
import { colors } from '@/utilities/colors.ts'
import { useNavigate } from 'react-router-dom'
import { Pagination } from '@/components/pagination'
import { NoProperty } from '@/src/pages/properties/components/no-property'
import { SortProperty } from '@/src/pages/properties/components/sort-property'
import { PropertyType } from '@/src/pages/home/components/search-filter/types.ts'

export function PropertiesScreen() {
    useDocumentTitle('Search for your next properties')

    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.up('md'))
    const [sortType, setSortType] = React.useState(SortType.DEFAULT)
    const [queryString, setQueryString] = React.useState<
        PageQueryParams | undefined
    >({
        property_types: PropertyType.RESIDENTIAL,
    })

    const [isMapView, setIsMapView] = React.useState<boolean>(false)
    const propertiesList = useGetPaginatedProperties(queryString)
    const [properties, setProperties] = React.useState<Property[]>([])
    const queryParams = useQueryParams()

    React.useEffect(() => {
        if (isTablet) {
            setIsMapView(false)
        }
    }, [isTablet])

    function handleChange(_: unknown, value: string) {
        if (value === 'low-price') {
            setSortType(SortType.ASC_PRICE)
            return
        }
        if (value === 'high-price') {
            setSortType(SortType.DESC_PRICE)
            return
        }
        setSortType(SortType.DEFAULT)
    }

    const sortProperties = React.useCallback(
        (properties: Property[]) => {
            if (sortType === SortType.ASC_PRICE) {
                setProperties([...properties].sort((a, b) => a.price - b.price))
                return
            }

            if (sortType === SortType.DESC_PRICE) {
                setProperties([...properties].sort((a, b) => b.price - a.price))
                return
            }
            setProperties([])
            return
        },
        [sortType]
    )

    const page = queryParams.get('page') || '1'

    function buildQueryObject() {
        const queryObj: Record<string, string> = {}
        for (const [key, value] of queryParams.entries()) {
            queryObj[key] = value
        }
        return queryObj
    }
    React.useEffect(() => {
        if (queryParams.size === 0) {
            return
        }
        const queryObj = buildQueryObject()
        //get all values from queryParams
        setQueryString(queryObj)
    }, [queryParams])

    React.useEffect(() => {
        sortProperties(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            propertiesList.data?.properties || []
        )
    }, [propertiesList.data, sortProperties, sortType])

    const isResultWithQueryEmpty =
        !propertiesList.isLoading &&
        (propertiesList.data?.properties || []).length === 0

    const allProperties =
        properties.length > 0
            ? properties
            : propertiesList.data?.properties || []

    function displayMapView() {
        if (isTablet) {
            return (
                <Grid
                    xs={12}
                    md={6}
                    sx={{
                        mt: '135px',
                        height: 'calc(100% - 135px)',
                        width: '100%',
                    }}
                >
                    <GoogleMapsView properties={allProperties} />
                </Grid>
            )
        }

        if (isMapView) {
            return (
                <Grid
                    xs={12}
                    md={6}
                    sx={{
                        mt: '135px',
                        height: 'calc(100% - 135px)',
                        width: '100%',
                    }}
                >
                    <GoogleMapsView properties={allProperties} />
                </Grid>
            )
        }
    }

    const navigate = useNavigate()

    function onFetchNextPage(page?: number) {
        if (page) {
            queryParams.set('page', String(page))
            // setQueryString(queryParams)
            navigate(`?${queryParams.toString()}`)
        }
    }

    function onFetchPrevPage(page?: number) {
        if (page) {
            queryParams.set('page', String(page))
            navigate(`?${queryParams.toString()}`)
        }
    }

    return (
        <Box maxHeight={'100vh'}>
            <Box
                width={'100%'}
                zIndex={2}
                position={'fixed'}
                height={'135px'}
                bgcolor={colors.white}
            >
                <Navbar />
                <Divider sx={{ my: 1 }} />
                <Box
                    sx={{
                        ml: {
                            xs: 2,
                            sm: 3,
                            md: 4,
                        },
                        mt: {
                            xs: 1,
                        },
                        mb: {
                            xs: 1,
                        },
                        mr: {
                            xs: 2,
                            sm: 3,
                            md: 4,
                        },
                    }}
                >
                    <SearchFilter />
                </Box>
            </Box>
            <Grid container height={`100vh`}>
                {displayMapView()}
                {!isMapView && (
                    <Grid
                        xs={12}
                        md={6}
                        width={'100%'}
                        mt={'135px'}
                        sx={{
                            height: 'calc(100% - 135px)',
                            overflowY: 'auto',
                        }}
                        className={'no-scrollbar'}
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            px={2}
                            mb={2}
                            alignItems={'center'}
                        >
                            <Typography
                                level="body-md"
                                sx={{ fontWeight: 500, fontSize: '1rem' }}
                            >
                                {allProperties.length + ' properties '}
                            </Typography>
                            <SortProperty
                                sortType={sortType}
                                handleChange={handleChange}
                            />
                        </Box>
                        {isResultWithQueryEmpty && <NoProperty />}
                        <PropertyList
                            properties={allProperties}
                            isLoading={
                                propertiesList.isLoading ||
                                propertiesList.isFetching
                            }
                            paginationComponent={
                                (propertiesList.data?.totalPages || 0) > 0 && (
                                    <Pagination
                                        currentPage={Number(page) || 1}
                                        totalPages={
                                            propertiesList.data?.totalPages || 0
                                        }
                                        hasNextPage={Boolean(
                                            (propertiesList.data?.currentPage ||
                                                1) <
                                                (propertiesList.data
                                                    ?.totalPages || 1)
                                        )}
                                        hasPreviousPage={Boolean(
                                            (propertiesList.data?.currentPage ||
                                                1) >=
                                                (propertiesList.data
                                                    ?.totalPages || 1)
                                        )}
                                        fetchNextPage={onFetchNextPage}
                                        fetchPreviousPage={onFetchPrevPage}
                                    />
                                )
                            }
                        />
                    </Grid>
                )}
                <ViewOnMap isMapView={isMapView} setIsMapView={setIsMapView} />
            </Grid>
        </Box>
    )
}
