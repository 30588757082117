import { Box, Button, Container, Link } from '@mui/joy'
import LogoIcon from '@/assets/logo.svg?react'
import { colors } from '@/utilities/colors.ts'
import { useNavigate } from 'react-router-dom'

export function Header() {
    const navigation = useNavigate()
    return (
        <Container
            maxWidth={'xl'}
            sx={{
                mt: 2,
                mb: 6,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Box>
                <Link href={'/'}>
                    <LogoIcon />
                </Link>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Button
                    onClick={() => {
                        navigation('/auth/login')
                    }}
                    sx={{
                        color: colors.primary,
                        backgroundColor: colors.white,
                        border: `1px solid ${colors.primary}`,
                        '&:hover': {
                            backgroundColor: '#FFFFFF',
                            border: `1px solid ${colors.primary}`,
                        },
                    }}
                >
                    Login
                </Button>
                <Button
                    onClick={() => {
                        navigation('/auth/sign-up')
                    }}
                >
                    Sign up
                </Button>
            </Box>
        </Container>
    )
}
