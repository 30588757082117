// import {extendTheme } from "@mui/joy";
//
// const theme = createTheme({
//     palette: {
//         primary: lime,
//         secondary: purple,
//     },
// });
//
// export const theme = extendTheme({
//     colorSchemes:{
//         light:{
//             palette: {
//                 primary: {
//                     main: '#EFA12D',
//                 },
//                 // secondary: {
//                 //     500: '#55917F',
//                 //     200: '#E1F0C4'
//                 // },
//             },
//         }
//     }
//
// });

import { extendTheme } from '@mui/joy/styles'

const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#fadfb8',
                    100: '#f6ca88',
                    200: '#f4c071',
                    300: '#d59843',
                    400: '#f1ab41',
                    500: '#EFA12D',
                    600: '#f1ab41',
                    700: '#ed9512',
                    800: '#d58610',
                    900: '#be780e',
                },
                danger: {
                    50: '#FFEBEE',
                    100: '#FFCDD2',
                    200: '#EF9A9A',
                    300: '#E57373',
                    400: '#EF5350',
                    500: '#F44336',
                    600: '#E53935',
                    700: '#D32F2F',
                    800: '#C62828',
                    900: '#B71C1C',
                },
                secondary: {
                    // Credit:
                    // https://github.com/tailwindlabs/tailwindcss/blob/master/src/public/colors.js
                    50: '#fdf2f8',
                    100: '#fce7f3',
                    200: '#fbcfe8',
                    300: '#f9a8d4',
                    400: '#f472b6',
                    500: '#ec4899',
                    600: '#db2777',
                    700: '#be185d',
                    800: '#9d174d',
                    900: '#831843',
                    // Adjust the global variant tokens as you'd like.
                    // The tokens should be the same for all color schemes.
                    solidBg: 'var(--joy-palette-secondary-400)',
                    solidActiveBg: 'var(--joy-palette-secondary-500)',
                    outlinedBorder: 'var(--joy-palette-secondary-500)',
                    outlinedColor: 'var(--joy-palette-secondary-700)',
                    outlinedActiveBg: 'var(--joy-palette-secondary-100)',
                    softColor: 'var(--joy-palette-secondary-800)',
                    softBg: 'var(--joy-palette-secondary-200)',
                    softActiveBg: 'var(--joy-palette-secondary-300)',
                    plainColor: 'var(--joy-palette-secondary-700)',
                    plainActiveBg: 'var(--joy-palette-secondary-100)',
                },
            },
        },
    },
    fontFamily: {
        display: 'Poppins',
        body: 'Poppins',
    },
    typography: {
        h4: {
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
    },
})

export { theme }
