import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const schema = z.object({
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    email: z.string().email(),
    password: z.string().min(10, 'Password must be at least 10 characters'),
})

export const signUpSchema = toFormikValidationSchema(schema)
