import React from 'react'
import { useURLQuery } from '@/src/utilities/use-url-query'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useStytch, useStytchSession, useStytchUser } from '@stytch/react'
import { Box, Button, Stack, Typography } from '@mui/joy'

import { colors } from '@/utilities/colors'

import isNil from 'lodash/isNil'

export function Verification() {
    const queryParams = useURLQuery()
    const navigate = useNavigate()
    const stytchClient = useStytch()
    const stytchSession = useStytchSession()
    const stytchUser = useStytchUser()

    const onAuthMagicLink = React.useCallback(
        (token: string) => {
            //Temp void for now
            void stytchClient.magicLinks.authenticate(token, {
                session_duration_minutes: 10800,
            })
        },
        [stytchClient.magicLinks]
    )

    React.useEffect(() => {
        console.log(stytchSession)
        if (isNil(stytchSession.session)) {
            setTimeout(() => {
                navigate('/auth/login')
            }, 2000)
            return
        }

        if (stytchUser.user?.emails[0].verified) {
            setTimeout(() => {
                navigate('/auth/login')
            }, 2000)
            return
        }

        const tokenType = queryParams.get('stytch_token_type')
        const stytchToken = queryParams.get('token')
        console.log({ tokenType, stytchToken })
        if (tokenType === 'magic_links' && !isNil(stytchToken)) {
            //
            onAuthMagicLink(stytchToken)
            return
        }

        if (tokenType === 'oauth' && !isNil(stytchToken)) {
            onAuthMagicLink(stytchToken)
            return
        }

        if (tokenType === 'reset_password' && isNil(stytchToken)) {
            setTimeout(() => {
                navigate('/auth/login') //For now until pass page is made
            }, 2000)
            return
        }
        // navigate('/secure')
    }, [
        queryParams,
        navigate,
        stytchSession.session,
        stytchUser.user?.emails,
        onAuthMagicLink,
    ])

    async function onResendEmail() {
        if (!stytchUser.user?.emails[0].email) {
            return
        }
        await stytchClient.magicLinks.email.send(
            stytchUser.user?.emails[0].email
        )
    }

    if (isNil(stytchSession.session)) {
        return (
            <Box>
                <Typography level={'h4'}>
                    User must be logged in to perform action
                </Typography>
            </Box>
        )
    }

    if (stytchSession.session && stytchUser.user?.emails[0].verified) {
        return <Navigate to={'/secure'} />
    }

    return (
        <Box
            sx={{
                backgroundColor: colors.gray5,
                height: '100vh',
                width: '100%',
                pt: 12,
            }}
        >
            <Stack
                mx={'auto'}
                p={4}
                sx={{
                    textAlign: 'center',
                    width: {
                        xs: '80%',
                        sm: '80%',
                        md: '40%',
                    },
                    backgroundColor: colors.white,
                }}
            >
                <Typography level={'h2'} mb={5}>
                    Verify your email address
                </Typography>
                <Box className={'text-md mb-4'}>
                    You're almost there! We sent an email to:
                </Box>
                <Typography level={'h4'} mb={4}>
                    {stytchUser.user?.emails[0].email}
                </Typography>
                <Typography level={'body-md'} mb={4}>
                    Just click on the link in that email to complete the email
                    verification. If you dont see it, you may need to check your
                    spam folder.
                </Typography>
                <Box mb={8}>
                    <Typography level={'body-md'} mb={4}>
                        Still can't find that email? No problem.
                    </Typography>
                    <Button onClick={onResendEmail} className={'w-full'}>
                        Resend email
                    </Button>
                </Box>
                <Box>
                    Need help?{' '}
                    <Link
                        className={'text-primaryBlue font-bold'}
                        to={'mailto:support@openprop.com'}
                    >
                        Contact customer support
                    </Link>
                </Box>
            </Stack>
        </Box>
    )
}

export function OauthVerification() {
    const queryParams = useURLQuery()
    const navigate = useNavigate()
    const stytchClient = useStytch()
    const stytchSession = useStytchSession()
    // const stytchUser = useStytchUser()

    const onAuthMagicLink = React.useCallback(
        (token: string) => {
            //Temp void for now
            void stytchClient.oauth.authenticate(token, {
                session_duration_minutes: 10800,
            })
        },
        [stytchClient.magicLinks]
    )

    React.useEffect(() => {
        const tokenType = queryParams.get('stytch_token_type')
        const stytchToken = queryParams.get('token')

        if (tokenType === 'oauth' && !isNil(stytchToken)) {
            onAuthMagicLink(stytchToken)
            return
        }
    }, [queryParams, onAuthMagicLink])

    React.useEffect(() => {
        if (stytchSession.session) {
            navigate('/secure')
        }
    }, [stytchSession])

    return (
        <Box
            sx={{
                backgroundColor: colors.gray5,
                height: '100vh',
                width: '100%',
                pt: 12,
            }}
        >
            <Stack
                mx={'auto'}
                p={4}
                sx={{
                    textAlign: 'center',
                    width: {
                        xs: '80%',
                        sm: '80%',
                        md: '40%',
                    },
                    backgroundColor: colors.white,
                }}
            >
                <Typography level={'h2'} mb={5}>
                    Logging you in
                </Typography>
                <Box className={'text-md mb-4'}></Box>
            </Stack>
        </Box>
    )
}
