import { Context } from '@/services/use-user-onboarding/provider'
import React from 'react'

export function useUserOnboardingController() {
    const context = React.useContext(Context)
    if (!context) {
        throw new Error(
            'You must wrapper with UserOnboardingProvider to use this hook'
        )
    }
    return context
}
