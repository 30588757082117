import { z } from 'zod'

export enum NotificationType {
    PROPERTY_ACCEPTED = 'PROPERTY_ACCEPTED',
    PROPERTY_FAVOURITED = 'PROPERTY_FAVOURITED',
}

export const notificationSchema = z.object({
    title: z.string(),
    description: z.string(),
    data: z.string(),
    notification_type: z.nativeEnum(NotificationType),
    user: z.string().array().optional(),
    createdAt: z.string(),
    _id: z.string(),
})

export const NotificationsSchema = notificationSchema.array()

export type Notification = z.infer<typeof notificationSchema>
