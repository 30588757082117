import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import Logo from '@/src/assets/logo.svg?react'
import {
    Button,
    Dropdown,
    MenuButton,
    Menu,
    MenuItem,
    // Divider,
    Container,
    Drawer,
    ModalClose,
    List,
    ListItem,
    Typography,
    ListItemButton,
    IconButton,
    Box,
    CircularProgress,
    Chip,
} from '@mui/joy'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { colors } from '@/utilities/colors.ts'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'

export function Navbar() {
    const navigation = useNavigate()
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false)
    const authUserController = useGetAuthenticatedUser()

    function onNavigate(value: string) {
        navigation(value)
        setMobileMenuOpen(false)
    }

    return (
        <Container maxWidth={'xl'}>
            <Box display={'flex'} mt={2}>
                <Box
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        onClick={() => setMobileMenuOpen(true)}
                        size="lg"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        size={'lg'}
                        open={mobileMenuOpen}
                        onClose={() => setMobileMenuOpen(false)}
                    >
                        <ModalClose />
                        <Box role={'presentation'} mt={6}>
                            <Box ml={2} mb={3}>
                                <NavLink to={'/'}>
                                    <Logo />
                                </NavLink>
                            </Box>
                            <Typography
                                level="body-xs"
                                textTransform="uppercase"
                                fontWeight="lg"
                                ml={2}
                            >
                                For Renters
                            </Typography>
                            <List size="lg" orientation={'vertical'}>
                                <ListItem>
                                    <ListItemButton
                                        onClick={() =>
                                            onNavigate(
                                                '/properties?property_types=residential'
                                            )
                                        }
                                    >
                                        Rent an apartment
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Typography
                                level="body-xs"
                                textTransform="uppercase"
                                fontWeight="lg"
                                ml={2}
                                mt={2}
                            >
                                For Landlords
                            </Typography>
                            <List size="lg">
                                <ListItem>
                                    <ListItemButton
                                        onClick={() =>
                                            onNavigate(
                                                '/secure/properties/create'
                                            )
                                        }
                                    >
                                        List an apartment
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Typography
                                level="body-xs"
                                textTransform="uppercase"
                                fontWeight="lg"
                                ml={2}
                            >
                                Virtual Tour
                            </Typography>
                            <List size="lg" orientation={'vertical'}>
                                <ListItem>
                                    <ListItemButton
                                        onClick={() => onNavigate('/pricing')}
                                    >
                                        Openprop{' '}
                                        <Chip
                                            color={'primary'}
                                            onClick={function () {}}
                                        >
                                            Pro
                                        </Chip>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        onClick={() =>
                                            onNavigate('/virtual-tour')
                                        }
                                    >
                                        How it works
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            {authUserController.isAuth ? (
                                <Button
                                    sx={{
                                        ml: 2,
                                        mt: 6,
                                    }}
                                    onClick={() => onNavigate('/secure')}
                                >
                                    Dashboard
                                </Button>
                            ) : (
                                <Button
                                    sx={{
                                        ml: 2,
                                        mt: 6,
                                        minWidth: '8rem',
                                    }}
                                    onClick={() => onNavigate('/auth/login')}
                                    disabled={authUserController.isPending}
                                >
                                    {authUserController.isPending ? (
                                        <CircularProgress />
                                    ) : (
                                        'Get Started'
                                    )}
                                </Button>
                            )}
                        </Box>
                    </Drawer>
                </Box>

                {/* Renters and Landlords for Desktop */}
                <Box
                    flex={2}
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        alignItems: 'center',
                    }}
                >
                    <Dropdown>
                        <MenuButton
                            variant={'plain'}
                            sx={{
                                ':hover': {
                                    background: 'transparent',
                                },
                            }}
                            endDecorator={<ArrowDropDownIcon />}
                        >
                            For Renters
                        </MenuButton>
                        <Menu>
                            <MenuItem>
                                <NavLink
                                    style={{
                                        color: colors.brown,
                                    }}
                                    to={
                                        '/properties?property_types=residential'
                                    }
                                >
                                    Rent an apartment
                                </NavLink>
                            </MenuItem>
                        </Menu>
                    </Dropdown>
                    <Dropdown>
                        <MenuButton
                            data-testid={'forLandlordsBtn'}
                            variant={'plain'}
                            sx={{
                                ':hover': {
                                    background: 'transparent',
                                },
                            }}
                            endDecorator={<ArrowDropDownIcon />}
                        >
                            For Landlords
                        </MenuButton>
                        <Menu>
                            <MenuItem>
                                <NavLink
                                    data-testid={'listAnApartmentText'}
                                    style={{
                                        color: colors.brown,
                                    }}
                                    to={'/secure/properties/create'}
                                >
                                    List an apartment
                                </NavLink>
                            </MenuItem>
                        </Menu>
                    </Dropdown>
                    <Dropdown>
                        <MenuButton
                            data-testid={'forLandlordsBtn'}
                            variant={'plain'}
                            sx={{
                                ':hover': {
                                    background: 'transparent',
                                },
                            }}
                            endDecorator={<ArrowDropDownIcon />}
                        >
                            Virtual Tour
                        </MenuButton>
                        <Menu>
                            <MenuItem>
                                <NavLink
                                    data-testid={'listAnApartmentText'}
                                    style={{
                                        color: colors.brown,
                                    }}
                                    to={'/pricing'}
                                >
                                    Openprop{' '}
                                    <Chip
                                        color={'primary'}
                                        onClick={function () {}}
                                    >
                                        Pro
                                    </Chip>
                                </NavLink>
                            </MenuItem>
                            <MenuItem>
                                <NavLink
                                    data-testid={'listAnApartmentText'}
                                    style={{
                                        color: colors.brown,
                                    }}
                                    to={'/virtual-tour'}
                                >
                                    How it works
                                </NavLink>
                            </MenuItem>
                        </Menu>
                    </Dropdown>
                </Box>

                {/* Center Section: Logo */}
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Link to={'/'}>
                        <Logo />
                    </Link>
                </Box>

                {/* Right Section: Get Started */}
                <Box
                    flex={2}
                    justifyContent={'flex-end'}
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        alignItems: 'center',
                    }}
                >
                    {authUserController.isAuth ? (
                        <Link to={'/secure'}>
                            <Button
                                data-testid={'btn-dashboard'}
                                sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                    width: '6.875rem',
                                    height: '2.5rem',
                                    py: 2,
                                    px: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '0.625rem',
                                    flexShrink: 0,
                                    borderRadius: 3,
                                    backgroundColor: colors.primary,
                                    borderColor: colors.primary,
                                    color: colors.white,
                                    textTransform: 'none',
                                }}
                            >
                                Dashboard
                            </Button>
                        </Link>
                    ) : (
                        <Button
                            data-testid={'btn-getting-started'}
                            sx={{
                                display: { xs: 'none', sm: 'flex' },
                                width: '6.875rem',
                                height: '2.5rem',
                                py: 2,
                                px: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '0.625rem',
                                flexShrink: 0,
                                borderRadius: 3,
                                backgroundColor: colors.primary,
                                borderColor: colors.primary,
                                color: colors.white,
                                textTransform: 'none',
                            }}
                            disabled={authUserController.isPending}
                        >
                            <Link
                                to={'/auth/login'}
                                style={{ color: colors.white }}
                            >
                                {authUserController.isPending ? (
                                    <CircularProgress />
                                ) : (
                                    'Get Started'
                                )}
                            </Link>
                        </Button>
                    )}
                </Box>
            </Box>
        </Container>
    )
}
