import {
    Box,
    Button,
    Checkbox,
    formLabelClasses,
    Stack,
    Typography,
} from '@mui/joy'
import { Link } from 'react-router-dom'
import { Divider } from '@mui/material'
// import GoogleIcon from '@/assets/svg-components/google-icon.tsx'
import { DateTime } from 'luxon'
import { useFormik } from 'formik'
import { loginSchema } from '@/src/pages/auth/login/schema'
import { TextInput } from '@/components/text-input'
import { Header } from '@/src/pages/auth/components/header'
import { useUserOnboardingController } from '@/services/use-user-onboarding'
import { GoogleLoginButton } from '@/components/google-login-button'

export function Login() {
    const onboardingController = useUserOnboardingController()
    const formik = useFormik({
        onSubmit: async (values) => {
            await onboardingController.onLogin(values)
        },
        initialValues: {
            email: onboardingController.userEmail || '',
            password: '',
        },
        validationSchema: loginSchema,
    })

    return (
        <Box>
            <Header />
            <Box
                component="main"
                sx={{
                    my: 'auto',
                    py: 2,
                    pb: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: 400,
                    maxWidth: '100%',
                    mx: 'auto',
                    borderRadius: 'sm',
                    '& form': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    },
                    [`& .${formLabelClasses.asterisk}`]: {
                        visibility: 'hidden',
                    },
                }}
            >
                <Stack gap={4} sx={{ mb: 2 }}>
                    <Stack gap={1}>
                        <Typography level="h3">Sign in</Typography>
                        <Typography level="body-sm">
                            New to Openprop?{' '}
                            <Link to="/auth/sign-up">
                                <Typography level="title-sm">
                                    Sign up!
                                </Typography>
                            </Link>
                        </Typography>
                    </Stack>
                    <GoogleLoginButton />
                </Stack>
                <Divider>or</Divider>
                <Stack gap={2} sx={{ mt: 2 }}>
                    <TextInput
                        label={'Email'}
                        placeholder={'Email address'}
                        size={'lg'}
                        errorText={
                            formik.touched.email && formik.errors?.email
                                ? formik.errors.email
                                : undefined
                        }
                        type="email"
                        name="email"
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                    <TextInput
                        label={'Password'}
                        placeholder={'*************'}
                        size={'lg'}
                        errorText={
                            formik.touched.password && formik.errors?.password
                                ? formik.errors.password
                                : undefined
                        }
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                    <Stack gap={4} sx={{ mt: 2 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Checkbox
                                size="sm"
                                label="Remember me"
                                name="persistent"
                            />
                            <Link to="/auth/reset-password">
                                Forgot your password?
                            </Link>
                        </Box>
                        <Button
                            disabled={!formik.isValid || !formik.dirty}
                            type="submit"
                            fullWidth
                            onClick={() => formik.handleSubmit()}
                        >
                            Sign in
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            <Box component="footer" sx={{ py: 3 }}>
                <Typography level="body-xs" textAlign="center">
                    © Openprop {DateTime.now().year}
                </Typography>
            </Box>
        </Box>
    )
}
