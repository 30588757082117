import React from 'react'
import { Box } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { Text } from '@/components/text'
import { Button, Typography, Grid } from '@mui/joy'
import { useGetLandlordProperties } from '@/services/use-get-properties'
import { Property } from '@/services/use-get-properties/types.ts'
import { colors } from '@/utilities/colors'
import AddIcon from '@mui/icons-material/Add'
import NoPropertyIcon from '@/src/pages/secure/properties/view-property/assets/svgs/no-property-icon.svg?react'
import { SecureHeader } from '@/src/pages/secure/components/secure-header'
import { VerifyIdentityModal } from './components/verify-identity-modal'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import { PropertyCard } from '@/components/property-card'

export function ViewProperty() {
    const navigate = useNavigate()
    const properties = useGetLandlordProperties()
    const [showModal, setShowModal] = React.useState(false)
    const { user } = useGetAuthenticatedUser()

    function onClickCreateProperty() {
        if (!user?.is_identity_card_valid) {
            setShowModal(true)
            return
        }
        navigate(`/secure/properties/create`)
    }

    return (
        <React.Fragment>
            <SecureHeader />
            <Box padding={3}>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Box
                        sx={{
                            borderRadius: '2rem',
                            padding: '0.4rem 1rem',
                            width: 'fit-content',
                            boxShadow: '0px 0px 6px 0px rgba(157,154,154,0.75)',
                            fontSize: { xs: 14, sm: 16, md: 18 },
                        }}
                    >
                        {(properties.data?.properties || []).length} properties
                        listed
                    </Box>
                    <Box>
                        <Button
                            onClick={onClickCreateProperty}
                            sx={{
                                px: { xs: 1.2, sm: 3, md: 3.4 },
                                ml: 0.5,
                                display: 'flex',
                                gap: 1,
                            }}
                        >
                            <AddIcon />
                            <Text
                                sx={{
                                    fontSize: {
                                        xs: 15,
                                        sm: 17,
                                        md: 19,
                                    },
                                    fontWeight: 'bold',
                                    color: colors.white,
                                }}
                            >
                                Create new listing
                            </Text>
                        </Button>
                    </Box>
                </Box>
                {(properties.data?.properties || []).length === 0 && (
                    <Box
                        width={'100%'}
                        mt={6}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                    >
                        <NoPropertyIcon />
                        <Box textAlign={'center'}>
                            <Typography mb={2} level={'h2'}>
                                No Properties Listed
                            </Typography>
                            <Typography>
                                Click the button on the top right corner of your
                                screen to list a property
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Grid
                    container
                    spacing={4}
                    mt={3}
                    sx={{ alignItems: 'stretch' }}
                >
                    {(properties.data?.properties || []).map(
                        (property: Property) => (
                            <Grid
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                mb={3}
                                key={property.id}
                            >
                                <PropertyCard
                                    isFaveDisabled
                                    hasEditAndDeleteActions
                                    property={property}
                                />
                            </Grid>
                        )
                    )}
                </Grid>
            </Box>
            <VerifyIdentityModal
                isPendingVerification={Boolean(user?.has_uploaded_id_card)}
                isOpen={showModal}
                onToggle={() => setShowModal(false)}
            />
        </React.Fragment>
    )
}
