import * as React from 'react'
import {
    Textarea,
    Stack,
    FormControl,
    Button,
    Box,
    LinearProgress,
} from '@mui/joy'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import { useUserChatController } from '@/services/use-user-messaging'

export type MessageInputProps = {
    value: string
    onSetValue: (value: string) => void
    onSubmit: () => void
}

export function MessageInput(props: MessageInputProps) {
    const { loadMessage } = useUserChatController()
    const { value, onSetValue, onSubmit } = props
    const textAreaRef = React.useRef<HTMLDivElement>(null)
    function handleClick() {
        if (value.trim() !== '') {
            onSubmit()
            onSetValue('')
        }
    }
    return (
        <Box sx={{ px: 2, pb: 3 }}>
            {loadMessage && <LinearProgress thickness={1} />}
            <FormControl>
                <Textarea
                    disabled={loadMessage}
                    placeholder="Type something here…"
                    aria-label="Message"
                    ref={textAreaRef}
                    onChange={(e) => {
                        onSetValue(e.target.value)
                    }}
                    value={value}
                    minRows={3}
                    maxRows={10}
                    endDecorator={
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            flexGrow={1}
                            sx={{
                                py: 1,
                                pr: 1,
                                borderTop: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <Button
                                size="sm"
                                color="primary"
                                sx={{
                                    borderRadius: 'sm',
                                }}
                                endDecorator={<SendRoundedIcon />}
                                onClick={handleClick}
                                disabled={loadMessage}
                            >
                                Send
                            </Button>
                        </Stack>
                    }
                    onKeyDown={(event) => {
                        if (
                            event.key === 'Enter' &&
                            (event.metaKey || event.ctrlKey)
                        ) {
                            handleClick()
                        }
                    }}
                    sx={{
                        '& textarea:first-of-type': {
                            minHeight: 72,
                        },
                    }}
                />
            </FormControl>
        </Box>
    )
}
