import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

export enum EMPLOYMENT {
    EMPLOYED = 'EMPLOYED',
    UNEMPLOYED = 'UNEMPLOYED',
}

export enum USERTYPE {
    LANDLORD = 'LANDLORD',
    RENTER = 'RENTER',
    AGENT = 'AGENT',
    UNKNOWN = 'UNKNOWN',
}

export const userProfileSchema = z.object({
    first_name: z.string().min(2).optional(),
    last_name: z.string().min(2).optional(),
    phone: z.string().min(10).optional(),
    user_type: z.nativeEnum(USERTYPE).optional(),
    next_of_kin: z
        .object({
            first_name: z.string().optional(),
            last_name: z.string().optional(),
            phone: z.string().optional(),
            relationship: z.string().optional(),
            address: z
                .object({
                    address: z.string(),
                    address_number: z.string(),
                    neighbourhood: z.string(),
                })
                .optional(),
        })
        .optional(),
    employment: z
        .object({
            employment_status: z.nativeEnum(EMPLOYMENT).optional(),
            occupation: z.string().optional(),
            company_name: z.string().optional(),
        })
        .optional(),
    identity_card: z
        .object({
            front: z.string(),
            back: z.string(),
        })
        .optional(),
    is_landlord: z.boolean().optional(),
})

export const updateProfileSchema = toFormikValidationSchema(userProfileSchema)
export type UserProfile = z.infer<typeof userProfileSchema>
