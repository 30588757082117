import React from 'react'
import { useMediaQuery } from '@mui/material'
import {
    Autocomplete,
    Box,
    Container,
    useTheme,
    Button,
    Grid,
    Typography,
    CircularProgress,
    AutocompleteOption,
    ListItemDecorator,
} from '@mui/joy'
import { Link } from 'react-router-dom'
import { useGetPropertiesLocations } from '@/services/use-get-properties-locations'

import SearchFilterSVG from '@/assets/search-filter.svg?react'
import { colors } from '@/utilities/colors.ts'
import { PropertyType } from '@/src/pages/home/components/search-filter/types.ts'

import { TPropertyLocationAutoCompleteOption } from '@/utilities/property.tsx'
import { SelectBathsBedsDropdown } from '@/components/select-baths-beds-dropdown'
import { SelectPriceDropdown } from '@/components/select-price-dropdown'
import { SelectPropertyTypeDropdown } from '@/components/select-property-type-dropdown'
import { FilterModal } from '@/components/filter-modal'
import { LocationOn } from '@mui/icons-material'
import ListItemContent from '@mui/joy/ListItemContent'

type TQueryParam = {
    propertylocation?: string
    minPrice?: string
    maxPrice?: string
    noOfBeds?: string
    noOfBaths?: string
    propertytype?: string
    isRegion?: boolean
}

export function SearchFilter() {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    const [openPrice, setOpenPrice] = React.useState<boolean>(false)
    const [openPropertyType, setOpenPropertyType] =
        React.useState<boolean>(false)
    const [openBedsBaths, setOpenBedsBaths] = React.useState<boolean>(false)

    const [displayMoreFilterModal, setDisplayMoreFilterModal] =
        React.useState<boolean>(false)
    const [minPrice, setMinPrice] = React.useState('')
    const [maxPrice, setMaxPrice] = React.useState('')
    const [noOfBeds, setNoOfBeds] = React.useState<string>('')
    const [noOfBaths, setNoOfBaths] = React.useState<string>('')
    const [query, setQuery] =
        React.useState<TPropertyLocationAutoCompleteOption>({
            name: '',
            id: '',
            isRegion: false,
        })
    const [propertyTypes, setPropertyTypes] = React.useState<PropertyType>(
        PropertyType.RESIDENTIAL
    )

    const propertyLocations = useGetPropertiesLocations()

    function buildQueryString(queryParam: TQueryParam) {
        const queries = []

        if (queryParam.propertylocation) {
            queries.push(
                `${queryParam.isRegion ? 'region' : 'neighbourhood'}=${queryParam.propertylocation}`
            )
        }

        if (queryParam.noOfBeds && Number(queryParam.noOfBeds) > 0) {
            queries.push(`max_number_of_beds=${queryParam.noOfBeds}`)
        }

        if (queryParam.noOfBaths && Number(queryParam.noOfBaths) > 0) {
            queries.push(`max_number_of_baths=${queryParam.noOfBaths}`)
        }

        if (queryParam.maxPrice && Number(queryParam.maxPrice) > 0) {
            queries.push(`max_price=${queryParam.maxPrice}`)
        }

        if (queryParam.minPrice && Number(queryParam.minPrice) > 0) {
            queries.push(`min_price=${queryParam.minPrice}`)
        }

        if (queryParam.propertytype != PropertyType.UNKNOWN) {
            queries.push(`property_types=${queryParam.propertytype}`)
        }

        return queries.length > 0 ? `?${queries.join('&')}` : ''
    }

    const getQueryParams = buildQueryString({
        noOfBaths,
        noOfBeds,
        maxPrice: `${maxPrice}`,
        minPrice: `${minPrice}`,
        propertytype: propertyTypes,
        propertylocation: query.id,
        isRegion: query.isRegion,
    })

    const options = propertyLocations.data || []
    return (
        <Container
            maxWidth={'md'}
            disableGutters
            sx={{
                width: {
                    md: '100%',
                    sm: '92%',
                    xs: '92%',
                },
                backgroundColor: 'white',
                borderRadius: 10,
                border: `1px solid ${colors.gray10}`,
            }}
        >
            <Grid container alignItems={'center'}>
                <Grid
                    sm={12}
                    xs={12}
                    md={5}
                    pt={isTablet || isMobile ? 1 : 1.5}
                    pb={isTablet || isMobile ? 0 : 1.5}
                    pr={isTablet || isMobile ? 1 : 0}
                    pl={isTablet || isMobile ? 1 : 0}
                >
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        sx={{
                            borderBottom: {
                                sm: '1px solid black',
                                xs: '1px solid black',
                                md: '0',
                                lg: '0',
                            },
                        }}
                    >
                        <Autocomplete
                            variant="plain"
                            endDecorator={
                                propertyLocations.isLoading ? (
                                    <CircularProgress
                                        size="sm"
                                        sx={{
                                            bgcolor: 'background.surface',
                                        }}
                                    />
                                ) : null
                            }
                            placeholder={
                                'Enter your preferred city or neighborhoods'
                            }
                            loading={propertyLocations.isLoading}
                            loadingText={<div>loading...</div>}
                            freeSolo
                            startDecorator={<LocationOn />}
                            onChange={(_, value) => {
                                const res =
                                    typeof value === 'string' || value === null
                                        ? {
                                              name: '',
                                              id: '',
                                              isRegion: false,
                                          }
                                        : value
                                setQuery(res)
                            }}
                            disableClearable
                            size={'lg'}
                            autoComplete
                            value={query}
                            options={options}
                            sx={{
                                paddingRight: 0,
                                '& MuiAutocomplete-popper': {
                                    backgroundColor: 'green',
                                },
                                width: '100%',
                                ['.MuiAutocomplete-input::placeholder']: {
                                    fontSize: 17,
                                },
                            }}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option
                                }
                                return option.name
                            }}
                            renderOption={(props, option) => (
                                <AutocompleteOption
                                    {...props}
                                    sx={{
                                        borderTopColor: colors.gray10,
                                        borderTopStyle: 'solid',
                                        borderTopWidth: 0.6,
                                    }}
                                >
                                    <ListItemDecorator>
                                        <LocationOn />
                                    </ListItemDecorator>
                                    <ListItemContent sx={{ fontSize: 'sm' }}>
                                        {option.name}
                                    </ListItemContent>
                                </AutocompleteOption>
                            )}
                        />
                        {(isTablet || isMobile) && (
                            <Box>
                                <SearchFilterSVG
                                    onClick={() =>
                                        setDisplayMoreFilterModal(true)
                                    }
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid sm={12} xs={12} md={7}>
                    {isTablet ? (
                        <Box mt={1} mx={1} mb={1}>
                            <Link to={`/properties${getQueryParams}`}>
                                <Button color={'primary'} fullWidth>
                                    Search
                                </Button>
                            </Link>
                        </Box>
                    ) : (
                        <Box
                            justifyContent={'center'}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <SelectBathsBedsDropdown
                                noOfBaths={noOfBaths}
                                noOfBeds={noOfBeds}
                                onToggle={setOpenBedsBaths}
                                isOpen={openBedsBaths}
                                setNoOfBaths={setNoOfBaths}
                                setNoOfBeds={setNoOfBeds}
                            />
                            <SelectPropertyTypeDropdown
                                onToggle={setOpenPropertyType}
                                isOpen={openPropertyType}
                                value={propertyTypes}
                                onChange={setPropertyTypes}
                            />
                            <SelectPriceDropdown
                                onToggle={setOpenPrice}
                                isOpen={openPrice}
                                onSetMinPrice={setMinPrice}
                                onSetMaxPrice={setMaxPrice}
                                maxPrice={maxPrice}
                                isSelected={
                                    Number(maxPrice) > 0 || Number(minPrice) > 0
                                }
                                minPrice={minPrice}
                            />
                            <Link to={`/properties${getQueryParams}`}>
                                <Button
                                    sx={{
                                        px: { xs: 1.2, sm: 3, md: 4 },
                                        ml: 0.5,
                                        mr: 1,
                                        height: 48,
                                    }}
                                >
                                    <Typography
                                        level={'body-sm'}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: colors.white,
                                        }}
                                    >
                                        Search
                                    </Typography>
                                </Button>
                            </Link>
                        </Box>
                    )}
                </Grid>
            </Grid>

            <FilterModal
                showLocationSearch={false}
                onFilter={() => {}}
                isOpen={displayMoreFilterModal}
                onClose={() => setDisplayMoreFilterModal(false)}
                onOpen={() => setDisplayMoreFilterModal(true)}
            />
        </Container>
    )
}
