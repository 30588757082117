export const keys: Record<string, Record<string, string>> = {
    GET_LANDLORD_PROPERTY: {
        key: 'get-landlord-property-key',
        url: '/properties/landlord',
    },
    GET_LATEST_PROPERTIES: {
        key: 'get-latest-property-key',
        url: '/properties/latest',
    },
    GET_USER: {
        key: 'get-user-key',
        url: '/user',
    },
    GET_USER_NOTIFICATIONS: {
        key: 'get-user-notification-key',
        url: '/notifications',
    },
    GET_USER_PROPERTY_FAVOURITES: {
        key: 'get-user-property-favourites-key',
        url: '/user/favourites',
    },
    GET_PROPERTIES: {
        key: 'get-properties-key',
        url: '/properties',
    },
    POST_PROPERTY_VIEWS: {
        key: 'post-property-view',
        url: '/properties',
    },
    GET_PROPERTIES_WITH_QUERY: {
        key: 'get-properties-with-query-key',
        url: '/properties',
    },
    GET_PROPERTY: {
        key: 'get-property-key',
        url: '/properties',
    },
    GET_PROPERTY_LOCATION: {
        key: 'get-property-locations-key',
        url: '/regions',
    },
    GET_PROPERTY_LOCATION_PLAIN: {
        key: 'get-property-locations-plain-key',
        url: '/regions?type=plain',
    },
    POST_REMOVE_PROPERTY: {
        key: 'post-remove-property-key',
        url: '/properties',
    },
    GET_AMENITIES: {
        key: 'get-amenities-key',
        url: '/amenities',
    },
    UPDATE_USER_PROFILE: {
        key: 'update-user-profile',
        url: '/user/profile',
    },
    UPDATE_USER_VERIFICATION: {
        key: 'update-user-verification',
        url: '/user/id_verification',
    },
    UPDATE_USER_TYPE: {
        key: 'update-user-type',
        url: '/user/user_type',
    },
}
