import { Box } from '@mui/joy'
import LockIcon from '@/assets/images/secure-lock.png'
import VirtualTourLimitBg from '@/assets/images/tour-limit-blur-image.png'
import { Typography } from '@mui/material'
import { colors } from '@/utilities/colors'
import { queryClient } from '@/utilities/react-query-client.ts'
import { keys } from '@/services/query-keys.ts'
import { PayWithPayStack } from '@/components/pay-with-paystack'

export function VirtualTourLimit(props: { src?: string; isVisible?: boolean }) {
    function onSuccess() {
        return void queryClient.invalidateQueries({
            queryKey: [keys.GET_USER.key],
        })
    }

    if (props.src && props.isVisible) {
        return (
            <iframe
                width="100%"
                height="500"
                src={props.src}
                allowFullScreen
                allow="xr-spatial-tracking"
            />
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundImage: `url(${VirtualTourLimitBg})`,
            }}
        >
            <Box
                textAlign={'center'}
                gap={2}
                sx={{
                    color: colors.footerTextColor,
                    border: '1px solid white',
                    width: { xs: '90%', md: '50%' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    borderRadius: 10,
                    padding: 2,
                    bgcolor: colors.white,
                }}
            >
                <img alt={'Lock Icon'} src={LockIcon} width={90} />
                <Box
                    display={'flex'}
                    gap={2}
                    mb={4}
                    alignItems={'center'}
                    flexDirection={'column'}
                    width={'100%'}
                >
                    <Typography
                        fontSize={'32px'}
                        fontWeight={700}
                        color={colors.footerTextColor}
                    >
                        Tour Locked
                    </Typography>
                    <Typography
                        textAlign={'center'}
                        sx={{ width: { xs: '100%', sm: '400px' } }}
                    >
                        This virtual tour is only available for subscribers.
                        Unlock amazing views and interactive features by
                        subscribing now!
                    </Typography>
                </Box>
                <Box>
                    <PayWithPayStack
                        amount={25}
                        label="Unlock"
                        onSuccess={onSuccess}
                    />
                </Box>
            </Box>
        </Box>
    )
}
